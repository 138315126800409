<template>
  <div class="row">
    <div class="col-12 col-lg-12 mb-4">
      <div class="bg-light card d-flex p-3">
        <div class="d-flex align-items-center justify-content-between">
          <div>
            <h5>{{ $t("editWebsiteContent") }}</h5>
            <p>{{ $t("editWebsiteContentDescription") }}</p>
          </div>
          <router-link to="/my-academy/content">
            <button type="submit" class="ml-2 btn btn-pill bg-warning">
              <span>{{ $t("editScreen") }}</span>
            </button>
          </router-link>
        </div>
      </div>
    </div>
    <div class="col-12 col-lg-6 mb-4"><Logo></Logo></div>
    <div class="col-12 col-lg-6 mb-4"><Favicon></Favicon></div>
    <div class="col-12 col-lg-12 mb-4"><Info></Info></div>
  </div>
</template>

<script>
import Logo from "./Logo.vue";
import Favicon from "./Favicon.vue";
import Info from "./Info.vue";
export default {
  components: { Logo, Favicon, Info },
};
</script>

<style></style>
