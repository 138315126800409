<template>
  <div class="row align-items-center p-2">
    <div v-if="!getActivePlan.isActive" class="col-12">
      <div class="alert alert-warning">
        Aboneliğiniz iptal edilmiş görünüyor,
        <strong>{{ formatter(getActivePlan.endDate) }}</strong> tarihinde
        aboneliğiniz tamamlanacak ve yenilenmeyecektir. Tekrar aboneliğinizi
        kaldığı yerden devam ettirmek istiyorsanız lütfen aboneliği tekrar aktif
        edin.
        <div class="mt-4 text-right">
          <button
            @click="showReactivePlanModal = true"
            class="btn btn-pill btn-xs btn-warning"
          >
            Aboneliği tekrar aktif et
          </button>
        </div>
      </div>
    </div>
    <div class="col-12 col-md-6 col-lg-4 mt-3 mt-md-0">
      <div><small>AKTİF ABONELİK</small></div>
      <h1 class="text-primary">
        {{ getActivePackage.name }}
        <small>
          ({{ $t("day", { d: getActivePlan.subscriptionPeriod }) }})</small
        >
      </h1>
      <p>{{ getActivePackage.description }}</p>
      <p>
        <small
          ><strong>
            {{ getActivePackage.product.name }} -
            {{ getActivePackage.product.description }}
          </strong></small
        >
      </p>
    </div>
    <div class="col-12 col-md-6 col-lg-4 mt-3 mt-md-0">
      <div><small>ABONELİK BİLGİSİ</small></div>
      <h4>
        <strong>{{ getActivePlan.id }}</strong>
      </h4>
      <div v-if="getActivePlan.isActive" class="mt-4">
        <button
          @click="showCancelPlan = true"
          class="btn btn-pill btn-danger btn-xs"
        >
          <i class="icon-blocked"></i> {{ $t("cancelSubscription") }}
        </button>

        <button
          @click="GO_TO_SUBSCRIPTON()"
          class="btn btn-pill ml-3 btn-info btn-xs"
        >
          <i class="icon-check"></i> {{ $t("savedCards") }}
        </button>
      </div>
    </div>
    <div
      v-if="getLastPlanUpdates.id"
      class="col-12 col-md-6 col-lg-4 mt-3 mt-md-0"
    >
      <div><small>ÜCRETLENDİRME</small></div>
      <h4>
        <strong>{{
          Currency(
            {
              price: getActivePlan.price,
            },
            "USD"
          )
        }}</strong>
        <span
          class="ml-2 badge float-right"
          :class="`badge-${
            getLastPlanUpdates.isPaymentSuccess ? 'success' : 'danger'
          }`"
        >
          <small>
            {{
              getLastPlanUpdates.isPaymentSuccess
                ? $t("isPaymentSucceed")
                : $t("isPaymentFailed")
            }}
          </small>
        </span>
      </h4>
      <div>
        <small class="text-danger">
          <strong v-if="getLastPlanUpdates.balance > 0">
            - {{ Currency({ price: getLastPlanUpdates.balance }) }} indirim
          </strong>
        </small>
      </div>
      <div class="mt-2">
        <small
          ><i>
            {{
              $i18n.locale === "tr"
                ? getLastPlanUpdates.paymentStatusMessageTR
                : getLastPlanUpdates.paymentStatusMessageEN
            }}</i
          ></small
        >
      </div>
      <div class="mt-2">
        <div>{{ $t("invoiceDate") }}</div>
        <div class="text-info">
          {{ formatter(new Date(getLastPlanUpdates.updatedDate)) }} -
          {{ formatter(new Date(getActivePlan.endDate)) }}
        </div>
      </div>
    </div>
    <div v-else class="col-12 col-md-6 col-lg-4 mt-3 mt-md-0">
      <span class="text-warning">
        {{ $t("paymentIsWaiting") }}
      </span>
    </div>
    <Modal
      sm
      v-model="showCancelPlan"
      :okText="$t('cancelSubscriptionButton')"
      :title="$t('cancelSubscriptionTitle')"
      @ok="CANCEL_SUBSCRIPTION"
    >
      <div
        v-if="alertBox.status"
        class="alert"
        :class="`alert-${alertBox.variant}`"
      >
        {{ $t(alertBox.message) }}
      </div>
      <div v-else>
        <h1 class="text-center">
          <i class="icon-blocked text-primary"></i>
        </h1>
        <div class="mt-3">
          {{ $t("cancelSubscriptionDescription") }}
        </div>
      </div>
    </Modal>
    <Modal
      sm
      v-model="showReactivePlanModal"
      :okText="$t('reactiveSubscriptionButton')"
      :title="$t('reactiveSubscriptionTitle')"
      @ok="REACTIVE_SUBSCRIPTION"
    >
      <div
        v-if="alertBox.status"
        class="alert"
        :class="`alert-${alertBox.variant}`"
      >
        {{ $t(alertBox.message) }}
      </div>
      <div v-else>
        <div class="mt-3">
          {{ $t("reactiveSubscriptionDescription") }}
        </div>
      </div>
    </Modal>
  </div>
</template>

<script>
import Modules from "../../mixins/Modules";
import { Currency } from "gurhan/helpers/Currency";
import { formatter } from "gurhan/helpers/Date";
import Modal from "../Shared/Modal/index.vue";
import { encrypt } from "../../helpers/subscription.helper";

export default {
  mixins: [Modules],
  components: {
    Modal,
  },
  methods: {
    Currency,
    formatter,
    GO_TO_SUBSCRIPTON() {
      const data = {
        id: "-",
        amount: 0,
        currency: "USD",
      };
      const hash = encrypt(JSON.stringify(data));
      this.$router.push(`/subscription-saved-card?hash=${hash}`);
    },
    async CANCEL_SUBSCRIPTION(loading) {
      loading(true);
      const response = await this.$apiHeocademy.delete(
        `SubscriptionManagement/${this.getActivePlan.id}`
      );
      if (response.message === "OK") {
        this.alertBox = {
          status: true,
          message: "cancelledSubscription",
          variant: "success",
        };
        setTimeout(() => {
          location.reload();
        }, 3500);
      } else {
        this.alertBox = {
          status: true,
          message: "cancelledSubscriptionFailed",
          variant: "danger",
        };
      }
      setTimeout(() => {
        this.alertBox.status = false;
      }, 4000);
      loading(false);
    },
    async REACTIVE_SUBSCRIPTION(loading) {
      loading(true);
      const response = await this.$apiHeocademy.post(
        "SubscriptionManagement/ReActivatePlan",
        {
          planId: this.getActivePlan.id,
        }
      );
      if (response.message === "OK") {
        this.alertBox = {
          status: true,
          message: "reactivatedSubscription",
          variant: "success",
        };
        setTimeout(() => {
          location.reload();
        }, 3500);
      } else {
        this.alertBox = {
          status: true,
          message: "reactivatedSubscriptionFailed",
          variant: "danger",
        };
      }
      loading(false);
      setTimeout(() => {
        this.alertBox.status = false;
      }, 4000);
    },
  },
  data() {
    return {
      showCancelPlan: false,
      showReactivePlanModal: false,
      alertBox: {
        status: false,
      },
    };
  },
};
</script>

<style></style>
