import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store/index";
Vue.use(VueRouter);
const beforeEnter = (to, from, next) => {
  store.dispatch("INIT_APP");
  next();
};

const routes = [
  {
    name: "app-start",
    path: "",
    redirect: "/",
    component: () =>
      import(/* webpackChunkName: "app-layout" */ "../layouts/default2.vue"),
    children: [
      {
        path: "/",
        beforeEnter,
        name: "Home",
        component: () =>
          import(/* webpackChunkName: "home" */ "../views/Home.vue"),
      },
      {
        path: "/products",
        beforeEnter,
        name: "Products",
        component: () =>
          import(/* webpackChunkName: "products" */ "../views/Products.vue"),
      },
      {
        path: "/product/:id",
        beforeEnter,
        name: "Product",
        component: () =>
          import(/* webpackChunkName: "product" */ "../views/Product.vue"),
      },
      {
        path: "/video-detail/:sectionId/:id",
        beforeEnter,
        name: "VideoDetail",
        component: () =>
          import(
            /* webpackChunkName: "videoDetail" */ "../views/VideoDetail.vue"
          ),
      },
      {
        path: "/categories",
        beforeEnter,
        name: "Categories",
        component: () =>
          import(
            /* webpackChunkName: "categories" */ "../views/Categories.vue"
          ),
      },
      {
        path: "/category/:id",
        beforeEnter,
        name: "Category",
        component: () =>
          import(/* webpackChunkName: "category" */ "../views/Category.vue"),
      },
      {
        path: "/type/:type",
        beforeEnter,
        name: "Type",
        component: () =>
          import(/* webpackChunkName: "category" */ "../views/Type.vue"),
      },
      {
        path: "/new-product",
        beforeEnter,
        name: "NewProduct",
        component: () =>
          import(
            /* webpackChunkName: "NewProduct" */ "../views/NewProduct.vue"
          ),
      },
      {
        path: "/user-list",
        beforeEnter,
        name: "UserList",
        component: () =>
          import(/* webpackChunkName: "UserList" */ "../views/UserList.vue"),
      },
      {
        path: "/instructor-list",
        beforeEnter,
        name: "InstructorList",
        component: () =>
          import(
            /* webpackChunkName: "InstructorList" */ "../views/InstructorList.vue"
          ),
      },
      {
        path: "/profile",
        beforeEnter,
        name: "UserList",
        component: () =>
          import(/* webpackChunkName: "Profile" */ "../views/Profile.vue"),
      },
      {
        path: "/payment-methods",
        beforeEnter,
        name: "PaymentMethods",
        component: () =>
          import(
            /* webpackChunkName: "PaymentMethods" */ "../views/PaymentMethods.vue"
          ),
      },
      {
        path: "/user/:id",
        beforeEnter,
        name: "User",
        component: () =>
          import(/* webpackChunkName: "UserList" */ "../views/User.vue"),
      },
      {
        path: "/comments",
        beforeEnter,
        name: "Comments",
        component: () =>
          import(/* webpackChunkName: "Comments" */ "../views/Comments.vue"),
      },
      {
        path: "/files",
        beforeEnter,
        name: "File",
        component: () =>
          import(/* webpackChunkName: "File" */ "../views/Files.vue"),
      },
      {
        path: "/video-detail/:sectionId/:videoId/quizes/:quizId",
        beforeEnter,
        name: "QuizDetail",
        component: () =>
          import(/* webpackChunkName: "File" */ "../views/QuizDetail.vue"),
      },
      {
        path: "/wp-connection",
        beforeEnter,
        name: "WPConnection",
        component: () =>
          import(
            /* webpackChunkName: "File" */ "../views/WhatsappConnection.vue"
          ),
      },
      {
        path: "/calendar",
        beforeEnter,
        name: "Calendar",
        component: () =>
          import(/* webpackChunkName: "Calendar" */ "../views/Calendar.vue"),
      },
      {
        path: "/course-insights",
        beforeEnter,
        name: "CourseInsights",
        component: () =>
          import(
            /* webpackChunkName: "CourseInsights" */ "../views/CourseInsights.vue"
          ),
      },
      {
        path: "/product-revenues",
        beforeEnter,
        name: "ProductRevenues",
        component: () =>
          import(
            /* webpackChunkName: "ProductRevenues" */ "../views/ProductRevenues.vue"
          ),
      },
      {
        path: "/instructor-revenues",
        beforeEnter,
        name: "InstructorRevenues",
        component: () =>
          import(
            /* webpackChunkName: "InstructorRevenues" */ "../views/InstructorRevenues.vue"
          ),
      },
      {
        path: "/orders",
        beforeEnter,
        name: "Orders",
        component: () =>
          import(/* webpackChunkName: "Orders" */ "../views/Orders.vue"),
      },
      {
        path: "/canceled-orders",
        beforeEnter,
        name: "CanceledOrders",
        component: () =>
          import(
            /* webpackChunkName: "CanceledOrders" */ "../views/CanceledOrders.vue"
          ),
      },
      {
        path: "/certificate",
        beforeEnter,
        name: "Certificate",
        component: () =>
          import(
            /* webpackChunkName: "Certificate" */ "../views/Certificate.vue"
          ),
      },
      {
        path: "/grade-book",
        beforeEnter,
        name: "GradeBook",
        component: () =>
          import(/* webpackChunkName: "GradeBook" */ "../views/GradeBook.vue"),
      },
      {
        path: "/quiz-statistics",
        beforeEnter,
        name: "QuizStatistics",
        component: () =>
          import(
            /* webpackChunkName: "QuizStatistics" */ "../views/QuizStatistics.vue"
          ),
      },
      {
        path: "/quiz-statistics/:id",
        beforeEnter,
        name: "QuizStatisticsByUser",
        component: () =>
          import(
            /* webpackChunkName: "QuizStatisticsByUser" */ "../views/QuizStatisticsByUser.vue"
          ),
      },
      {
        path: "/quiz-statistics/quiz/:id",
        beforeEnter,
        name: "QuizStatisticsByQuiz",
        component: () =>
          import(
            /* webpackChunkName: "QuizStatisticsByQuiz" */ "../views/QuizStatisticsByQuiz.vue"
          ),
      },
      {
        path: "/quizzes",
        beforeEnter,
        name: "Quizzes",
        component: () =>
          import(/* webpackChunkName: "Quizzes" */ "../views/Quizzes.vue"),
      },
      {
        path: "/definitions",
        beforeEnter,
        name: "Definitions",
        component: () =>
          import(
            /* webpackChunkName: "Definitions" */ "../views/Definitions.vue"
          ),
      },
      {
        path: "/definitions/:type",
        beforeEnter,
        name: "DefinitionTypes",
        component: () =>
          import(
            /* webpackChunkName: "DefinitionTypes" */ "../views/DefinitionTypes.vue"
          ),
      },
      {
        path: "/event-detail/:eventId/event-date/:eventItemId",
        beforeEnter,
        name: "File",
        component: () =>
          import(
            /* webpackChunkName: "EventDate" */ "../views/EventDetail.vue"
          ),
      },
      {
        path: "/packages",
        beforeEnter,
        name: "Packages",
        component: () =>
          import(/* webpackChunkName: "Packages" */ "../views/Packages.vue"),
      },
      {
        path: "/subscription",
        beforeEnter,
        name: "Subscription",
        component: () =>
          import(
            /* webpackChunkName: "Subscription" */ "../views/Subscription.vue"
          ),
      },
      {
        path: "/subscription-saved-card",
        beforeEnter,
        name: "SubscriptionSavedCard",
        component: () =>
          import(
            /* webpackChunkName: "SubscriptionSavedCard" */ "../views/SubscriptionSavedCard.vue"
          ),
      },
      {
        path: "/my-subscription",
        beforeEnter,
        name: "MySubscription",
        component: () =>
          import(
            /* webpackChunkName: "MySubscription" */ "../views/MySubscription.vue"
          ),
      },
      {
        path: "/pay-is-succeed",
        beforeEnter,
        name: "PayIsSucceed",
        component: () =>
          import(
            /* webpackChunkName: "PayIsSucceed" */ "../views/PayIsSucceed.vue"
          ),
      },
      {
        path: "/subscription-policy",
        beforeEnter,
        name: "SubscriptionPolicy",
        component: () =>
          import(
            /* webpackChunkName: "SubscriptionPolicy" */ "../views/SubscriptionPolicy.vue"
          ),
      },
      {
        path: "/my-academy",
        beforeEnter,
        name: "MyAcademy",
        component: () =>
          import(/* webpackChunkName: "MyAcademy" */ "../views/MyAcademy.vue"),
      },
      {
        path: "/my-academy/content",
        beforeEnter,
        name: "MyAcademyContent",
        component: () =>
          import(
            /* webpackChunkName: "MyAcademyContent" */ "../views/MyAcademy/Content.vue"
          ),
      },
      {
        path: "/my-academy/content/:type",
        beforeEnter,
        name: "MyAcademy_ContentView",
        component: () =>
          import(
            /* webpackChunkName: "MyAcademy_ContentView" */ "../views/MyAcademy/_ContentView.vue"
          ),
      },
      {
        path: "/my-academy",
        beforeEnter,
        name: "MyAcademy",
        component: () =>
          import(/* webpackChunkName: "MyAcademy" */ "../views/MyAcademy.vue"),
      },
    ],
  },
  {
    path: "/sign-in",
    name: "SignIn",
    component: () =>
      import(/* webpackChunkName: "SignIn" */ "../views/SignIn.vue"),
  },
  {
    path: "/sync-account",
    name: "CreateAccountComeFromSignUp",
    component: () =>
      import(
        /* webpackChunkName: "CreateAccountComeFromSignUp" */ "../views/CreateAccountComeFromSignUp/index.vue"
      ),
  },
  {
    path: "*",
    name: "Error",
    component: () =>
      import(/* webpackChunkName: "Error" */ "../views/Error.vue"),
  },
  {
    path: "/error",
    name: "Error",
    component: () =>
      import(/* webpackChunkName: "Error" */ "../views/Error.vue"),
  },
  // {
  //  path: "/about",
  //  name: "About",
  // route level code-splitting
  // this generates a separate chunk (about.[hash].js) for this route
  // which is lazy-loaded when the route is visited.
  //  component: () =>
  //    import(/* webpackChunkName: "about" */ "../views/About.vue"),
  // },
];

const router = new VueRouter({
  routes,
});

export default router;
