<template>
  <div class="my-4">
    <h4>
      <img src="/images/rez-logo.png" width="40" alt="" />
      {{ $t("serviceProductProvide") }}
    </h4>
    <div class="alert mt-4 alert-info">
      {{ $t("cameFromRezz") }}
    </div>
    <CheckRezervationConnected>
      <div class="row">
        <div class="col-12 col-md-6 col-lg-4 card">
          <div class="mb-3">
            <h5>{{ $t("chooseCompany") }}</h5>
            <p class="text-muted">{{ $t("chooseCompanyDescription") }}</p>
          </div>
          <div v-for="(company, i) in getRezzAppActiveUser.companies" :key="i">
            <div class="chekbox-lg">
              <label>
                <input
                  @change="GET_ROOM_LIST(company.companyId)"
                  type="checkbox"
                />
                <b
                  class="checkmark list"
                  :class="{
                    checked: company.company.slug === companyId,
                  }"
                >
                </b>
                <span class="ml-4"> {{ company.company.name }}</span>
              </label>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-4 card" v-if="companyId">
          <Loading v-if="roomLoading"></Loading>
          <div v-else>
            <div class="mb-3">
              <h5>{{ $t("chooseRoom") }}</h5>
              <p class="text-muted">{{ $t("chooseRoomDescription") }}</p>
            </div>
            <div v-for="(room, i) in roomList" :key="i">
              <div class="chekbox-lg">
                <label>
                  <input @change="GET_SERVICE_LIST(room.id)" type="checkbox" />
                  <b
                    class="checkmark list"
                    :class="{
                      checked: room.id === roomId,
                    }"
                  ></b>
                  <span> {{ room.title }} </span>
                </label>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-12 col-lg-4 card" v-if="roomId">
          <Loading v-if="serviceLoading"></Loading>
          <div v-else>
            <div class="mb-3">
              <h5>{{ $t("chooseServices") }}</h5>
              <p class="text-muted">{{ $t("chooseServicesDescription") }}</p>
            </div>
            <div class="text-right">
              <small class="cursor-pointer">
                <i @click="services = serviceList.map((item) => item.id)">
                  {{ $tc("selectAll") }}
                </i>
              </small>
            </div>
            <div v-for="(service, i) in serviceList" :key="i">
              <div class="chekbox-lg">
                <label>
                  <input @change="SET_SERVICE(service.id)" type="checkbox" />
                  <b
                    class="checkmark list"
                    :class="{
                      checked: services.includes(service.id),
                    }"
                  ></b>
                  <span>
                    {{ service.service.name }}
                    ({{ Currency({ price: service.price }) }} -
                    {{ service.time }} {{ $t("min") }})
                  </span>
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </CheckRezervationConnected>
  </div>
</template>

<script>
import CheckRezervationConnected from "../Shared/CheckRezervationConnected.vue";
import Loading from "../Shared/Loading.vue";
import { Currency } from "gurhan/helpers/Currency";
export default {
  data() {
    return {
      companyId: "",
      roomId: "",
      services: [],
      roomList: [],
      roomLoading: true,
      serviceLoading: true,
      serviceList: [],
      loaded: false,
    };
  },
  props: {
    value: {
      default: "",
    },
  },
  computed: {
    // rezervasyondan gelen hizmetler
    // rezervasyondan gelen hizmetler
    getConnected() {
      return this.$store.state.Rezervation.rezzAppActiveUser.status;
    },
    getRezzAppActiveUser() {
      return this.$store.state.Rezervation.rezzAppActiveUser;
    },
  },
  components: { CheckRezervationConnected, Loading },
  methods: {
    Currency,
    ON_INPUT() {
      if (!this.loaded) return;
      if (!this.companyId || !this.roomId || !this.services.length)
        return this.$emit("input", "");
      const query = `?filter[slug]=${this.companyId}&filter[roomId]=${
        this.roomId
      }&${this.services
        .map((id) => `filter[companyServiceIds][]=${id}`)
        .join("&")}`;
      this.$emit("input", query);
    },
    SET_SERVICE(serviceId) {
      const index = this.services.indexOf(serviceId);
      if (index > -1) {
        this.services.splice(index, 1);
      } else {
        this.services.push(serviceId);
      }
      this.ON_INPUT();
    },
    async GET_ROOM_LIST(companyId) {
      this.roomLoading = true;
      const company = this.getRezzAppActiveUser.companies.find(
        (company) =>
          company.companyId === companyId || company.company.slug === companyId
      );

      if (!company) {
        this.roomId = "";
        this.services = [];
        return (this.roomLoading = false);
      }

      this.companyId = company.company.slug;
      this.roomId = "";
      this.services = [];
      const response = await this.$apiForRez.get(
        "Room?filter[companyId]=" + company.companyId
      );
      if (response.message === "OK") {
        this.roomList = response.data.items;
      }
      this.roomLoading = false;

      this.ON_INPUT();
    },
    async GET_SERVICE_LIST(roomId) {
      this.serviceLoading = true;
      this.roomId = roomId;
      this.services = [];
      const response = await this.$apiForRez.get(
        "Room/" + roomId + "?includes=companyServices"
      );
      if (response.message === "OK") {
        this.serviceList = response.data?.includes?.companyServices || [];
      }
      this.serviceLoading = false;
      this.ON_INPUT();
    },
    async GET_SEANCE_PROCESSES() {
      console.log(this.value);

      if (
        this.value.includes("?filter[slug]=") &&
        this.value.includes("&filter[roomId]=") &&
        this.value.includes("&filter[companyServiceIds][]=")
      ) {
        console.log("evet.");

        const queryString = this.value;
        this.companyId = queryString.split("&")[0].split("=")[1];
        await this.GET_ROOM_LIST(this.companyId);
        this.roomId = queryString.split("&")[1].split("=")[1];
        await this.GET_SERVICE_LIST(this.roomId);
        const companyServicesLength = queryString.split(
          "&filter[companyServiceIds][]="
        ).length;
        console.log(companyServicesLength, "şength");

        for (let i = 1; i < companyServicesLength; i++) {
          const element = queryString.split("&filter[companyServiceIds][]=")[i];
          console.log(element);
          this.SET_SERVICE(element);
        }
        //this.services = queryString.split("&")[2].split("=")[1].split(",");
      }
      setTimeout(() => {
        this.loaded = true;
      }, 250);
    },
  },
  mounted() {
    this.GET_SEANCE_PROCESSES();
  },
};
</script>

<style></style>
