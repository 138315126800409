import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters(["getSettings"]),
  },
  methods: {
    openWindow() {
      window.open(
        `https://online.rezzapp.com/#/login`,
        // `http://localhost:8081/#/login`,
        "_blank",
        "height=800px, width=500px"
      );
    },
  },
};
