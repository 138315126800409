<template>
  <div class="my-4 bg-light card p-4">
    <h3>{{ $t("productHasLimit.title") }}</h3>
    <p>
      {{ $t("productHasLimit.description") }}
    </p>
    <p>
      <strong> {{ $t("productHasLimit.confirm") }} </strong>
    </p>
    <div class="my-4">
      <button
        @click.prevent="isLimit = true"
        class="btn btn-pill"
        :class="{ donate: isLimit }"
      >
        {{ $t("yes") }}
      </button>
      <button
        @click.prevent="isLimit = false"
        class="btn btn-pill"
        :class="{ donate: !isLimit }"
      >
        {{ $t("no") }}
      </button>
    </div>
    <div v-if="isLimit" class="vid-title" id="v-step-nc-0">
      <h2 class="title-hd">
        <i class="icon-pinned"></i> {{ $t("purchasedCount") }} *
      </h2>
      <div class="form_field">
        <input
          type="number"
          required
          :placeholder="$t('purchasedCount')"
          min="0"
          :value="value"
          @input="$emit('input', $event.target.value)"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      default: 0,
    },
  },
  data() {
    return {
      isLimit: false,
    };
  },
  mounted() {
    this.isLimit = this.value > 0;
  },
};
</script>

<style></style>
