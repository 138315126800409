var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"my-4"},[_c('h4',[_c('img',{attrs:{"src":"/images/rez-logo.png","width":"40","alt":""}}),_vm._v(" "+_vm._s(_vm.$t("serviceProductProvide"))+" ")]),_c('div',{staticClass:"alert mt-4 alert-info"},[_vm._v(" "+_vm._s(_vm.$t("cameFromRezz"))+" ")]),_c('CheckRezervationConnected',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-md-6 col-lg-4 card"},[_c('div',{staticClass:"mb-3"},[_c('h5',[_vm._v(_vm._s(_vm.$t("chooseCompany")))]),_c('p',{staticClass:"text-muted"},[_vm._v(_vm._s(_vm.$t("chooseCompanyDescription")))])]),_vm._l((_vm.getRezzAppActiveUser.companies),function(company,i){return _c('div',{key:i},[_c('div',{staticClass:"chekbox-lg"},[_c('label',[_c('input',{attrs:{"type":"checkbox"},on:{"change":function($event){return _vm.GET_ROOM_LIST(company.companyId)}}}),_c('b',{staticClass:"checkmark list",class:{
                  checked: company.company.slug === _vm.companyId,
                }}),_c('span',{staticClass:"ml-4"},[_vm._v(" "+_vm._s(company.company.name))])])])])})],2),(_vm.companyId)?_c('div',{staticClass:"col-12 col-md-6 col-lg-4 card"},[(_vm.roomLoading)?_c('Loading'):_c('div',[_c('div',{staticClass:"mb-3"},[_c('h5',[_vm._v(_vm._s(_vm.$t("chooseRoom")))]),_c('p',{staticClass:"text-muted"},[_vm._v(_vm._s(_vm.$t("chooseRoomDescription")))])]),_vm._l((_vm.roomList),function(room,i){return _c('div',{key:i},[_c('div',{staticClass:"chekbox-lg"},[_c('label',[_c('input',{attrs:{"type":"checkbox"},on:{"change":function($event){return _vm.GET_SERVICE_LIST(room.id)}}}),_c('b',{staticClass:"checkmark list",class:{
                    checked: room.id === _vm.roomId,
                  }}),_c('span',[_vm._v(" "+_vm._s(room.title)+" ")])])])])})],2)],1):_vm._e(),(_vm.roomId)?_c('div',{staticClass:"col-12 col-md-12 col-lg-4 card"},[(_vm.serviceLoading)?_c('Loading'):_c('div',[_c('div',{staticClass:"mb-3"},[_c('h5',[_vm._v(_vm._s(_vm.$t("chooseServices")))]),_c('p',{staticClass:"text-muted"},[_vm._v(_vm._s(_vm.$t("chooseServicesDescription")))])]),_c('div',{staticClass:"text-right"},[_c('small',{staticClass:"cursor-pointer"},[_c('i',{on:{"click":function($event){_vm.services = _vm.serviceList.map((item) => item.id)}}},[_vm._v(" "+_vm._s(_vm.$tc("selectAll"))+" ")])])]),_vm._l((_vm.serviceList),function(service,i){return _c('div',{key:i},[_c('div',{staticClass:"chekbox-lg"},[_c('label',[_c('input',{attrs:{"type":"checkbox"},on:{"change":function($event){return _vm.SET_SERVICE(service.id)}}}),_c('b',{staticClass:"checkmark list",class:{
                    checked: _vm.services.includes(service.id),
                  }}),_c('span',[_vm._v(" "+_vm._s(service.service.name)+" ("+_vm._s(_vm.Currency({ price: service.price }))+" - "+_vm._s(service.time)+" "+_vm._s(_vm.$t("min"))+") ")])])])])})],2)],1):_vm._e()])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }