<template>
  <div
    class="row justify-content-end mt-4"
    v-if="selectedAllItemWithoutPage || checkedList.length !== 0"
  >
    <div class="col-6 col-md-4 col-lg-3">
      <export-excel
        :name="'Heocademy_' + name + '.' + getExcelName"
        :fields="fields"
        ref="excel"
        :data="checkedList"
        :type="getExcelName"
      >
        <div></div>
      </export-excel>

      <button
        v-if="selectedAllItemWithoutPage"
        @click="GET_ALL(true)"
        class="bg-success text-white w-100 btn d-block p-2 btn-pill d-flex align-items-center justify-content-center"
      >
        ({{ count }}) {{ $t(selectable ? "saveUsers" : "exportExcell") }}
      </button>
      <button
        v-else-if="checkedList.length !== 0"
        @click="CHECKED_LIST(true)"
        class="bg-success text-white w-100 btn d-block p-2 btn-pill d-flex align-items-center justify-content-center"
      >
        ({{ checkedList.length }})
        {{ $t(selectable ? "saveUsers" : "exportExcell") }}
      </button>
    </div>
    <div class="col-6 col-md-4 col-lg-3" v-if="emailSendable">
      <button
        @click="GET_ALL(false)"
        v-if="selectedAllItemWithoutPage"
        class="subscribe w-100 d-block p-2 btn-pill d-flex align-items-center justify-content-center"
      >
        ({{ count }}) {{ $t(selectable ? "saveUsers" : "sendMail") }}
      </button>
      <button
        @click="CHECKED_LIST()"
        v-else-if="checkedList.length !== 0"
        class="subscribe w-100 d-block p-2 btn-pill d-flex align-items-center justify-content-center"
      >
        ({{ checkedList.length }})
        {{ $t(selectable ? "saveUsers" : "sendMail") }}
      </button>
    </div>
  </div>
</template>

<script>
import { formatter } from "gurhan/helpers/Date";
import { mapGetters } from "vuex";

export default {
  props: {
    fields: {
      default: () => ({}),
    },
    selectedAllItemWithoutPage: {
      default: false,
    },
    checkedList: {
      default: [],
    },
    count: {
      default: 0,
    },
    selectable: {
      default: false,
    },
    name: {
      default: "",
    },
  },
  computed: {
    ...mapGetters(["getExcelName"]),
    emailSendable() {
      return this.checkedList.some((c) => c.email);
    },
  },
  methods: {
    formatter,
    SET_CHECK_LIST_FOR_EXCEL() {
      setTimeout(() => {
        this.$refs.excel.generate();
      }, 250);
    },
    SET_CHECK_LIST(checkedList) {
      return checkedList
        .map((c) => c.email)
        .reduce(function (a, b) {
          if (a.indexOf(b) < 0) a.push(b);
          return a;
        }, [])
        .join(",");
    },
    CHECKED_LIST(fromFetch = false) {
      if (fromFetch) {
        return this.SET_CHECK_LIST_FOR_EXCEL(this.checkedList);
      }
      this.$store.commit("SET_MAIL_DRAWER", {
        status: true,
        form: {
          to: "",
          bcc: this.SET_CHECK_LIST(this.checkedList),
          subject: ``,
          content: "",
          files: [],
        },
      });
    },
    GET_ALL(fromFetch = false) {
      this.$store.commit("SET_LOADING_MODAL", {
        loading: true,
        alertBox: {
          status: false,
          variant: "success",
          message: "",
        },
      });
      this.$emit("getAll", this.GET_RESPONSE, fromFetch);
    },
    GET_RESPONSE(checkedList, fromFetch = false) {
      this.$store.commit("SET_LOADING_MODAL", {
        loading: false,
        alertBox: {
          status: false,
          variant: "success",
          message: "",
        },
      });
      if (fromFetch) return this.SET_CHECK_LIST_FOR_EXCEL(checkedList);
      setTimeout(() => {
        this.$store.commit("SET_MAIL_DRAWER", {
          status: true,
          form: {
            to: "",
            bcc: this.SET_CHECK_LIST(checkedList),
            subject: ``,
            content: "",
            files: [],
          },
        });
      }, 250);
    },
  },
};
</script>

<style></style>
