<template>
  <div>
    <div v-for="(item, i) in list" :key="i">
      <h4 class="mb-4">
        {{ i + 1 }}. {{ $t(`contents.${$route.params.type}List`) }}
      </h4>
      <FormView :items="[item]" :fields="fields"></FormView>
    </div>
    <div v-if="list.length === 0">
      <no-found no-button></no-found>
    </div>
    <hr />
    <div class="mt-5">
      <button @click="insertList()" class="btn w-100 btn-pill donate">
        + {{ $t("addContent") }}
      </button>
    </div>
  </div>
</template>

<script>
import FormView from "./FormView.vue";
import NoFound from "../../Shared/NoFound.vue";
export default {
  components: {
    FormView,
    NoFound,
  },
  data() {
    return {
      list: [],
    };
  },
  props: {
    items: {
      default: () => [],
    },
    fields: {
      default: () => [],
    },
  },
  methods: {
    insertList() {
      this.$router.replace({ query: { ...this.$route.query, lang: "TR" } });
      setTimeout(() => {
        this.list.push({
          title: "",
          description: "",
          degrees: "",
          imagePath: null,
          language: "TR",
        });
      }, 250);
    },
  },
  mounted() {
    this.list = [...this.items];
  },
};
</script>

<style></style>
