import Vue from "vue";
const vm = Vue.prototype;
import router from "../../router";
import i18n from "../../plugins/i18n";

export default {
  state: {
    customerList: [], // customerList list
    customerListIsLoading: false, // loading
    newCustomerListIsLoading: false, // loading
    customerBoughtItemList: [], // kullanıcının satın aldığı ürünler listesi.
    customerBoughtItemAreLoading: false, // kullanıcının satın aldığı liste loadingi.
  },
  mutations: {
    // for set customer which is get the customerList
    SET_CUSTOMER_LIST(state, payload) {
      // section içine video arrayinin açılması gerekiyor. açılan arrayin içine sectiona ait videolar gelecek.
      state.customerList.push(payload);
    },
    // for loading
    SET_CUSTOMER_LIST_IS_LOADING(state, payload) {
      state.customerListIsLoading = payload;
    },
    SET_NEW_CUSTOMER_IS_LOADING(state, payload) {
      state.newCustomerListIsLoading = payload;
    },
    // for update customerList
    SET_UPDATE_CUSTOMER_LIST(state, payload) {
      const customerListIndex = state.customerList.findIndex(
        (c) => c.id === payload.id
      );
      if (customerListIndex !== -1) {
        // computed güncellenmesi için silip ekleme gerekli.
        const customer = state.customerList[customerListIndex];
        state.customerList.splice(customerListIndex, 1);
        state.customerList.push({
          ...customer,
          ...payload,
        });
      }
    },
    SET_REMOVE_CUSTOMER_LIST(state, { id }) {
      const customerListIndex = state.customerList.findIndex(
        (c) => c.id === id
      );
      if (customerListIndex !== -1)
        state.customerList[customerListIndex].isActive = 0;
    },
    SET_CUSTOMER_DETAIL_BOUGHT_ITEMS(state, payload) {
      if (payload.force) state.customerBoughtItemList = [];
      state.customerBoughtItemList.push(payload);
    },
    SET_CUSTOMER_DETAIL_BOUGHT_ITEMS_LOADING(state, payload) {
      state.customerBoughtItemAreLoading = payload;
    },
  },
  actions: {
    // get customerList
    async GET_CUSTOMER_TO_LIST({ state, commit }, { id }) {
      if (state.customerList.find((p) => p.id === id)) return;
      commit("SET_CUSTOMER_LIST_IS_LOADING", true);
      const response = await vm.$api.get(`Users/${id}`);
      if (response.message === "OK" && response.result === "OK") {
        commit("SET_CUSTOMER_LIST", { ...response.data });
      } else router.push("/error");
      commit("SET_CUSTOMER_LIST_IS_LOADING", false);
    },
    //? TODO customer'ı sil
    async SEND_TO_REMOVE_CUSTOMER_LIST({ commit }, { id }) {
      const response = await vm.$api.delete(`Users/${id}`);
      if (response.message === "OK" && response.result === "OK") {
        commit("SET_REMOVE_CUSTOMER_LIST", {
          id,
        }); // customerDetail.js
        commit("SET_REMOVE_CUSTOMER", {
          id,
        }); // customers.js
        commit("SET_LOADING_MODAL", {
          loading: true,
          alertBox: {
            status: true,
            variant: "success",
            message: i18n.t("insertIsSucceed"),
          },
        });
        return {
          variant: "success",
          message: i18n.t("insertIsSucceed"),
          status: true,
        };
      } else {
        return {
          variant: "danger",
          message: i18n.t("insertIsNotSucceed"),
          status: true,
        };
      }
    },
    async SEND_UPDATE_CUSTOMER_DETAIL({ commit }, { fd, form }) {
      commit("SET_NEW_CUSTOMER_IS_LOADING", true);
      const response = await vm.$api.put(`Users/${form.id}`, fd);
      if (response.message === "OK" && response.result === "OK") {
        commit("SET_UPDATE_CUSTOMER_LIST", form); // customerDetail.js
        commit("SET_UPDATE_CUSTOMER_TO_LIST", form); // customers.js
        commit("SET_LOADING_MODAL", {
          loading: true,
          alertBox: {
            status: true,
            variant: "success",
            message: i18n.t("updatedIsSucceed"),
          },
        });
        commit("SET_NEW_CUSTOMER_IS_LOADING", false);
        return {
          variant: "success",
          status: true,
          message: i18n.t("updatedIsSucceed"),
        };
      } else {
        return {
          status: true,
          variant: "danger",
          message: i18n.t("updatedIsNotSucceed"),
        };
      }
    },
    async GET_CUSTOMER_DETAIL_BOUGHT_ITEMS(
      { state, commit },
      { id, force = false, search = "" }
    ) {
      if (
        state.customerBoughtItemList.findIndex((c) => c.userId === id) !== -1 &&
        !force
      )
        return;
      commit("SET_CUSTOMER_DETAIL_BOUGHT_ITEMS_LOADING", true);
      const response = await vm.$api.get(
        `Products/ProductList?userId=${id}&searchKey=${search}`
      );
      if (response.message === "OK" && response.result === "OK") {
        commit("SET_CUSTOMER_DETAIL_BOUGHT_ITEMS", {
          userId: id,
          data: response.data,
          force,
        });
      }
      commit("SET_CUSTOMER_DETAIL_BOUGHT_ITEMS_LOADING", false);
    },
  },
};
