import AlertBox from "./AlertBox";
export default {
  mixins: [AlertBox],
  methods: {
    listenMessage() {
      const vm = this;
      console.log("çalıştı yani.");

      window.addEventListener(
        "message",
        function (e) {
          // e.data hold the message from child
          if (e.data?.token) {
            localStorage.setItem("rez_info", e.data.token);
            vm.$store.commit("SET_REZZAPP_ACTIVE_USER", {
              ...e.data,
              status: true,
              loading: false,
            });
            vm.SET_ALERT_BOX("success", vm.$t("connectionIsSucceed"));
          }
        },
        false
      );
    },
  },
  created() {
    this.listenMessage();
  },
  mounted() {
    this.$store.dispatch("GET_REZZAPP_INFO");
  },
};
