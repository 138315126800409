<template>
  <div :class="{ card: !noCard }" class="rounded my-4">
    <div class="card-title mb-0">
      <div class="d-flex justify-content-between mt-4">
        <div class="col-12 col-md-6 col-lg-4 col-xl-4">
          <p v-if="checkedList.length !== 0">
            {{
              $t("choosedUser", {
                count: selectedAll ? countItem : checkedList.length,
              })
            }}
            <span
              @click="SET_CHECKED_ALL(true, true)"
              v-if="
                !selectedAll &&
                countItem !== checkedList.length &&
                checkedList.length !== 0 &&
                countItem !== items.length
              "
              class="tel-link"
            >
              {{ $t("chooseAll", { count: countItem }) }}
            </span>
          </p>
        </div>
        <div v-if="!noSearch" class="col-12 col-md-6 col-lg-4 col-xl-4">
          <div class="search_form w-100">
            <form @submit="SET_SUBMIT">
              <input
                required="required"
                type="text"
                name="search"
                v-model="search"
                @keyup="SET_SUBMIT"
                :placeholder="searchPlaceholder"
              /><button type="submit"><i class="icon-search"></i></button>
            </form>
          </div>
        </div>
      </div>
      <Header
        v-if="!hideHeader"
        @change:scroll="(e) => (scrollLeft = e)"
        @change:checkedAll="SET_CHECKED_ALL"
        :scrollLeft="scrollLeft"
        :checkedList="checkedList"
        :items="items"
        :checkable="checkable"
        :singleCheckable="singleCheckable"
        :allChecked="allChecked"
        :headers="headers"
      ></Header>
    </div>
    <div v-if="loading" class="text-center">
      <loading></loading>
    </div>
    <Body
      @change:checked="SET_CHECK_LIST"
      :checkedList="checkedList"
      :headers="headers"
      :checkable="checkable"
      :singleCheckable="singleCheckable"
      :hideHeader="hideHeader"
      :checkedKey="checkedKey"
      :scrollLeft="scrollLeft"
      :items="items"
      v-else-if="headers.length !== 0 && items.length !== 0"
    >
      <template
        v-slot:[header]="{ item, index }"
        v-for="(header, i) in scopedSlots"
      >
        <div :key="'a' + i">
          <slot :item="item" :index="index" :name="header"></slot>
        </div>
      </template>
    </Body>
    <div class="text-center my-3" v-else>
      <img src="/images/no-found-category.svg" width="200" alt="" />
      <p>{{ emptyText }}</p>
    </div>
  </div>
</template>

<script>
import Header from "./Header.vue";
import Body from "./Body.vue";
export default {
  data() {
    return {
      search: "",
      selectedAll: false,
      searchTimeout: -1,
      scrollLeft: 0,
      countItem: 0,
      scopedSlots: [],
      checkedList: [],
    };
  },
  props: {
    searchPlaceholder: {
      default: "",
    },
    initSearch: {
      default: "",
    },
    headers: {
      default: () => [],
    },
    items: {
      default: () => [],
    },
    noSearch: {
      default: false,
      type: Boolean,
    },
    checkable: {
      default: false,
      type: Boolean,
    },
    singleCheckable: {
      default: false,
      type: Boolean,
    },
    loading: {
      default: false,
      type: Boolean,
    },
    hideHeader: {
      default: false,
      type: Boolean,
    },
    noCard: {
      default: false,
      type: Boolean,
    },
    emptyText: {
      type: String,
      default: function () {
        return this.$t("noFoundData");
      },
    },
    checkedKey: {
      default: "id",
    },
    count: {
      default: 0,
    },
    checkValue: {
      default: () => [],
    },
  },
  watch: {
    checkValue() {
      this.checkedList = this.checkValue;
    },
    initSearch(data) {
      this.search = data;
    },
    items() {
      if (this.count === 0) this.countItem = this.items.length;
      else this.countItem = this.count;
    },
    checkedList(data) {
      this.$emit("change:checkedList", data);
    },
  },
  computed: {
    allChecked() {
      return this.checkedList.length === this.items.length;
    },
  },
  methods: {
    SET_CHECKED_ALL(data, getAllItem = false) {
      this.selectedAll = getAllItem;
      if (getAllItem) this.$emit("change:selectAllWithoutPage", true);
      if (data) {
        this.checkedList = [...this.items];
      } else {
        this.$emit("change:selectAllWithoutPage", false);
        this.checkedList = [];
      }
    },
    SET_CHECK_LIST(data) {
      this.selectedAll = false;
      this.$emit("change:selectAllWithoutPage", false);
      const index = this.checkedList.findIndex(
        (item) => item[this.checkedKey] === data[this.checkedKey]
      );
      if (index === -1 && this.singleCheckable) {
        this.checkedList = [];
        this.checkedList.push(data);
      } else if (index === -1) this.checkedList.push(data);
      else this.checkedList.splice(index, 1);
    },
    SET_SUBMIT(e) {
      e.preventDefault();
      if (this.searchTimeout !== -1) clearTimeout(this.searchTimeout);
      this.searchTimeout = setTimeout(() => {
        this.$emit("change:search", this.search);
      }, 1000);
    },
  },
  components: { Header, Body },
  mounted() {
    this.scopedSlots = Object.keys(this.$scopedSlots);
    if (this.count === 0) this.countItem = this.items.length;
    else this.countItem = this.count;
    this.checkedList = this.checkValue;
    this.search = this.initSearch;
  },
};
</script>
