export default {
  computed: {
    getActivePlan() {
      return this.$store.getters["getActivePlan"];
    },
    getActiveStatus() {
      return this.$store.getters["getActivePlan"].status;
    },
    getSettings() {
      return this.$store.state.User.settings;
    },
  },
  watch: {
    getActiveStatus() {
      this.CHECK_PLAN_LOADING();
    },
  },
  methods: {
    CHECK_PLAN_LOADING() {
      if (
        this.getActiveStatus &&
        this.getSettings.creditCardConfirmDate === null
      ) {
        this.$router.push("/subscription-policy");
      }
    },
  },
  mounted() {
    this.CHECK_PLAN_LOADING();
  },
};
