<template>
  <div class="top-bar d-none d-lg-flex" v-if="getActivePlan.status">
    <a href="https://heocademy.com" target="_blank" class="text-white"
      >Heocademy.com Yönetim Paneli 2024 | {{ getActivePlan?.package?.name }}
    </a>
    <div>
      <router-link class="text-white" to="/profile?push=language">
        <img
          width="21"
          :src="`https://flagicons.lipis.dev/flags/4x3/${$i18n.locale}.svg`"
          alt=""
        />
        {{ $t(`languages.${$i18n.locale}`) }} |
      </router-link>
      <a class="text-white" :href="`https://${getSettings.website}`">
        {{ getSettings.title }}
      </a>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    getSettings() {
      return this.$store.state.User.settings;
    },
    getActivePlan() {
      return this.$store.state.User.activePlan;
    },
  },
};
</script>

<style></style>
