<template>
  <div>
    <div v-if="connected">
      <slot></slot>
    </div>
    <div v-else class="text-center">
      <img src="/images/rezzapp.png" width="50" alt="" />
      <p>{{ $t("requiredRezzApp") }}</p>
      <p v-html="$t('connectionToRezz')"></p>
      <div class="mt-2 text-center">
        <button @click.prevent="openWindow()" class="btn btn-pill btn-dark">
          {{ $t("connectToRezz") }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import ConnectionRezzervasyon from "../../mixins/ConnectionRezzervasyon";
export default {
  mixins: [ConnectionRezzervasyon],
  computed: {
    // rezervasyondan bağlantısı
    connected() {
      return this.$store.state.Rezervation.rezzAppActiveUser.status;
    },
  },
};
</script>

<style></style>
