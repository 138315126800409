<template>
  <div>
    <h5 class="mb-2">
      <img src="/panel/favicon-32x32.png" width="24" alt="" /> {{ $t(title) }}
      <span v-if="count" class="text-muted">{{ count }}</span>
    </h5>
    <p class="text-muted">{{ $t(`${title}Description`) }}</p>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      default: "",
    },
    count: {
      default: "",
    },
  },
};
</script>

<style></style>
