<template>
  <div>
    <MyAcademyTitle title="myDefaultImage"></MyAcademyTitle>
    <div class="bg-light card p-3 my-3 card">
      <p>{{ $t("myDefaultImageDescription2") }}</p>
    </div>
    <p>
      {{ $t("youCanUploadFaviconThisLink") }}:
      <a target="_blank" href="https://realfavicongenerator.net"
        >https://realfavicongenerator.net</a
      >
    </p>
    <div class="mt-4 d-flex align-items-center" v-if="getSettings">
      <img
        @error="(e) => (e.target.src = getSettings.logo)"
        :src="getSettings.favicon"
        width="24"
        alt=""
      />
      <input
        class="d-none"
        type="file"
        ref="file"
        accept="image/*"
        @change="(e) => uploadImage(e, 'DefaultImage', 32)"
        id=""
      />
      <button
        @click="$refs.file.click()"
        :disabled="actionIsLoading"
        class="ml-4 btn btn-pill subscribe"
      >
        <Loading v-if="actionIsLoading"></Loading>
        <span v-else>{{ $t("upload") }}</span>
      </button>
    </div>
  </div>
</template>

<script>
import MyAcademyTitle from "./MyAcademyTitle.vue";
import Loading from "../Shared/Loading.vue";
import actionMixin from "./action.mixin";
export default {
  components: { MyAcademyTitle, Loading },
  mixins: [actionMixin],
};
</script>

<style></style>
