<template>
  <div>
    <iframe
      width="100%"
      height="1000"
      v-if="!isLoading"
      :src="link"
      frameborder="0"
    ></iframe>
  </div>
</template>

<script>
import { encrypt, decrypto } from "../../helpers/subscription.helper";
import Modules from "../../mixins/Modules";
export default {
  mixins: [Modules],
  data() {
    return {
      isLoading: true,
      item: {
        amount: "",
        currency: "",
        customerId: "",
        packageId: "",
        productId: "",
        token: "",
      },
      packageItem: {
        id: -1,
        amount: 0,
        currency: "USD",
      },
      link: "",
      callbackUrl: "/pay-is-succeed",
    };
  },
  computed: {
    getActiveStatus() {
      return this.$store.getters["getActivePlan"].status;
    },
    getSettings() {
      return this.$store.state.User.settings;
    },
  },
  methods: {
    GET_DECRYPTO() {
      const q = this.$route.query;

      if (q.hash) {
        try {
          if (this.getSettings.creditCardConfirmDate === null)
            return this.$router.push(
              "/subscription-policy?redirect=subscription&hash=" + q.hash
            );

          this.packageItem = JSON.parse(decrypto(q.hash));
          this.GET_TOKEN_OF_SUBSCRIPTION();
          this.callbackUrl = q.callback || "/pay-is-succeed";
        } catch (error) {
          this.$router.go(-1);
        }
      } else this.$router.go(-1);
    },
    async GET_TOKEN_OF_SUBSCRIPTION() {
      this.isLoading = true;
      const response = await this.$apiHeocademy.post(
        "SubscriptionManagement/RemoteUserLogin"
      );
      if (response.message === "OK") {
        const data = response.data;
        if (data.message === "OK") {
          this.item = {
            customerId: data.data.remoteId,
            productId: "65d34989216d7050bcf4f41a",
            token: data.data.token,
            packageId: this.packageItem.id,
            amount: this.packageItem.amount,
            currency: this.packageItem.currency,
            lang: this.$i18n.locale,
          };
          const hash = encrypt(JSON.stringify(this.item));
          this.link = `https://payment.eralpsoftware.net?token=${hash}`;
          this.isLoading = false;
        }
      }
    },
    async handleMsg(e) {
      if (e?.data === "PAY_IS_SUCCEED") {
        if (this.getActivePlan.id) await this.CANCEL_SUBSCRIPTION();
        this.$store.dispatch("GET_ACTIVE_PLAN");
        this.$router.push(this.callbackUrl || "/pay-is-succeed");
        this.link = "";
      } else if (e?.data === "PAY_IS_FAILED") this.$router.push("/packages");
    },
    async CANCEL_SUBSCRIPTION() {
      const response = await this.$apiHeocademy.delete(
        `SubscriptionManagement/${this.getActivePlan.id}`
      );
      return response.message === "OK";
    },
  },
  mounted() {
    this.GET_DECRYPTO();
    window.addEventListener("message", this.handleMsg, true);
  },
};
</script>

<style></style>
