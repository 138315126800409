import { quality } from "../../helpers/image";
import AlertBox from "../../mixins/AlertBox";
export default {
  mixins: [AlertBox],
  data() {
    return {
      actionIsLoading: false,
      changedImage: {
        image: null,
        file: null,
      },
    };
  },
  computed: {
    getSettings() {
      return this.$store.state.User.settings;
    },
  },
  methods: {
    async uploadImage(e, field, width = 250) {
      this.actionIsLoading = true;
      const file = e.target.files[0];
      const response = await quality(file, width, 1);
      this.changedImage = { image: response.result, file: response.file };
      this.companyPut({ [field]: this.changedImage.file });
    },
    async companyPut(body) {
      this.actionIsLoading = true;
      this.SET_LOADING_MODAL(true);

      // convert object to formdata
      const fd = new FormData();
      for (const key in body) {
        fd.append(key, body[key]);
      }
      const response = await this.$apiHeocademy.put(
        "Companies/" + this.getSettings.id,
        fd
      );
      if (response.message === "OK") {
        this.SET_ALERT_BOX("success", this.$t("updatedIsSucceed"), 3000);
        this.$store.commit("SETTINGS_IS_LOADED", {
          settings: this.getSettings,
          status: false,
        });
        this.$store.dispatch("GET_COMPANY_SETTINGS");
      } else this.SET_ALERT_BOX("danger", this.$t("updatedIsNotSucceed"), 3000);
      this.actionIsLoading = false;
    },
  },
};
