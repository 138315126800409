import Vue from "vue";
const vm = Vue.prototype;
import i18n from "../../plugins/i18n";
import router from "../../router";
export default {
  state: {
    products: [], // product list
    productIsLoading: false, // loading
    videoIsUploading: false, // when video which is add to section is uploading
    videoThatIsUploadıng: {
      form: {
        video: "",
      },
    }, // video which is add to section as file
  },
  mutations: {
    // for set produch which is get the product
    SET_PRODUCT_LIST(state, payload) {
      // section içine video arrayinin açılması gerekiyor. açılan arrayin içine sectiona ait videolar gelecek.
      state.products.push({
        ...payload,
        sections: payload.sections
          ? payload.sections.map((s) => {
              return {
                ...s,
                videos: [],
                isLoading: false,
                isExpanded: false,
              };
            })
          : [],
      });
    },
    // for loading
    SET_PRODUCT_IS_LOADING(state, payload) {
      state.productIsLoading = payload;
    },
    // for update product
    SET_UPDATE_PRODUCT(state, payload) {
      const productIndex = state.products.findIndex((c) => c.id === payload.id);
      if (productIndex !== -1) {
        state.products.splice(productIndex, 1);
        state.products.push({
          ...state.products[productIndex],
          ...payload,
        });
      }
    },
    // update section of product
    SET_UPDATE_SECTION_OF_PRODUCT(state, { form, productIndex, sectionIndex }) {
      state.products[productIndex].sections[sectionIndex].name = form.name;
    },
    // add new section of product
    SET_ADD_SECTION_OF_PRODUCT(state, { productIndex, form }) {
      state.products[productIndex].sections.push(form);
    },
    // remove section of product
    SET_REMOVE_SECTION_OF_PRODUCT(state, { productIndex, sectionIndex }) {
      state.products[productIndex].sections.splice(sectionIndex, 1);
    },
    // section loading
    SET_SECTION_IS_LOADING(state, { productIndex, sectionIndex, status }) {
      state.products[productIndex].sections[sectionIndex].isLoading = status;
    },
    // section expanded
    SET_SECTION_IS_EXPANDED(state, { productId, sectionIndex, status }) {
      const index = state.products.findIndex((p) => p.id === productId);
      state.products[index].sections[sectionIndex].isExpanded = status;
    },
    // videoları sectiona ata.
    SET_VIDEOS_OF_SECTION(state, { productIndex, sectionIndex, data }) {
      state.products[productIndex].sections[sectionIndex].videos = data;
    },
    SET_VIDEO_IS_UPLOADING(state, payload) {
      state.videoIsUploading = payload;
    },
    SET_VIDEO_THAT_IS_UPLOADING(state, payload) {
      state.videoThatIsUploadıng = payload;
    },
    SET_REMOVE_PRODUCT(state, { productIndex }) {
      state.products.splice(productIndex, 1);
    },
    SET_VIDEO_TO_SECTION(state, { form, productIndex, sectionIndex }) {
      state.products[productIndex].sections[sectionIndex].videos.push(form);
    },
  },
  actions: {
    // get product
    async GET_PRODUCT({ state, commit }, { path, id, type }) {
      if (state.products.find((p) => p.id === id)) return;
      commit("SET_PRODUCT_IS_LOADING", true);
      const response = await vm.$api.get(path);
      if (response.message === "OK" && response.result === "OK") {
        commit("SET_PRODUCT_LIST", {
          ...response.data,
          sections: sortArray(response.data.sections),
          type,
        });
      } else router.push("/error");
      commit("SET_PRODUCT_IS_LOADING", false);
    },
    // update section
    async SEND_UPDATE_SECTION_OF_PRODUCT(
      { commit },
      { form, productIndex, sectionIndex }
    ) {
      const response = await vm.$api.put(`Sections/${form.id}`, {
        name: form.name,
      });
      if (response.message === "OK" && response.result === "OK") {
        commit("SET_UPDATE_SECTION_OF_PRODUCT", {
          form,
          productIndex,
          sectionIndex,
        });
        return {
          variant: "success",
          message: i18n.t("updatedIsSucceed"),
          status: true,
        };
      } else {
        return {
          variant: "danger",
          message: i18n.t("updatedIsNotSucceed"),
          status: true,
        };
      }
    },
    //? TODO  videoları çek. forEach olduğundan dolayı then kullanıldı.
    async GET_VIDEOS_OF_SECTIONS({ commit }, { sections, productIndex }) {
      if (sections.length === 0) return; // sections yoksa dön
      sections.forEach((section, index) => {
        if (section.videos.length !== 0) return; // daha önce videolar çekilmişse dön
        commit("SET_SECTION_IS_LOADING", {
          productIndex,
          sectionIndex: index,
          status: true,
        });
        vm.$api.get(`Videos?sectionId=${section.id}`).then((response) => {
          if (response.message === "OK" && response.result === "OK")
            commit("SET_VIDEOS_OF_SECTION", {
              productIndex,
              sectionIndex: index,
              data: response.data.map((d) => {
                return {
                  ...d,
                  sectionId: section.id,
                };
              }),
            });
          else
            commit("SET_VIDEOS_OF_SECTION", {
              productIndex,
              sectionIndex: index,
              data: [],
            });
          commit("SET_SECTION_IS_LOADING", {
            productIndex,
            sectionIndex: index,
            status: false,
          });
        });
      });
    },
    // add section of product
    async SEND_ADD_SECTION_OF_PRODUCT({ commit }, { productIndex, form }) {
      const response = await vm.$api.post(`Sections`, {
        name: form.name,
        productId: form.productId,
      });
      if (response.message === "OK" && response.result === "OK") {
        commit("SET_ADD_SECTION_OF_PRODUCT", {
          form: {
            ...form,
            id: response.data,
            videos: [],
          },
          productIndex,
        });
        return {
          variant: "success",
          message: i18n.t("insertIsSucceed"),
          status: true,
        };
      } else {
        return {
          variant: "danger",
          message: i18n.t("insertIsNotSucceed"),
          status: true,
        };
      }
    },
    //? TODO section'ı sil
    async SEND_TO_REMOVE_SECTION(
      { commit },
      { productIndex, id, sectionIndex }
    ) {
      const response = await vm.$api.delete(`Sections/${id}`);
      if (response.message === "OK" && response.result === "OK") {
        commit("SET_REMOVE_SECTION_OF_PRODUCT", {
          productIndex,
          sectionIndex,
        });
        return {
          variant: "success",
          message: i18n.t("updatedIsSucceed"),
          status: true,
        };
      } else {
        return {
          variant: "danger",
          message: i18n.t("updatedIsNotSucceed"),
          status: true,
        };
      }
    },
    //? TODO section'ı sil
    async SEND_TO_REMOVE_PRODUCT(
      { commit },
      { productIndex, id, path, commitRemovePath }
    ) {
      const response = await vm.$api.delete(`${path}/${id}`);
      if (response.message === "OK" && response.result === "OK") {
        commit("SET_REMOVE_PRODUCT", {
          productIndex,
        });
        commit(commitRemovePath, { id });
        commit("SET_LOADING_MODAL", {
          loading: true,
          alertBox: {
            status: true,
            variant: "success",
            message: i18n.t("removeActionIsSucceed"),
          },
        });
        return {
          variant: "success",
          message: i18n.t("updatedIsSucceed"),
          status: true,
        };
      } else {
        commit("SET_LOADING_MODAL", {
          loading: true,
          alertBox: {
            status: true,
            variant: "success",
            message: i18n.t("removeActionIsFailed"),
          },
        });
        return {
          variant: "danger",
          message: i18n.t("updatedIsNotSucceed"),
          status: true,
        };
      }
    },
    //? TODO video gönder => socket kullanılacak.
    async SEND_TO_VIDEO_TO_SECTION(
      { commit, dispatch },
      {
        file,
        form,
        productIndex,
        sectionIndex,
        index = 0,
        nameOfFile,
        videoId = -1,
        item,
        showInterval,
      }
    ) {
      const fd = new FormData();
      fd.append("Description", form.description);
      fd.append("Overview", form.overview);
      fd.append("IsPreview", form.isPreview ? 1 : 0);
      //       fd.append("Video", item.video.file);
      fd.append("Interval", showInterval ? form.interval : 0);
      fd.append("SectionId", item.section.id);
      fd.append("Duration", item.video.duration);
      commit("SET_VIDEO_IS_UPLOADING", true);
      commit("SET_VIDEO_THAT_IS_UPLOADING", {
        form,
        productIndex,
        sectionIndex,
        fd,
        upload: {
          order: 0,
          byte: 0,
          totalByte: 0,
          percent: 0,
        },
      });
      const fileSpliced = spliceFile(file);
      const fileName = nameOfFile;
      let i = index;
      const chunk = fileSpliced[i];
      const response = await RECURSIVE_VIDEO_UPLOAD(
        fd,
        chunk.blob,
        i,
        fileName,
        fileSpliced,
        videoId
      );
      if (response.message === "OK" && i + 1 < fileSpliced.length) {
        const nextIndex = i + 1;
        dispatch("SEND_TO_VIDEO_TO_SECTION", {
          file,
          form,
          productIndex,
          sectionIndex,
          fd,
          index: nextIndex,
          nameOfFile,
          videoId: response.data,
          item,
          showInterval,
        });
        commit("SET_VIDEO_THAT_IS_UPLOADING", {
          form,
          productIndex,
          sectionIndex,
          fd,
          upload: chunk,
        });
      } else if (response.message === "OK") {
        commit("SET_VIDEO_TO_SECTION", {
          form: { ...form, id: response.data, videoPath: "" },
          productIndex,
          sectionIndex,
          upload: chunk,
        });
        commit("SET_LOADING_MODAL", {
          loading: true,
          alertBox: {
            status: true,
            variant: "success",
            message: i18n.t("insertIsSucceed"),
          },
        });
        commit("SET_VIDEO_IS_UPLOADING", false);
      } else {
        commit("SET_VIDEO_IS_UPLOADING", false);
        commit("SET_LOADING_MODAL", {
          loading: true,
          alertBox: {
            status: true,
            variant: "danger",
            message: i18n.t("somethingWentWrong"),
          },
        });
      }
      // vm.$api.post("Videos", fd).then((response) => {
      //   if (response.message === "OK" && response.result === "OK") {
      //     commit("SET_VIDEO_IS_UPLOADING", false);
      //     commit("SET_VIDEO_TO_SECTION", {
      //       form: { ...form, id: response.data, videoPath: "" },
      //       productIndex,
      //       sectionIndex,
      //     });
      //     commit("SET_LOADING_MODAL", {
      //       loading: true,
      //       alertBox: {
      //         status: true,
      //         variant: "success",
      //         message: i18n.t("insertIsSucceed"),
      //       },
      //     });
      //     setTimeout(() => {
      //       location.reload();
      //     }, 1000);
      //   } else {
      //     commit("SET_VIDEO_IS_UPLOADING", false);
      //     commit("SET_LOADING_MODAL", {
      //       loading: true,
      //       alertBox: {
      //         status: true,
      //         variant: "danger",
      //         message: i18n.t("somethingWentWrong"),
      //       },
      //     });
      //   }
      // });
      // vm.$api.post("Videos", fd).then((response) => {
      //   if (response.message === "OK" && response.result === "OK") {
      //     commit("SET_VIDEO_IS_UPLOADING", false);
      //     commit("SET_VIDEO_TO_SECTION", {
      //       form: { ...form, id: response.data, videoPath: "" },
      //       productIndex,
      //       sectionIndex,
      //     });
      //     commit("SET_LOADING_MODAL", {
      //       loading: true,
      //       alertBox: {
      //         status: true,
      //         variant: "success",
      //         message: i18n.t("insertIsSucceed"),
      //       },
      //     });
      //     setTimeout(() => {
      //       location.reload();
      //     }, 1000);
      //   } else {
      //     commit("SET_VIDEO_IS_UPLOADING", false);
      //     commit("SET_LOADING_MODAL", {
      //       loading: true,
      //       alertBox: {
      //         status: true,
      //         variant: "danger",
      //         message: i18n.t("somethingWentWrong"),
      //       },
      //     });
      //   }
      // });
    },
  },
};
async function RECURSIVE_VIDEO_UPLOAD(
  fd,
  chunk,
  i,
  fileName,
  fileSpliced,
  videoId
) {
  fd.append("Video", chunk);
  fd.append("Order", i + 1);
  fd.append("IsLastPart", i + 1 === fileSpliced.length);
  fd.append("FileName", fileName);
  if (videoId > -1) fd.append("VideoId", videoId);

  return await SEND_VIDEO(fd);
}

async function SEND_VIDEO(fd) {
  return await vm.$api.post("Videos", fd);
}

function spliceFile(file) {
  const slicedFileArr = [];
  let slicedBytes = 0;
  let byteRange = 5 * 1024 * 1024; // 5mb => 5 000 000b minimum kabul edilen
  const fileBytes = file.size;
  while (fileBytes > slicedBytes) {
    let lastByte = byteRange + slicedBytes;
    if (lastByte > fileBytes) lastByte = fileBytes;
    if (fileBytes - slicedBytes < byteRange * 2) lastByte = fileBytes;
    const byte = file.slice(slicedBytes, lastByte);
    slicedFileArr.push({
      blob: byte,
      totalByte: fileBytes,
      startByte: slicedBytes,
      lastByte,
    });
    slicedBytes = lastByte;
  }
  return slicedFileArr;
}

function sortArray(arr) {
  if (!arr) return arr;
  return arr.sort((a, b) => {
    if (a.order > b.order) return 1;
    if (a.order < b.order) return -1;
    return 0;
  });
}
