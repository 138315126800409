<template>
  <div>
    <my-academy-title class="mb-4" title="contentTitle"></my-academy-title>
    <div
      v-for="(field, i) in settingsFields"
      :key="i"
      class="bg-light p-3 mb-4 row justify-content-between align-items-center"
    >
      <div class="col-12 col-lg-9">
        <h5>{{ $t(`contents.${field.name}`) }}</h5>
        <p class="text-muted">{{ $t(`contents.${field.name}Description`) }}</p>
      </div>
      <div class="col-12 col-lg-3 text-right">
        <router-link
          :to="`/my-academy/content/${field.name}?type=${
            field.type
          }&fields=${field.fields.join('-|-')}&component=${field.component}`"
        >
          <button class="btn btn-pill subscribe">{{ $t("manage") }}</button>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import MyAcademyTitle from "../MyAcademyTitle.vue";
export default {
  components: { MyAcademyTitle },
  data() {
    return {
      settingsFields: [
        {
          name: "banner",
          type: 1,
          component: "FormView",
          fields: ["title", "description2", "overview", ""],
        },
        {
          name: "about",
          type: 2,
          component: "FormView",
          fields: ["title", "description2", "overview", "certifacates"],
        },
        {
          name: "sponsorList",
          type: 3,
          component: "ListView",
          fields: ["sponsorTitle", "link", "alt", ""],
        },
        {
          name: "moreAboutMe",
          type: 4,
          component: "ListView",
          fields: ["moreAboutMeTitle", "link", "description", ""],
        },
        {
          name: "faq",
          type: 5,
          component: "ListView",
          fields: ["faqTitle", "", "faqDescription", ""],
        },
        {
          name: "popupList",
          type: 6,
          component: "ListView",
          fields: ["popupListTitle", "link", "description", ""],
        },
        {
          name: "testimonials",
          type: 7,
          component: "ListView",
          fields: ["testimonialsTitle", "user", "comment", "point"],
        },
        {
          name: "policies",
          type: 8,
          component: "ListView",
          fields: ["policiesTitle", "title", "policiesHTML", ""],
        },
        // {
        //   name: "componentSettings",
        //   type: 9,
        //   component: "ListView",
        //   fields: ["policiesTitle", "title", "policies", ""],
        // },
      ],
    };
  },
};
</script>

<style></style>
