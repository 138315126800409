<template>
  <div
    class="tab-pane fade show active"
    id="vvideo_tabz"
    role="tabpanel"
    aria-labelledby="videos_taab"
  >
    <div class="bg-light card p-3 my-3 card">
      <VideosInformation />
    </div>
    <div class="badge-light-info p-3 my-3">
      <h4>{{ $t("videoUpdates.title") }}</h4>
      <p>{{ $t("videoUpdates.note1") }}</p>
      <p>{{ $t("videoUpdates.note2") }}</p>
      <p>{{ $t("videoUpdates.note3") }}</p>
      <p v-html="$t('videoUpdates.note4')"></p>
    </div>

    <div class="videso_tb_details mt-4" v-if="product.sections.length > 0">
      <div
        class="container p-3"
        v-for="(section, i) in product.sections"
        @dragstart="DRAGGED_SECTION(section, i)"
        @dragover.prevent
        @drop.prevent="(e) => DROPPED_SECTION(section, i, e)"
        :draggable="!preventDraggableSection"
        :key="i"
      >
        <div class="p-3 bg-light">
          <h5
            class="text-secondary d-md-flex justify-content-between align-items-center"
          >
            <span>
              <i class="icon-playlist"></i> {{ $t("section") }}:
              {{ section.name }} {{ sectionId }}
              <small>
                | {{ section.totalVideoCount }} {{ $t("pcs") }} video |
                {{ convertM2H(section.totalVideoDuration) }}
                {{ $t("learnDuration") }}
              </small>
            </span>
            <div class="mt-4 mt-md-0">
              <router-link
                :to="{
                  query: { ...$route.query, modal: `section-${section.id}` },
                }"
              >
                <button
                  class="btn-xs border-0 badge-light-info bg-secondary text-white mx-2"
                >
                  <img src="/images/edit.svg" class="mr-1" width="20" alt="" />
                  {{ $t("updateSection") }}
                </button>
              </router-link>
              <button
                :disabled="uploadIsStarted"
                @click="SET_CLICK_FILE(section, i)"
                class="btn-xs border-0 badge-light-danger"
              >
                + {{ $t("addNewVideo") }}
              </button>
              <i
                @click="
                  $store.commit('SET_SECTION_IS_EXPANDED', {
                    productId: product.id,
                    sectionIndex: i,
                    status: !section.isExpanded,
                  })
                "
                style="font-size: 12px"
                :class="{ 'rotate-180': section.isExpanded }"
                class="cursor-pointer ml-4 mr-2 icon-arrow_below"
              ></i>
            </div>
          </h5>
        </div>
        <div v-if="section.isExpanded"></div>
        <div v-else-if="section.isLoading" class="text-center my-4">
          <loading />
        </div>
        <div v-else-if="section.videos.length === 0">
          <no-found no-button :buttonText="$t('addNewVideo')" />
        </div>
        <div v-else class="mt-2">
          <router-link
            style="font-size: 16px"
            v-for="(video, j) in section.videos"
            :key="i + '-' + j"
            :to="`/video-detail/${section.id}/video-${video.id}`"
          >
            <div
              @dragstart="DRAGGED_VIDEO(video, j, i)"
              @dragover.prevent
              @drop.prevent="DROPPED_VIDEO(video, j, i)"
              draggable="true"
              @mousemove="preventDraggableSection = true"
              @mouseleave="preventDraggableSection = false"
              class="my-2 p-3 row align-items-center col-hover"
              :class="{ 'badge-light-info': video.duration === 0 }"
            >
              <div class="col-12 col-md-6 col-lg-4">
                <Loading v-if="video.duration === 0" />
                <i v-else class="text-primary icon-play"></i>
                {{ video.description }}
                <div>
                  <small class="text-muted">
                    Son güncelleme:
                    {{
                      formatter(new Date(video.uploadUpdateAt), {
                        year: "numeric",
                        month: "long",
                        day: "numeric",
                        hour: "numeric",
                        minute: "numeric",
                        timeZone: "Europe/Istanbul",
                      })
                    }}
                  </small>
                </div>
              </div>
              <div class="col-12 col-md-6 col-lg-2">
                <span
                  v-if="video.isPreview === 1"
                  class="badge badge-light-success"
                >
                  <i class="icon-play"></i> {{ $t("previewVideo") }}
                </span>
              </div>
              <div class="col-12 col-md-6 col-lg-2">
                <span v-if="video.duration > 0">
                  {{ convertM2H(video.duration) }}</span
                >
                <span v-else class="badge badge-light-danger">
                  {{ $t("preparing") }} {{ video.uploadPercent }}%
                </span>
              </div>
              <div class="col-12 col-md-6 col-lg-2">
                <span
                  v-if="video.isActive === 1"
                  class="badge badge-light-success"
                >
                  {{ $t("active") }}
                </span>
                <span v-else class="badge badge-light-danger">
                  {{ $t("closed") }}
                </span>
              </div>
            </div>
          </router-link>
          <div class="my-2 p-3">
            <button
              :disabled="uploadIsStarted"
              @click="SET_CLICK_FILE(section, i)"
              class="btn-xs border-0 badge-light-danger"
            >
              + {{ $t("addNewVideo") }}
            </button>
          </div>
        </div>
      </div>
      <!-- <div class="container" v-for="(section, i) in product.sections" :key="i">
        <ProductWithTitle
          isVideo
          editable
          :limit="12"
          :loading="section.isLoading"
          :title="section.name"
          :sectionUpdatePath="{ modal: `section-${section.id}` }"
          :productList="section.videos"
        >
          <template v-slot:editable:product>
            <div
              v-if="!getVideoIsUploading"
              @click="SET_CLICK_FILE(section, i)"
              class="col-xl-3 col-lg-4 col-md-4 col-sm-6 col-6 full_wdth add-video"
            >
              <div class="text-center" v-if="!uploadIsStarted">
                <h1>+</h1>
                <p>Video Ekle</p>
              </div>
              <div class="text-center" v-else>
                <loading></loading>
                <p>Video ayarlanıyor...</p>
              </div>
            </div>
            <div
              v-else
              class="col-xl-3 col-lg-4 col-md-4 col-sm-6 col-6 full_wdth add-video"
            >
              <p>Video Yükleniyor..</p>
              <p>Lütfen bekleyin...</p>
            </div>
          </template>
        </ProductWithTitle>
        
      </div> -->
      <div class="container my-4">
        <div
          @click="
            $router.replace({
              query: { ...$route.query, modal: 'add-section' },
            })
          "
          class="add-section"
        >
          + {{ $t("addNewSection") }}
        </div>
      </div>
    </div>
    <div v-else class="text-center my-4">
      <img src="/images/no-found-category.svg" width="240" alt="" />
      <div class="mt-2 mb-4">{{ $t("notFoundSection") }}</div>
      <div class="my-2">
        {{ $t("notFoundSectionDescription") }}
      </div>
      <div class="container my-4">
        <div
          @click="
            $router.replace({
              query: { ...$route.query, modal: 'add-section' },
            })
          "
          class="add-section"
        >
          + {{ $t("addNewSection") }}
        </div>
      </div>
    </div>
    <div
      class="save-box badge-light-info"
      v-if="
        videoMoveTransactions.length > 0 || sectionMoveTransactions.length > 0
      "
    >
      <h5>{{ $t("saveChanges") }}</h5>
      <p class="mt-2">
        {{ $t("saveChangesDescription") }}
      </p>
      <div class="mt-2 text-right">
        <button
          @click="UPDATE_ALL_ORDER()"
          :disabled="isLoadingOrder"
          class="btn btn-pill btn-info"
        >
          <span v-if="!isLoadingOrder">
            {{ $t("save") }}
          </span>
          <loading v-else />
        </button>
      </div>
    </div>
    <EditSectionOfVideo
      :productIndex="productIndex"
      :sections="product.sections"
    ></EditSectionOfVideo>
    <AddVideoToSection
      :item="addVideoToSectionData"
      :productIndex="productIndex"
      :isLocked="product.isLocked"
    >
    </AddVideoToSection>
    <AddSectionOfProduct
      :product="product"
      :productIndex="productIndex"
    ></AddSectionOfProduct>
    <input
      @change="SET_VIDEO"
      type="file"
      accept="video/*"
      ref="addVideo"
      class="d-none"
    />
    <!--videso_tb_details end-->
  </div>
</template>

<script>
// import ProductWithTitle from "../List/ProductWithTitle.vue";
import EditSectionOfVideo from "./EditSectionOfProduct.vue";
import VideosInformation from "./VideosInformation";
import AddVideoToSection from "./AddVideoToSection.vue";
import AddSectionOfProduct from "./AddSectionOfProduct.vue";
import AlertBox from "../../mixins/AlertBox";
import NoFound from "../Shared/NoFound.vue";
import Loading from "../Shared/Loading.vue";

import { formatter } from "gurhan/helpers/Date";
export default {
  mixins: [AlertBox],
  components: {
    // ProductWithTitle,
    EditSectionOfVideo,
    NoFound,
    Loading,
    AddVideoToSection,
    AddSectionOfProduct,
    VideosInformation,
  },
  props: {
    product: {
      default: () => {},
    },
    productIndex: {
      default: -1,
    },
  },
  data() {
    return {
      preventDraggableSection: false,
      draggedSection: null,
      sectionMoveTransactions: [],
      draggedVideo: null,
      videoMoveTransactions: [],
      uploadIsStarted: false,
      isLoadingOrder: false,
      addVideoToSectionData: {
        section: {},
        video: {},
      },
    };
  },
  computed: {
    getVideoIsUploading() {
      return this.$store.state.Products.videoIsUploading;
    },
  },
  methods: {
    formatter,
    convertM2H(secs) {
      var sec_num = parseInt(secs, 10);
      var hours = Math.floor(sec_num / 3600);
      var minutes = Math.floor(sec_num / 60) % 60;
      var seconds = sec_num % 60;

      return [hours, minutes, seconds]
        .map((v) => (v < 10 ? "0" + v : v))
        .filter((v, i) => v !== "00" || i > 0)
        .join(":");
    },
    DRAGGED_SECTION(section, i) {
      this.draggedSection = { section, i };
    },
    DROPPED_SECTION(section, i, e) {
      if (this.preventDraggableSection)
        if (this.product.sections[i].videos.length == 0)
          return this.DROPPED_VIDEO(this.draggedVideo.video, 0, i);
        else return e.preventDefault();
      this.sectionMoveTransactions.push({
        from: this.draggedSection,
        to: { section, i },
      });
      this.array_move(this.product.sections, this.draggedSection.i, i);
    },
    DRAGGED_VIDEO(video, videoIndex, sectionIndex) {
      this.draggedVideo = { video, videoIndex, sectionIndex };
    },
    DROPPED_VIDEO(video, videoIndex, sectionIndex) {
      this.videoMoveTransactions.push({
        from: this.draggedVideo,
        to: { video, videoIndex, sectionIndex },
      });
      this.product.sections[this.draggedVideo.sectionIndex].videos.splice(
        this.draggedVideo.videoIndex,
        1
      );
      this.product.sections[sectionIndex].videos.splice(videoIndex, 0, {
        ...this.draggedVideo.video,
        sectionId: this.product.sections[sectionIndex].id,
      });
    },
    async UPDATE_ALL_ORDER() {
      this.SET_LOADING_MODAL(true);
      const videos = this.product.sections
        .reduce((arr, obj) => {
          arr.push([...obj.videos]);
          return arr;
        }, [])
        .flat(1)
        .map((video, i) => ({
          videoId: video.id,
          sectionId: video.sectionId,
          order: i + 1,
        }));
      const response = await this.$api.post("VideoOrder", videos);
      if (response.message === "OK" || response.result === "OK") {
        this.SET_ALERT_BOX("success", this.$t("updatedIsSucceed"));
        this.sectionMoveTransactions = [];
        this.videoMoveTransactions = [];
      }
    },
    UPDATE_ORDER(arr, old_index, new_index, item1, item2, type, idName) {
      if (type === "increase") {
        const data = {
          firstVideo: { id: item1.id, order: item1.order - 1 },
          secondVideo: { id: item2.id, order: item2.order + 1 },
        };
        this.PUT_ORDER(
          data,
          arr,
          old_index,
          new_index,
          idName,
          type,
          item1,
          item2
        );
      } else if (type === "decrease") {
        const data = {
          firstVideo: { id: item1.id, order: item1.order + 1 },
          secondVideo: { id: item2.id, order: item2.order - 1 },
        };
        this.PUT_ORDER(data, arr, old_index, new_index, idName, type);
      }
    },
    async PUT_ORDER(data, arr, old_index, new_index, idName, type) {
      this.SET_LOADING_MODAL(true);
      const response = await this.$api.put("Videos/UpdateOrder", data);
      if (response.message === "OK") {
        this.SET_ALERT_BOX("success", this.$t("validMessage.updated"));
        this.ADD_CHANGE_IS_CLASS(
          idName,
          old_index,
          new_index,
          "video-move-success-" + type
        );
        this.array_move(arr, old_index, new_index, data);
      } else {
        this.SET_ALERT_BOX(
          "success",
          this.$t("validMessage.updatedIsNotSucceed")
        );
        this.ADD_CHANGE_IS_CLASS(
          idName,
          old_index,
          new_index,
          "video-move-error"
        );
      }
      this.SET_LOADING_MODAL(false);
      return;
    },
    array_move(arr, old_index, new_index) {
      if (new_index >= arr.length) {
        var k = new_index - arr.length + 1;
        while (k--) {
          arr.push(undefined);
        }
      }
      arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
      return arr; // for testing
    },
    // order değişimi sağlandığında id'e eklenen class.
    ADD_CHANGE_IS_CLASS(idName, old_index, new_index, className) {
      document.getElementById(idName + old_index).classList.add(className);
      document
        .getElementById(idName + new_index)
        .classList.add(className + "-reverse");
      setTimeout(() => {
        document.getElementById(idName + old_index).classList.remove(className);
        document
          .getElementById(idName + new_index)
          .classList.remove(className + "-reverse");
      }, 2000);
    },
    SET_CLICK_FILE(section, i) {
      this.addVideoToSectionData.section = {
        ...section,
        index: i,
        prevVideoOrder:
          section.videos.length > 0
            ? section.videos[section.videos.length - 1].order
            : 0,
      };
      this.$refs.addVideo.click();
    },
    GET_VIDEOS_OF_SECTIONS() {
      this.$store.dispatch("GET_VIDEOS_OF_SECTIONS", {
        productIndex: this.productIndex,
        sections: this.product.sections,
      });
    },
    SET_VIDEO(e) {
      this.uploadIsStarted = true;
      const vm = this;
      const file = e.target.files[0];
      // if(file.size > 100000) return this.SET_ALERT_BOX('danger', this.$t('more1gb'))
      if (file?.type.indexOf("video/") === -1) {
        this.uploadIsStarted = false;
        vm.SET_ALERT_BOX(
          "danger",
          "Sadece video formatında dosya yükleyebilirsiniz."
        );
        return;
      }
      vm.addVideoToSectionData.video = {
        uri: "",
        file,
        size: file.size,
        duration: 0,
      };
      vm.$router.replace({
        query: { ...vm.$route.query, modal: "add-video-to-section" },
      });
      vm.uploadIsStarted = false;
    },
  },
  mounted() {
    this.GET_VIDEOS_OF_SECTIONS();
  },
};
</script>

<style>
.table-hover:hover {
  background: #dedede;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  transition: 0.3s linear all;
}
.badge-preparing {
  animation: changeColor 1s ease-in infinite;
  color: #fff;
  background: #ff5740;
}
@keyframes changeColor {
  from {
    background: #2d3958;
  }
  to {
    background: #ff5740;
  }
}
.rotate-duration::before {
  -webkit-animation-name: rotateIt;
  -webkit-animation-duration: 10s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  -webkit-animation-delay: 0s;
}
@keyframes rotateIt {
  0% {
  }
  5%,
  25% {
    transform: rotate(360deg);
  }
  15%,
  35% {
    transform: rotate(360deg);
  }
  40% {
    transform: rotate(0deg);
  }
}
.reverse::before {
  transform: rotate(180deg);
}
.mini-icon {
  font-size: 10px;
}
.mini-icon::before {
  width: auto !important;
}
.video-move-success-decrease {
  position: relative;
  background: #a7c8ca31;
  transition: 0.3s ease-in;
  animation: slideDown reverse 0.5s ease-in;
}
.video-move-success-decrease-reverse {
  position: relative;
  background: #a7c8ca31;
  transition: 0.3s ease-in;
  animation: slideUp reverse 0.5s ease-in;
}
.video-move-success-increase {
  position: relative;
  background: #a7c8ca31;
  transition: 0.3s ease-in;
  animation: slideUp reverse 0.5s ease-in;
}
.video-move-success-increase-reverse {
  position: relative;
  background: #a7c8ca31;
  transition: 0.3s ease-in;
  animation: slideDown reverse 0.5s ease-in;
}
.video-move-success-enter {
  position: relative;
  background: #a7c8ca31;
  transition: 0.3s ease-in;
  animation: slideLeft 0.5s ease-in;
}
@keyframes slideDown {
  from {
    bottom: 0;
    opacity: 1;
  }
  to {
    bottom: -70px;
    opacity: 1;
  }
}
@keyframes slideUp {
  from {
    top: 0px;
    opacity: 1;
  }
  to {
    top: -70px;
    opacity: 1;
  }
}
.video-move-error {
  transition: 0.3s ease-in;
  background: #bd99a8;
}
</style>
