<template>
  <ModuleLocked v-if="!blogModule" />
  <section v-else class="vid-title-sec">
    <delete-product
      :product="form"
      v-if="form.buyerCount === 0"
    ></delete-product>
    <div class="container">
      <form @submit.prevent="SET_MIDDLEWARE">
        <Cropper :image="form.imagePath" @change:image="SET_IMAGE"></Cropper>
        <div class="vid-title">
          <h2 class="title-hd">{{ $t("enterBlogTitle") }}</h2>
          <div class="form_field">
            <input
              type="text"
              maxlength="100"
              required
              v-model="form.title"
              :placeholder="$t('addHereMax100Char')"
            />
          </div>
        </div>
        <!--abt-vidz-->
        <div class="abt-vidz-pr">
          <h2 class="title-hd">{{ $t("blogOverview") }} *</h2>
          <div class="form_field">
            <vue-editor
              style="height: 1000px"
              id="editor"
              :placeholder="$t('explainBlogDetail')"
              useCustomImageHandler
              v-model="form.overview"
              @image-added="HANDLE_IMAGE_ADDED"
            >
            </vue-editor>
          </div>
        </div>
        <!--vid-title-->
        <div class="abt-vidz-pr mt-5">
          <h2 class="title-hd mt-4 pt-4">{{ $t("shortDesc") }}</h2>
          <div class="form_field">
            <textarea
              required
              v-model="form.description"
              maxlength="250"
            ></textarea>
          </div>
        </div>
        <!--abt-vidz-->
        <div class="cls-vidz">
          <div class="row">
            <div class="col-sm-6 col-12">
              <div class="option">
                <h2 class="title-hd">{{ $t("category") }} *</h2>
                <div class="form_field">
                  <select
                    :disabled="getCategoriesAreLoading"
                    required
                    v-model="form.categoryId"
                  >
                    <option
                      :value="item.id"
                      v-for="(item, i) in getCategories"
                      :key="i"
                    >
                      {{ item.name }}
                    </option>
                  </select>
                </div>
              </div>
              <!--option end-->
            </div>
          </div>
        </div>
        <div class="vid-title mt-4">
          <h2 class="title-hd">{{ $t("enterTags") }}</h2>
          <div class="form_field">
            <input
              type="text"
              required
              v-model="form.hiddenInfo"
              :placeholder="$t('enterTagsPlaceholder')"
            />
          </div>
          <div>
            <small class="text-muted">{{ $t("enterTagsExample") }}</small>
          </div>
        </div>
        <div>
          <SelectProductOwner
            :edit="edit"
            v-model="form.userInfo"
            ref="userList"
          ></SelectProductOwner>
        </div>
        <!--cls-vidz end-->
        <div class="clearfix"></div>
        <div class="btn-sbmit">
          <button type="submit" :disabled="getNewBlogIsLoading">
            <loading v-if="getNewBlogIsLoading"></loading>
            <span v-else> {{ $t("submit") }}</span>
          </button>
        </div>
      </form>
      <IsActiveProduct
        :isActive="1"
        :form="product"
        v-if="edit"
      ></IsActiveProduct>
    </div>
  </section>
  <!--vid-title-sec-->
</template>

<script>
import axios from "axios";
import Cropper from "./ImageCropper.vue";
import { VueEditor } from "vue2-editor";
import SelectProductOwner from "./SelectProductOwner.vue";
import IsActiveProduct from "./IsActiveProduct.vue";
import DeleteProduct from "./DeleteProduct.vue";
import ModuleLocked from "../Packages/ModuleLocked.vue";
import Modules from "../../mixins/Modules.js";
import AlertBox from "../../mixins/AlertBox.js";

export default {
  mixins: [Modules, AlertBox],
  components: {
    Cropper,
    SelectProductOwner,
    VueEditor,
    IsActiveProduct,
    DeleteProduct,
    ModuleLocked,
  },
  props: {
    edit: {
      default: false,
      type: Boolean,
    },
    product: {
      default: () => {},
    },
  },
  data() {
    return {
      form: {
        user: null,
        title: "",
        description: "",
        overview: "",
        imagePath: "",
        videoPath: "",
        filePath: "",
        image: null,
        priceTRY: 0,
        priceUSD: 0,
        priceEUR: 0,
        discountTRY: 0,
        discountUSD: 0,
        discountEUR: 0,
        langCode: "tr",
        level: 1,
        isShowcase: 0,
        isOffer: 0,
        isSharable: 1,
        hiddenInfo: "",
        categoryId: null,
      },
      files: {
        image: "",
        pdf: "",
      },
    };
  },
  computed: {
    // kategorilerin listesi alınır.
    getCategories() {
      return this.$store.state.Category.categories;
    },
    getActiveUser() {
      return this.$store.state.User.activeUser;
    },
    // kateori listesi loading
    getCategoriesAreLoading() {
      return this.$store.state.Category.categoriesAreLoading;
    },
    // ürün ekleme loading
    getNewBlogIsLoading() {
      return this.$store.state.Blogs.newBlogsIsLoading;
    },
  },
  methods: {
    // eğer yeni kayıt query'de category var ise.
    SET_CATEGORY() {
      const categoryId = Number(this.$route.query.category);
      if (categoryId) this.form.categoryId = categoryId;
    },
    async UPLOAD_IMAGE(image) {
      const data = new FormData();
      data.append("file", image);
      data.append("upload_preset", "ml_default");
      data.append("cloud_name", "defgcz4oj");
      let response;
      response = await axios.post(
        "https://api.cloudinary.com/v1_1/defgcz4oj/image/upload",
        data
      );
      const imageUrl = response?.data?.url || false;

      return imageUrl;
    },
    async HANDLE_IMAGE_ADDED(file, Editor, cursorLocation, resetUploader) {
      const url = await this.UPLOAD_IMAGE(file);
      if (url) {
        Editor.insertEmbed(cursorLocation, "image", url);
        resetUploader();
      } else {
        this.SET_ALERT_BOX("danger", this.$t("imageCouldntUpload"));
      }
    },
    SET_IF_EDIT() {
      this.form = {
        ...this.product,
        categoryId: this.product.category.id,
        imagePath: this.$api.image(this.product.imagePath),
        image: null,
      };
    },
    SET_IMAGE(e) {
      this.form.image = e.file; // image file'ı
      this.files.image = e.uri; // image linki
    },
    // middleware
    SET_MIDDLEWARE(e) {
      e.preventDefault();
      if (this.edit) this.SEND_UPDATE_BLOG();
      else this.SEND_BLOG();
    },
    // ürünü güncelle
    async SEND_UPDATE_BLOG() {
      const response = await this.$store.dispatch("SEND_UPDATE_BLOG", {
        fd: this.SET_BLOG_BY_FORMDATA(this.form),
        form: {
          ...this.form,
          imagePath:
            this.files.image === null
              ? this.product.imagePath
              : this.files.image,
          filePath:
            this.files.pdf === null ? this.product.filePath : this.files.pdf,
        },
      });
      if (response)
        setTimeout(() => {
          this.$router.go(0);
        }, 1000);
    },
    // ürünü gönder
    async SEND_BLOG() {
      if (this.form.image === null)
        return this.SET_ALERT_BOX("danger", this.$t("imageIsRequired"));
      const response = await this.$store.dispatch("SEND_NEW_BLOG", {
        fd: this.SET_BLOG_BY_FORMDATA(this.form),
        form: {
          ...this.form,
          imagePath: this.files.image,
        },
      });
      if (response)
        setTimeout(() => {
          this.$router.push(`/product/blogs-${response}`);
        }, 2500);
    },
    // ürünü formdataya taşı.
    SET_BLOG_BY_FORMDATA(form) {
      const fd = new FormData();

      fd.append("UserId", form.userInfo.id);
      fd.append("Title", form.title);
      fd.append("CategoryId", form.categoryId);
      fd.append("Description", form.description);
      fd.append("DiscountEUR", form.discountEUR);
      fd.append("DiscountTRY", form.discountTRY);
      fd.append("DiscountUSD", form.discountUSD);
      fd.append("HiddenInfo", form.hiddenInfo);
      fd.append("LangCode", form.langCode);
      fd.append("Level", form.level);
      fd.append("IsShowcase", form.isShowcase);
      fd.append("IsSharable", form.isSharable);
      fd.append("IsOffer", form.isOffer);
      fd.append("Overview", form.overview);
      fd.append("PriceEUR", form.priceEUR);
      fd.append("PriceTRY", form.priceTRY);
      fd.append("PriceUSD", form.priceUSD);
      fd.append("ImagePath", form.imagePath);
      fd.append("FilePath", form.filePath);
      if (this.form.image !== null) fd.append("Image", form.image);
      return fd;
    },
  },
  created() {
    if (this.edit) this.SET_IF_EDIT();
    this.SET_CATEGORY();
  },
};
</script>
