export default {
  methods: {
    SET_LOADING_MODAL(loading) {
      this.$store.commit("SET_LOADING_MODAL", {
        loading,
        alertBox: {
          status: false,
          variant: "",
          message: "",
        },
      });
    },
    SET_ALERT_BOX(variant, message, duration = 0) {
      this.$store.commit("SET_LOADING_MODAL", {
        loading: true,
        alertBox: {
          status: true,
          variant,
          message,
        },
      });

      if (duration > 0)
        setTimeout(() => {
          this.SET_LOADING_MODAL(false);
        }, duration);
    },
  },
};
