<template>
  <div>
    <MyAcademyTitle title="myLogo"></MyAcademyTitle>
    <div class="bg-light card p-3 my-3 card">
      <p>{{ $t("myLogoDescription2") }}</p>
    </div>
    <div class="mt-4 d-flex align-items-center" v-if="getSettings">
      <img :src="getSettings.logo" width="120" alt="" />
      <input
        class="d-none"
        type="file"
        ref="file"
        accept="image/*"
        @change="(e) => uploadImage(e, 'Logo')"
        id=""
      />
      <button
        @click="$refs.file.click()"
        :disabled="actionIsLoading"
        class="ml-4 btn btn-pill subscribe"
      >
        <Loading v-if="actionIsLoading"></Loading>
        <span v-else>{{ $t("upload") }}</span>
      </button>
    </div>
  </div>
</template>

<script>
import MyAcademyTitle from "./MyAcademyTitle.vue";
import Loading from "../Shared/Loading.vue";
import actionMixin from "./action.mixin";
export default {
  components: { MyAcademyTitle, Loading },
  mixins: [actionMixin],
};
</script>

<style></style>
