<template>
  <div>
    <router-view v-if="getLoadedSettings" />
    <PageIsClosed v-else-if="getPageIsClosed" />
  </div>
</template>

<script>
import PageIsClosed from "./components/PageIsClosed/index.vue";
export default {
  components: {
    PageIsClosed,
  },
  computed: {
    getLoadedSettings() {
      return this.$store.state.User.settingsLoaded;
    },
    getPageIsClosed() {
      return this.$store.state.User.pageIsClosed;
    },
  },
  methods: {
    GET_LANG() {
      const navLang = navigator.language.split("-")[0] || "en";

      const lang = localStorage.getItem("lang") || navLang || "en";
      if (this.$i18n.availableLocales.includes(lang)) this.$i18n.locale = lang;
    },
  },
  async created() {
    await this.$store.dispatch("GET_COMPANY_SETTINGS");
    this.$store.dispatch("GET_CURRENCY_PRICES");
    // this.$store.commit("SET_PROGRESS_BAR");
  },
  mounted() {
    this.GET_LANG();
    setTimeout(() => {
      const query = this.$route.query;
      if (query.packageId)
        this.$store.commit("SET_PACKAGE_ID_FROM_WEBSITE", query.packageId);
    }, 150);
  },
};
</script>
