<template>
  <ModuleLocked v-if="!createCouponModule" />
  <div v-else class="container">
    <div class="text-right my-4">
      <button @click="showInsertModal = true" class="btn btn-pill donate">
        {{ $t("newPromotionCode") }}
      </button>
    </div>
    <Tables
      :loading="getPromotionLoading"
      @change:search="SET_SEARCH"
      :headers="headers"
      :items="getPromotionCodes"
    >
      <template #createdAt="{ item }">
        {{ formatter(new Date(item.createdAt)) }}
      </template>
      <template #expiredAt="{ item }">
        {{ formatter(new Date(item.expiredAt)) }}
      </template>
      <template #code="{ item }">
        <div class="d-flex justify-content-start align-items-center">
          <span v-if="item.currency === 1">
            <span title="Lira" class="badge badge-success">₺</span></span
          >
          <span v-else-if="item.currency === 2">
            <span title="Euro" class="badge badge-danger">€</span></span
          >
          <span v-else-if="item.currency === 3">
            <span title="USD" class="badge badge-warning">$</span></span
          >
          <span v-else> TRY (₺)</span>
          <img
            style="object-fit: cover"
            src="/images/payments/iyzico.png"
            width="30"
            alt=""
          />
          <img
            style="object-fit: cover"
            src="/images/payments/paypal.png"
            width="30"
            alt=""
          />
          <img
            style="object-fit: cover"
            src="/images/payments/havale-eft.png"
            width="30"
            alt=""
          />
          {{ item.code }}
          <span
            class="badge"
            :class="
              CONTROL_EXPIRED_AT(item.expiredAt)
                ? 'badge-warning'
                : item.isActive === 1
                ? 'badge-success'
                : 'badge-danger'
            "
          >
            {{
              CONTROL_EXPIRED_AT(item.expiredAt)
                ? "Süresi doldu"
                : item.isActive === 1
                ? $t("valid")
                : $t("invalid")
            }}</span
          >
        </div>
      </template>
      <template #discount="{ item }"> %{{ item.discount }} </template>
      <template #options="{ item }">
        <button
          @click="
            updatePromotionCode = item;
            showUpdateModal = true;
          "
          class="btn btn-pill donate"
        >
          {{ $t("update") }}
        </button>
      </template>
    </Tables>
    <div class="d-flex justify-content-center">
      <BoostrapPagination
        @change="SET_PAGINATION"
        :page="page"
        :pages="pages"
      ></BoostrapPagination>
    </div>
    <Modal @ok="SET_PROMOTION_CODE_FORM('promotion')" v-model="showUpdateModal">
      <UpdatePromotionCode
        ref="promotion"
        @close="showUpdateModal = false"
        edit
        :item="updatePromotionCode"
      ></UpdatePromotionCode>
    </Modal>
    <Modal
      @ok="SET_PROMOTION_CODE_FORM('promotionInsert')"
      v-model="showInsertModal"
    >
      <UpdatePromotionCode
        ref="promotionInsert"
        @close="showUpdateModal = false"
        :item="updatePromotionCode"
      ></UpdatePromotionCode>
    </Modal>
  </div>
</template>

<script>
import Tables from "../Tables/index.vue";
import { formatter } from "gurhan/helpers/Date";
import Modal from "../Shared/Modal";
import BoostrapPagination from "gurhan/vue/BootstrapPagination.vue";
import UpdatePromotionCode from "./PromotionCodeModal.vue";
import Modules from "../../mixins/Modules.js";
import ModuleLocked from "../Packages/ModuleLocked.vue";
export default {
  mixins: [Modules],
  data() {
    return {
      page: 1,
      limit: 10,
      count: 0,
      headers: [
        {
          title: this.$t("code"),
          value: "code",
          col: 12,
          md: 6,
          lg: 5,
          xl: 5,
        },
        {
          title: this.$t("discount"),
          value: "discount",
          col: 12,
          md: 6,
          lg: 1,
          xl: 1,
        },
        {
          title: this.$t("usedCount"),
          value: "usedCount",
          col: 12,
          md: 6,
          lg: 3,
          xl: 3,
        },
        {
          title: this.$t("createdAt"),
          value: "createdAt",
          col: 12,
          md: 6,
          lg: 3,
          xl: 3,
        },
        {
          title: this.$t("expiredAt"),
          value: "expiredAt",
          col: 12,
          md: 6,
          lg: 3,
          xl: 3,
        },
      ],
      updatePromotionCode: {},
      showUpdateModal: false,
      showInsertModal: false,
    };
  },
  computed: {
    getPromotionCodes() {
      return this.$store.state.Coupons.Coupons;
    },
    getPromotionLoading() {
      return this.$store.state.Coupons.CouponsAreLoading;
    },
    pages() {
      return Math.ceil(this.count / this.limit);
    },
  },
  methods: {
    formatter,
    SET_PROMOTION_CODE_FORM(ref) {
      this.$refs[ref].SEND_PROMOTION_CODE();
    },
    SET_SEARCH(search) {
      this.$store.dispatch("GET_COUPONS", { page: 1, search });
    },
    SET_PAGINATION(page) {
      this.page = page;
      this.$router.replace({ query: { ...this.$route.query, page } });
      this.$store.dispatch("GET_COUPONS", { page, search: "" });
    },
    CONTROL_EXPIRED_AT(date) {
      return new Date(date).getTime() < new Date().getTime();
    },
  },
  mounted() {
    this.$store.dispatch("GET_COUPONS", { page: 1, search: "" });
  },
  components: {
    Tables,
    BoostrapPagination,
    Modal,
    UpdatePromotionCode,
    ModuleLocked,
  },
};
</script>

<style></style>
