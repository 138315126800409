<template>
  <Modal
    :lg="userList.length === 0"
    @ok="SEND_MULTIPLE_PRODUCTS"
    :hide-footer="
      userList.length === 0 ||
      (alertBox.status && alertBox.variant === 'success')
    "
    :value="value"
    @input="(e) => $emit('input', e)"
  >
    <div class="alert" :class="`alert-info`" v-if="alertBox.length > 0">
      <ul>
        <li v-for="(alert, i) in alertBox" :key="i">
          {{
            $t("alertMessageStudent", {
              fullname: alert.fullname,
              message: alert.message,
            })
          }}
          <hr />
        </li>
      </ul>
    </div>
    <p>
      <strong> {{ $t("purchasedCount") }} :</strong>
      {{ product.purchasedCount || product.buyerCount }}
      {{ product.limit ? "/ " + product.limit : "" }}
    </p>
    <p>
      <strong> {{ $t("productName") }} :</strong> {{ product.title }}
    </p>
    <p>
      <strong> {{ $t("productDescription") }} </strong>:
      {{ product.description }}
    </p>
    <hr />
    <GetUserThatOldEvents
      table="event"
      hideAction
      @response="GET_SELECTED_USER_LIST"
      :product="product"
      ref="multipleAddUser"
    />
    <div v-if="userList.length > 0">
      <!-- <div class="mb-4 text-right">
        <button @click="userList = []" class="btn btn-pill btn-danger">
          Yeni Öğrenci Seç
        </button>
      </div> -->
      <div class="my-4">
        <h5 class="mb-2">
          <strong>{{ $t("selectedStudent") }} ({{ userList.length }})</strong>
        </h5>
        <p>
          <small
            class="px-2 text-white m-1 bg-primary d-inline-block rounded"
            v-for="(user, i) in userList"
            :key="i"
            >{{ user.fullname }}
            <i
              @click="userList.splice(i, 1)"
              class="cursor-pointer icon-cancel"
            ></i
          ></small>
        </p>
      </div>
      <div v-if="getSettings.departmentId !== 2">
        <label for="pay"> {{ $t("currency") }} </label>
        <select v-model="currency" name="pay" class="form-control" id="">
          <option selected hidden :value="null">
            {{ $t("chooseCurrency") }}
          </option>
          <option :value="item.type" v-for="(item, i) in prices" :key="i">
            {{ item.label }}
          </option>
        </select>
        <div class="mt-4 bg-light card">
          <h5>{{ $t("infoForCurrency") }} :</h5>
          <div class="mt-3">
            <ul>
              <li>- {{ $t("infoForCurrency2") }}</li>
              <li>- {{ $t("infoForCurrency3") }}.</li>
              <li>- {{ $t("infoForCurrency4") }}</li>
            </ul>
          </div>
        </div>
      </div>
      <div
        v-if="currency !== null && getSettings.departmentId !== 2"
        class="mt-4"
      >
        <Apply v-model="promotion" :currency="currency">
          <h5 class="mb-2">{{ $t("addPromotionCodeToStudent") }}</h5>
          <p class="text-muted">
            {{ $t("addPromotionCodeToStudentDescription") }}
          </p>
          <div class="mt-2 alert alert-warning">
            <h6>{{ $t("warn") }}:</h6>
            <p>* {{ $t("promotionCodeWarnMessage") }}</p>
          </div>
        </Apply>
      </div>
      <div class="mt-4" v-if="getSettings.departmentId !== 2">
        <h5 class="mb-2">{{ $t("currencyItemWhoAddedToStudent") }}</h5>
        <p class="mb-4 text-muted">
          <span> {{ $t("currencyItemWhoAddedToStudentDescription2") }}</span>
        </p>
        <select v-model="status" name="pay" class="form-control" id="">
          <option selected hidden :value="null">
            {{ $t("choosePaymentStatus") }}
          </option>
          <option :value="1">{{ $t("waitPayment") }}</option>
          <option :value="2">{{ $t("completePayment") }}</option>
        </select>
        <div v-if="status === 2" class="alert alert-warning mt-2">
          {{ $t("choosedPaymentCompletedForStudent") }}
        </div>
      </div>
    </div>
    <div class="my-2 col-12" v-else></div>
    <div class="my-4 alert alert-light" v-if="getSettings.departmentId !== 2">
      <p>
        <strong class="text-success"> {{ $t("completePayment") }} :</strong>
        {{ $t("completePaymentDescription") }}
      </p>
      <p>
        <strong class="text-warning"> {{ $t("waitPayment") }} :</strong>
        {{ $t("waitPaymentDescription") }}
      </p>
      <p>
        <strong class="text-danger"> {{ $t("cancelPayment") }} :</strong>
        {{ $t("cancelPaymentDescription") }}
      </p>
    </div>
    <div
      class="alert"
      :class="`alert-${alertBox.variant}`"
      v-if="alertBox.status"
    >
      {{ alertBox.message }}
    </div>
  </Modal>
</template>

<script>
import Modal from "../Shared/Modal";
import Apply from "../PromotionCodes/apply.vue";
import GetUserThatOldEvents from "./GetUserThatOldEvents.vue";
import AlertBox from "../../mixins/AlertBox";
export default {
  components: { Modal, Apply, GetUserThatOldEvents },
  mixins: [AlertBox],
  props: {
    value: {
      default: false,
    },
    product: {
      default: () => {},
    },
  },
  data() {
    return {
      user: {},
      userList: [],
      promotion: { id: -1, status: "" },
      alertBox: [],
      prices: [
        { type: "TRY", label: "TRY (₺)", isPaypal: 0 },
        { type: "USD", label: "USD ($)", isPaypal: 1 },
        { type: "USD", label: "EUR (€)", isPaypal: 1 },
      ],
      currency: "TRY",
      status: 1,
    };
  },
  computed: {
    getSettings() {
      return this.$store.state.User.settings;
    },
  },
  methods: {
    SET_USER_LIST(userList) {
      this.userList = userList;
    },
    async SEND_MULTIPLE_PRODUCTS(loading) {
      loading(true);
      this.alertBox = [];
      const purchasedCount =
        this.product.purchasedCount || this.product.buyerCount;
      if (
        this.product.limit &&
        this.product.limit < purchasedCount + this.userList.length
      ) {
        this.SET_ALERT_BOX("danger", this.$t("eventLimitExceeded"));
        return loading(false);
      }
      const response = await Promise.all(
        this.userList.map((user) => this.SEND_USER_TO_PRODUCT(user))
      );
      const successCount = response.filter(
        (res) => res.variant === "success"
      ).length;
      const userCount = this.userList.length;
      if (successCount === userCount) {
        setTimeout(() => {
          this.$emit("input", false);
          this.$emit("refresh", true);
        }, 2500);
      } else {
        this.$emit("refresh");
      }
      loading(false);
    },
    async SEND_USER_TO_PRODUCT(user) {
      const alertBox = await this.$store.dispatch(
        this.product.type.addUserToProductByManual,
        {
          productId: this.product.id,
          userId: user.id,
          price: this.product[`price${this.currency}`],
          discount: this.product.discount,
          currency: this.currency,
          promotionCodeId: this.promotion.id === -1 ? null : this.promotion.id,
          paymentStatus: this.product[`price${this.currency}`] > 0 ? 1 : 2,
        }
      );
      const response =
        this.status === 1 || alertBox.variant === "danger"
          ? true
          : await this.SEND_UPDATE_USER_PRODUCT(user);
      this.alertBox.push({
        ...alertBox,
        ...user,
        updateResponse: response,
      });
      return { ...alertBox, ...user, updateResponse: response };
      // setTimeout(() => {
      //   if (this.alertBox.variant === "success") {
      //     this.alertBox.status = false;
      //     this.$emit("input", false);
      //     this.$emit("refresh");
      //   }
      // }, 2500);
    },
    async SEND_UPDATE_USER_PRODUCT(user) {
      this.alertBox.status = false;
      const response = await this.$api.put(
        `Products/UpdateStatus?table=${this.product.type.name}`,
        {
          userId: user.id,
          productId: this.product.id,
          status: this.status,
          price: user.price,
          discount: user.discount,
        }
      );
      if (response.message === "OK") {
        return true;
        // this.$emit("input", false);
        // this.$emit("refresh");
      } else {
        return false;
      }
    },
    GET_SELECTED_USER_LIST(list) {
      this.userList = list;
    },
  },
  mounted() {
    if (this.getSettings.departmentId === 2) this.status = 2;
  },
};
</script>

<style></style>
