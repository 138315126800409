<template>
  <div class="history-lst">
    <Banner
      :title="product.title"
      :src="$api.image(product.imagePath)"
    ></Banner>
    <div v-if="loaded" class="container">
      <div class="my-4">
        <img
          @click="SET_BACK()"
          src="/images/back-button.svg"
          width="32"
          alt=""
        />
      </div>
      <EventTitle
        :event="event"
        :badge="CHECK_EXPIRED_EVENT(event)"
      ></EventTitle>
      <div class="text-right mt-4">
        <button
          @click="
            $router.replace({
              query: {
                ...$route.query,
                modal: `eventDates-${event.id}`,
              },
            })
          "
          class="btn btn-pill donate m-1"
        >
          {{ $t("editEvent") }}
        </button>
      </div>
      <ul class="mt-4 px-3 nav nav-tabs" id="myTab" role="tablist">
        <li @click="activeTab = 'list'" class="nav-item">
          <a
            class="nav-link"
            :class="{ active: activeTab === 'list' }"
            id="home_tab"
            data-toggle="tab"
            href="javascript:void(0)"
            role="tab"
            aria-controls="home_tab"
            aria-selected="true"
            >{{ $t("users") }}</a
          >
        </li>
        <li @click="activeTab = 'uploadedItems'" class="nav-item">
          <a
            class="nav-link"
            :class="{ active: activeTab === 'uploadedItems' }"
            id="home_tab"
            data-toggle="tab"
            href="javascript:void(0)"
            role="tab"
            aria-controls="home_tab"
            aria-selected="true"
            >{{ $t("uploadedItems") }}</a
          >
        </li>
        <li @click="activeTab = 'uploadEventVideo'" class="nav-item">
          <a
            class="nav-link"
            :class="{ active: activeTab === 'uploadEventVideo' }"
            id="home_tab"
            data-toggle="tab"
            href="javascript:void(0)"
            role="tab"
            aria-controls="home_tab"
            aria-selected="true"
            >{{ $t("uploadEventVideo") }}</a
          >
        </li>
      </ul>
      <ListOfEventDate
        v-if="activeTab === 'list'"
        :product="product"
        :event="event"
        hide-title
        @refresh="GET_EVENT"
        :badge="CHECK_EXPIRED_EVENT(event)"
      ></ListOfEventDate>
      <div v-else-if="activeTab === 'uploadEventVideo'">
        <upload-event-video
          :event="event"
          :product="product"
          @refresh="GET_EVENT"
          :video-src="event.videoPath || ''"
          :videoSecondSrc="event.secVideoPath || ''"
          :type="type"
        ></upload-event-video>
      </div>
      <div v-else>
        <assignment-file
          :event="event"
          :product="product"
          :type="type"
        ></assignment-file>
      </div>
      <EventDatesDetail
        :product="product"
        @refresh="GET_EVENT"
        :event-dates="event"
        single
      ></EventDatesDetail>
    </div>
    <page-loading v-else></page-loading>
  </div>
</template>

<script>
import Banner from "../List/Banner.vue";
import EventDatesDetail from "../Product/EventDatesDetail.vue";
import PageLoading from "../Shared/PageLoading.vue";
import ListOfEventDate from "../Product/ListOfEventDate.vue";
import EventTitle from "./EventTitle.vue";
import AssignmentFile from "../Assignments/index.vue";
import UploadEventVideo from "./EventVideo.vue";
export default {
  components: {
    Banner,
    PageLoading,
    EventDatesDetail,
    ListOfEventDate,
    EventTitle,
    UploadEventVideo,
    AssignmentFile,
  },
  props: {
    product: {
      default: () => {},
    },
    type: {
      default: () => {},
    },
  },
  data() {
    return {
      event: {},
      activeTab: "list",
      loaded: false,
    };
  },
  watch: {
    $route: {
      deep: true,
      handler() {
        this.SET_ACTIVE_TAB();
      },
    },
  },
  methods: {
    CHECK_EXPIRED_EVENT(item) {
      const today = new Date().getTime();
      const eventTime = new Date(item.date).getTime() + item.time * 1000;
      const eventFinishTime =
        new Date(item.date).getTime() + item.time * 100 + item.duration * 1000;
      if (item.isActive === 0)
        return {
          status: false,
          text: this.$t("eventCanceled"),
          variant: "danger",
        };
      else if (today > eventFinishTime)
        return {
          status: false,
          text: this.$t("eventDone"),
          variant: "success",
        };
      else if (today > eventTime)
        return {
          status: false,
          text: this.$t("eventContiuning"),
          variant: "warning",
        };
      else
        return {
          status: true,
          text: this.$t("eventWaiting"),
          variant: "info",
        };
    },
    async GET_EVENT() {
      this.loaded = false;
      const eventId = Number(this.$route.params.eventItemId);
      if (isNaN(eventId)) return this.$router.push("/error");
      const response = await this.$api.get(`EventItems/${eventId}`);
      if (response.message === "OK") {
        // if (this.product.id !== response.data.eventId)
        //   this.$router.push("/error");
        this.event = response.data;
        this.loaded = true;
        this.SET_ACTIVE_TAB();
      } else this.$router.push("/error");
    },
    SET_ACTIVE_TAB() {
      const tab = this.$route.query.tab;
      if (tab) this.activeTab = tab;
    },
    SET_BACK() {
      this.$router.push(
        `/product/events-${this.event.eventId}?path=eventdates`
      );
    },
  },
  mounted() {
    this.GET_EVENT();
  },
};
</script>

<style></style>
