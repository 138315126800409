import Vue from "vue";
const vm = Vue.prototype;
import i18n from "../../plugins/i18n";

export default {
  state: {
    newCommentIsLoading: false, // yeni ürün loadingi
    commentListRequested: false, // daha önce ürün verisi çekilmiş mi?
    commentsAreLoading: false, // ürünler listesi loadingi
    comments: [], // ürünler listesi
    count: 0, // kaç adet ürün bulunuyor bilgisi.
    commentsWithFilter: [],
  },
  mutations: {
    // ürünlerin loadingi
    SET_COMMENTS_ARE_LOADING(state, payload) {
      state.commentsAreLoading = payload;
    },
    // ürünleri projeye ekle
    SET_COMMENTS(state, payload) {
      if (!state.commentListRequested) state.comments = [];
      if (payload.search !== "") {
        state.comments = [];
        state.commentListRequested = false;
      } else state.commentListRequested = true;

      state.comments = [...state.comments, ...payload.data];
      state.count = payload.count;
    },
    // filtreden gelen ürünleri ekle
    SET_COMMENTS_WITH_FILTER(state, payload) {
      state.commentsWithFilter.push(payload);
    },
    // güncellenen ürünü ekle
    SET_UPDATE_COMMENT_THAT_IS_FILTER_TO_LIST(state, payload) {
      const productIndex = state.commentsWithFilter.findIndex(
        (c) => c.productId === payload.productId
      );
      if (productIndex !== -1) {
        const commentIndex = state.commentsWithFilter[
          productIndex
        ].data.findIndex((c) => c.id === payload.form.id);
        if (commentIndex !== -1)
          state.commentsWithFilter[productIndex].data[commentIndex] = {
            ...state.commentsWithFilter[productIndex].data[commentIndex],
            ...payload.form,
          };
      }
    },
    // ürünü yeni eklerkenki loading
    SET_NEW_COMMENT_IS_LOADING(state, payload) {
      state.newCommentIsLoading = payload;
    },
    // eklenen ürünü ekle
    SET_NEW_COMMENT_TO_LIST(state, payload) {
      state.comments.unshift(payload);
    },
    // güncellenen ürünü ekle
    SET_UPDATE_COMMENT_TO_LIST(state, payload) {
      const commentIndex = state.comments.findIndex((c) => c.id === payload.id);
      if (commentIndex !== -1)
        state.comments[commentIndex] = {
          ...state.comments[commentIndex],
          ...payload,
        };
    },
    // ürünü sil
    SET_REMOVE_COMMENT(state, { id }) {
      const commentIndex = state.comments.findIndex((c) => c.id === id);
      if (commentIndex !== -1) state.comments.splice(commentIndex, 1);
    },
  },
  actions: {
    // ürünleri getir
    async GET_COMMENTS(
      { state, commit },
      { page = 1, search = "", limit = 12 }
    ) {
      if (
        state.comments.length !== 0 &&
        (search === "") & state.commentListRequested &&
        state.comments.map((comment) => comment.page).includes(page)
      )
        return; // daha önce liste çekilmişse return at.
      commit("SET_COMMENTS_ARE_LOADING", true);
      const response = await vm.$api.get(
        `Comments?page=${page}&searchKey=${search}&limit=${limit}`
      );
      if (response.message === "OK" && response.result === "OK")
        commit("SET_COMMENTS", {
          count: response.data.count || 0,
          search,
          data: response.data.items.map((d) => {
            // .items gelecek.
            return {
              ...d,
              page,
            };
          }),
        });
      else commit("SET_COMMENTS", []);
      commit("SET_COMMENTS_ARE_LOADING", false);
    },
    async GET_COMMENTS_WITH_FILTER(
      { state, commit },
      { page = 1, limit = 12, filter = "", dataFilter = {}, key = "" }
    ) {
      if (
        state.commentsWithFilter.find(
          (c) => c[key] === dataFilter[key] && c.page === page
        )
      )
        return; // daha önce liste çekilmişse return at.
      commit("SET_COMMENTS_ARE_LOADING", true);
      const response = await vm.$api.get(
        `Comments?${filter}page=${page}&limit=${limit}`
      );
      if (response.message === "OK" && response.result === "OK")
        commit("SET_COMMENTS_WITH_FILTER", {
          count: response.data.count || 0,
          ...dataFilter,
          page,
          data: response.data.items.map((d) => {
            // .items gelecek.
            return {
              ...d,
              page,
            };
          }),
        });
      else commit("SET_COMMENTS", []);
      commit("SET_COMMENTS_ARE_LOADING", false);
    },
    async SEND_UPDATE_COMMENT({ commit }, { productId = -1, form }) {
      commit("SET_NEW_COMMENT_IS_LOADING", true);
      const response = await vm.$api.put(`Comments/${form.id}`, form);
      if (response.message === "OK" && response.result === "OK") {
        commit("SET_UPDATE_COMMENT_TO_LIST", form);
        if (productId !== -1)
          commit("SET_UPDATE_COMMENT_THAT_IS_FILTER_TO_LIST", {
            form,
            productId,
          });
        commit("SET_LOADING_MODAL", {
          loading: true,
          alertBox: {
            status: true,
            variant: "success",
            message: i18n.t("commentActionIsSucceed"),
          },
        });
        commit("SET_NEW_COMMENT_IS_LOADING", false);

        return {
          status: true,
          variant: "success",
          message: i18n.t("commentActionIsSucceed"),
        };
      } else {
        commit("SET_LOADING_MODAL", {
          loading: true,
          alertBox: {
            status: true,
            variant: "danger",
            message: i18n.t("somethingWentWrong"),
          },
        });
        commit("SET_NEW_COMMENT_IS_LOADING", false);

        return {
          status: true,
          variant: "danger",
          message: i18n.t("somethingWentWrong"),
        };
      }
    },
  },
};
