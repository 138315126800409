<template>
  <div class="container policy">
    <div
      v-if="getSettings.creditCardConfirmDate !== null"
      id="confirm-button"
      class="col-12 d-flex align-items-center changed-video-image"
    >
      <div class="col-2 col-lg-1">
        <img src="/images/success.svg" width="80" alt="" />
      </div>
      <div class="col-10 col-md-10">
        <div>
          {{ formatter(new Date(getSettings.creditCardConfirmDate)) }} tarihinde
          sözleşmeyi onayladınız.
        </div>
      </div>
    </div>
    <div
      v-if="!hideToScrollButton && getSettings.creditCardConfirmDate === null"
      class="policy-button"
      @click="FORWARD_TO_BOTTOM"
    >
      <img
        src="/images/fast-forward.png"
        width="16"
        alt=""
        class="forward-icon"
      />
      <div class="mt-2">sözleşmeyi onayla</div>
    </div>
    <h4 class="text-primary">HEOCADEMY KULLANICI SÖZLEŞMESİ</h4>
    <p>
      SİTEMİZE ÜYE OLARAK, KULLANICI SÖZLEŞMESİ'Nİ OKUDUĞUNUZU, İÇERİĞİNİ
      ANLADIĞINIZI VE HÜKÜMLERİNİ KABUL ETTİĞİNİZİ VE ONAYLADIĞINIZI KABUL,
      BEYAN ETMİŞ OLUYORSUNUZ. <strong>(ilan yılı: 2024)</strong>
    </p>
    <h5 class="mt-4">1. Taraflar</h5>
    <p>
      İşbu sözleşme ve sözleşmenin ayrılmaz birer parçası olan eklerden oluşan
      Heocademy Kullanıcı Sözleşmesi (bundan böyle kısaca 'Kullanıcı Sözleşmesi'
      olarak anılacaktır), Heocademy ile Site'ye üye olan Kullanıcı arasında,
      Kullanıcı'nın Site'ye üye olması amacıyla ve Kullanıcı Sözleşmesi'nin,
      Site'nin bulunduğu elektronik ortamda Kullanıcı tarafından onaylanması
      anında düzenlenmiştir. Site'ye üye olarak, Kullanıcı Sözleşmesi'nin
      tamamını okuduğunuzu, içeriğini bütünü ile anladığınızı ve tüm hükümlerini
      onayladığınızı kabul, beyan ve taahhüt etmektesiniz.
    </p>
    <h5 class="mt-4">2. Tanımlar</h5>
    <p>
      <strong>Kullanıcı:</strong> Site'ye üye olan ve Site'de sunulan
      Hizmet'lerden, işbu Kullanıcı Sözleşmesin'de belirtilen koşullar dahilinde
      yararlanan gerçek veya tüzel kişi
    </p>
    <p>
      <strong>Alıcı:</strong> Site'de sunulan Hizmet'leri kullanmak suretiyle,
      Satıcı tarafından satışa arz edilen mal ve/veya hizmetleri satın alan
      Kullanıcı
    </p>
    <p>
      <strong>Satıcı:</strong> Site'de sunulan Hizmet'leri kullanmak suretiyle,
      hukuken mülkiyetini haiz bulunduğu ve mülkiyet üzerinde tasarrufta
      bulunabilme hak ve yetkisine sahip olduğu mal ve/veya hizmetleri, diğer
      Kullanıcı'lara yönelik olarak satışa arz eden Kullanıcı
    </p>
    <p>
      <strong> Site:</strong> Heocademy (www.heocademy.com) isimli alan adından
      ve bu alan adına bağlı alt alan adlarından oluşan web sitesi
    </p>
    <p>
      <strong>Heocademy Eğitim Platformu (Kısaca "Platform"):</strong>
      Kullanıcı'ların Kullanıcı Sözleşmesi'nde tanımlı olan iş ve işlemlerini
      gerçekleştirmelerini sağlamak amacıyla, Heocademy, Site içerisinde ortaya
      konulan uygulamalar, Heocademy, Kullanıcı'ların Kullanıcı Sözleşmesi'nde
      tanımlı olan iş ve işlemleri daha etkin şekilde gerçekleştirebilmelerini
      sağlamak üzere, dilediği zaman Hizmet'lerinde değişiklikler ve/veya
      uyarlamalar yapabilir.
    </p>
    <p>
      <strong>Ürün:</strong> Site'de Satıcı tarafından satışa arz edilen her
      türlü mal ve/veya hizmet
    </p>
    <h5 class="mt-4">3. Sözleşmenin Konusu ve Kapsamı</h5>
    <p>
      <strong>3.1 </strong> Kullanıcı Sözleşmesi'nin konusu, Site'de sunulan
      Hizmet'lerin, bu Hizmet'lerden yararlanma şartlarının ve tarafların hak ve
      yükümlülüklerinin tespitidir.
    </p>
    <p>
      <strong>3.2 </strong>
      Kullanıcı Sözleşmesi'nin kapsamı, Kullanıcı Sözleşme'si ve ekleri ile Site
      içerisinde yer alan, kullanıma, üyeliğe ve Hizmet'lere ilişkin olarak
      Heocademy tarafından yapılmış olan bilcümle uyarı, yazı ve açıklama gibi
      beyanlardır.
    </p>
    <p>
      <strong>3.3</strong> Kullanıcı Sözleşmesi'nin hükümlerini kabul etmekle,
      Site içinde yer alan, kullanıma, üyeliğe ve Hizmet'lere ilişkin olarak
      Heocademy tarafından açıklanan her türlü beyanı ve bu beyanlara uygun
      davranacağınızı da kabul etmiş olmaktasınız.
    </p>
    <h5 class="mt-4">4. Üyelik ve Hizmet Kullanımı Şartları</h5>
    <p>
      <strong>4.1</strong> Üyelik, Site'nin ilgili bölümünden, Kullanıcı olmak
      isteyen kişi tarafından, Site'ye üye olmak için gerekli bilgilerinin
      girilmesi ile tamamlanır. Üyelik işlemi tamamlanmadan, işbu Kullanıcı
      Sözleşmesin'de tanımlanan Kullanıcı olma hak ve yetkisine sahip olunamaz.
    </p>
    <p>
      <strong>4.2</strong> Site'ye üye olabilmek için, işbu Kullanıcı
      Sözleşmesi'nin 5.2 maddesi uyarınca, Heocademy tarafından, geçici olarak
      üyelikten uzaklaştırılmış veya üyelikten süresiz yasaklanmış olmamak
      gerekmektedir. Yukarıda belirtildiği gibi, işbu Kullanıcı Sözleşmesi'nin
      5.2 maddesi uyarınca, Heocademy tarafından geçici olarak üyelikten
      uzaklaştırılmış veya üyelikten süresiz yasaklanmış olan kişilerin Site
      kayıt işlemlerini tamamlamış olmaları, Site üyesi olmaları sonucunu
      doğurmayacaktır.
    </p>
    <h5 class="mt-4">5. Hak ve Yükümlülükler</h5>
    <p>
      <strong>a)</strong> Kullanıcı, üyelik prosedürlerini yerine getirirken,
      Site'nin Hizmet'lerinden faydalanırken ve Site'deki Hizmet'lerle ilgili
      herhangi bir işlemi yerine getirirken, Kullanıcı Sözleşmesi'nde yer alan
      tüm şartlara, Site'nin ilgili yerlerinde belirtilen kurallara ve
      yürürlükteki tüm mevzuata uygun hareket edeceğini, yukarıda belirtilen tüm
      şart ve kuralları anladığını ve onayladığını kabul, beyan ve taahhüt eder.
    </p>
    <p>
      <strong>b)</strong> Kullanıcı, Gizlilik Politikasında yer alan hükümler
      ile birlikte yürürlükteki emredici mevzuat hükümleri gereğince veya diğer
      Kullanıcılar ile üçüncü şahısların haklarının ihlal edildiğinin iddia
      edilmesi durumlarında, Heocademy’'nin kendisine ait gizli/özel/ticari
      bilgileri gerek resmi makamlara ve gerekse hak sahibi kişilere açıklamaya
      yetkili olacağını ve bu sebeple Heocademy'den her ne nam altında olursa
      olsun tazminat talep edilemeyeceğini kabul, beyan ve taahhüt eder.
    </p>
    <p>
      <strong>c)</strong> Kullanıcı'ların Heocademy tarafından sunulan
      Hizmet'lerden yararlanabilmek amacıyla kullandıkları sisteme erişim
      araçlarının (Kullanıcı ismi, şifre v.b.) güvenliği, saklanması, üçüncü
      kişilerin bilgisinden uzak tutulması ve kullanılması durumlarıyla ilgili
      hususlar tamamen Kullanıcı'ların sorumluluğundadır. Kullanıcı'ların,
      sisteme giriş araçlarının güvenliği, saklanması, üçüncü kişilerin
      bilgisinden uzak tutulması, kullanılması gibi hususlardaki tüm ihmal ve
      kusurlarından dolayı Kullanıcı'ların ve/veya üçüncü kişilerin uğradığı
      veya uğrayabileceği zararlara istinaden Heocademy'nin, doğrudan veya
      dolaylı, herhangi bir sorumluluğu yoktur.
    </p>
    <p>
      <strong>d)</strong> Kullanıcı'lar, Site dahilinde kendileri tarafından
      sağlanan bilgi ve içeriklerin doğru ve hukuka uygun olduğunu kabul, beyan
      ve taahhüt ederler. Heocademy, Kullanıcı'lar tarafından Heocademy’'e
      iletilen veya Site üzerinden kendileri tarafından yüklenen, değiştirilen
      veya sağlanan bilgi ve içeriklerin doğruluğunu araştırma, bu bilgi ve
      içeriklerin güvenli, doğru ve hukuka uygun olduğunu taahhüt ve garanti
      etmekle yükümlü ve sorumlu olmadığı gibi, söz konusu bilgi ve içeriklerin
      yanlış veya hatalı olmasından dolayı ortaya çıkacak hiçbir zarardan da
      sorumlu tutulamaz.
    </p>
    <p>
      <strong>e) </strong> Kullanıcı'lar, Heocademy’'nin yazılı onayı olmadan,
      Kullanıcı Sözleşmesi kapsamındaki hak ve yükümlülüklerini, kısmen veya
      tamamen, herhangi bir üçüncü kişiye devredemezler.
    </p>
    <p>
      <strong>f)</strong> Heocademy’'nin sunduğu Hizmet'lerden yararlananlar ve
      Site'yi kullananlar, yalnızca hukuka uygun amaçlarla Site üzerinde işlem
      yapabilirler. Kullanıcı'ların, Site dahilinde yaptığı her işlem ve
      eylemdeki hukuki ve cezai sorumluluk kendilerine aittir. Her Kullanıcı,
      Heocademy ve/veya başka bir üçüncü şahsın ayni veya şahsi haklarına veya
      malvarlığına tecavüz teşkil edecek şekilde, Site dahilinde bulunan
      resimleri, metinleri, görsel ve işitsel imgeleri, video kliplerini,
      dosyaları, veritabanlarını, katalogları ve listeleri çoğaltmayacağını,
      kopyalamayacağını, dağıtmayacağını, işlemeyeceğini, gerek bu eylemleri ile
      gerekse de başka yollarla Heocademy ile doğrudan ve/veya dolaylı olarak
      rekabete girmeyeceğini kabul, beyan ve taahhüt eder. Heocademy,
      Kullanıcı'ların Kullanıcı Sözleşmesi hükümlerine ve/veya hukuka aykırı
      olarak Site üzerinde gerçekleştirdikleri faaliyetler nedeniyle üçüncü
      kişilerin uğradıkları veya uğrayabilecekleri zararlardan doğrudan ve/veya
      dolaylı olarak, hiçbir şekilde sorumlu tutulamaz.
    </p>
    <p>
      <strong> g)</strong> Kullanıcı'lar da dahil olmak üzere üçüncü kişiler
      tarafından Site'de sağlanan hizmetlerden ve yayınlanan içeriklerden dolayı
      Heocademy’nin, Heocademy çalışanlarının veya yöneticilerinin sorumluluğu
      bulunmamaktadır. Herhangi bir üçüncü kişi tarafından sağlanan ve
      yayınlanan bilgilerin, içeriklerin, görsel ve işitsel imgelerin doğruluğu
      ve hukuka uygunluğunun taahhüdü, bütünüyle bu eylemleri gerçekleştiren
      kişilerin sorumluluğundadır. Heocademy, Kullanıcı'lar da dahil olmak üzere
      üçüncü kişiler tarafından sağlanan hizmetlerin ve içeriklerin güvenliğini,
      doğruluğunu ve hukuka uygunluğunu taahhüt ve garanti etmemektedir.
    </p>
    <p>
      <strong>h)</strong> Kullanıcılar Site üzerinde yer alan kendi üyelikleri
      veya tanıdıklarına ait üyelikler arasında para transferini sağlayacak
      şekilde işlem yapmayacaklarını ve Site'nin işleyişini manipüle edecek
      davranışlarda bulunmayacaklarını, aksi halde Heocademy’n'in uğrayacağı her
      türlü zararı tazmin edeceklerini kabul, beyan ve taahhüt eder.
    </p>
    <p>
      <strong>ı)</strong> Kullanıcılar, işbu Kullanıcı Sözleşmesi’nin Site'nin
      mobil ortam dahil faaliyet gösterdiği her türlü mecrada geçerli olacağını
      kabul, beyan ve taahhüt ederler.
    </p>
    <p>
      <strong>i)</strong> Heocademy, Kullanıcıları'na kendi inisyatifleri
      doğrultusunda, Heocademy tarafından yapılacak tanıtım ve bilgilendirme
      mesajlarını almalarına imkan sağlamaktadır. Kullanıcılar, her türlü
      bilgilendirme ve tanıtım mesajlarının mobil ortam dahil Site'nin faaliyet
      gösterdiği her mecrada Heocademy tarafından gönderilmesine onay
      verdiklerini kabul, beyan ve taahhüt ederler. Kullanıcılar, kullanıcı
      hesabında yer alan ayarlar ile bilgilendirme ve tanıtım mesajlarının
      alınması ile ilgili değişiklik yapma hakkına sahiptiler.
    </p>
    <p>
      <strong>j)</strong> Kullanıcı, Heocademy’'nin Site'ye ilişkin mobil
      uygulamasında kredi kartı bilgileri şifrelenmiş şekilde Kullanıcının kendi
      cihazına kaydetmesine olanak verdiğini kabul, beyan ve taahhüt eder.
      Kullanıcı, cihazın çalınması, kaybolması veya yetkisiz kişilerin eline
      geçmesinden dolayı Heocademy’nin herhangi bir sorumluluğu olmadığını
      kabul, beyan ve taahhüt eder.
    </p>
    <h5 class="mt-4">5.1.1. Alıcı'ların Hak ve Yükümlülükleri</h5>
    <p>
      <strong>a)</strong> Alıcı, Ürün'ü satın almak amacıyla talepte bulunması
      veya Satıcı'nın belirlediği fiyatlama modeli ile yapılan satışlarda ise
      Ürün'ü sipariş vermesiyle; Ürün açıklamasını, Satıcı tarafından
      belirlenmiş satış şartlarını usullerini de kabul etmiş sayılır.
    </p>
    <p>
      <strong>b)</strong> Alıcı, fiyatlama modeli ile yapılan satışlarda
      Ürünler'e teklif vermeye veya teklifi iptal etmeye ilişkin Heocademy
      tarafından belirlenen kurallara uygun davranacağını kabul, beyan ve
      taahhüt eder.
    </p>
    <p>
      <strong>c)</strong> Alıcı, Site'de Satıcı'lar tarafından satışa arz edilen
      Ürünler'in ayıplı ve/veya kaçak ve/veya Yasaklı Ürünler'den olup
      olmadığını, niteliği, orjinalliği, Ürün'ün tanıtımında kullanılan yazılı
      ve/veya görüntülü açıklamaların doğruluğu dahil ancak bunlarla sınırlı
      olmamak üzere, Ürün'ün aslı ile ilgili Heocademy’'nin bilgi sahibi
      olmadığı ve olması gerekmediğini ve bunları taahhüt ve garanti etmek
      yükümlülüğü bulunmadığını kabul ve beyan eder.
    </p>
    <p>
      <strong>d)</strong> Alıcı, Site üzerinde gerçekleşmekte olan alım-satım
      sürecinin herhangi bir aşamasında ve her ne sebeple olursa olsun,
      Satıcı'nın Ürün'ü satmaktan vazgeçmesi veya Ürün'e verilen tekfileri iptal
      etmesi halinde, bu durumdan ötürü Heocademy’nin hiçbir sorumluluğu ve
      yükümlülüğü olmayacağını; Heocademy'den, satın almaya çalıştığı Ürün'ün
      veya muadilinin, her ne suretle olursa olsun tedarikini veya buradan
      hareketle herhangi bir tazminat ödenmesini talep edemeyeceğini kabul,
      beyan ve taahhüt eder.
    </p>
    <p>
      <strong></strong> Alıcı, Site'de Tüketicinin Korunması Hakkındaki Kanun ve
      Mesafeli Sözleşmelere Dair Yönetmelik çerçevesinde Heocademy’nin kendisine
      ait kullanıcı bilgilerini kullanabileceğini kabul, beyan ve taahhüt eder.
    </p>
    <p>
      <strong>f)</strong> Alıcı, Heocademy’n'in ön bilgilendirme formu ve
      mesafeli sözleşme'den kaynaklanan herhangi bir anlaşmazlıkta taraf
      olmayacağını; ön bilgilendirme formu ve mesafeli sözleşmeye ve/veya satım
      konusu Ürün'e ilişkin her türlü talebinin tek muhatabının Satıcı
      olacağını; bu taleplerle ilgili Heocademy’'nin herhangi bir yasal
      sorumluluğu bulunmadığını kabul, beyan ve taahhüt eder.
    </p>
    <p>
      <strong>g)</strong> Alıcı, Satıcı ile arasındaki satım akdinin tarafı
      olmayan ve gerek satım ilişkisiyle ve satım konusu Ürün ile ilgili
      herhangi bir işlem, taahhüt ve yükümlülükte bulunmayan Heocademy'nin,
      Tüketicinin Korunması Hakkında Kanun kapsamında, satıcı, sağlayıcı,
      imalatçı, üretici, bayi, acente, reklamcı, mecra kuruluşu ve sair her ne
      nam altında olursa olsun, hiç bir işlem, taahhüt ve yükümlülüğü
      olmadığını; Satıcı ile arasındaki ön bilgilendirme formunun ve mesafeli
      sözleşmenin tarafı olmadığını onaylar, anlar ve kabul, eder.
    </p>
    <h5 class="mt-4">5.1.2. Satıcı'ların Hak ve Yükümlülükleri</h5>
    <p>
      <strong>a) </strong>Satıcı, Site'de satışa arz ettiği Ürün'ün/Ürünler'in
      mülkiyetinin kendisine ait olduğunu ve/veya mülkiyeti devretmeye yetkili
      olduğunu ve Ürün'ün/Ürünler'in kendisi tarafından satılmasına karşı
      herhangi bir hukuki veya diğer engel bulunmadığını kabul, beyan ve taahhüt
      eder.
    </p>
    <p>
      <strong>b) </strong>Satıcı, Site'de satışa arz edeceği bir Ürün'ü, 'Hemen
      Al' seçeneğini kullanarak, önceden açıkladığı sabit bir fiyatla veya
      Teklif Ver fiyatlama modeli ile satışa sunma ve satma hak ve yetkisine
      sahiptir. Satıcı, Teklif Ver fiyatlama modeli ile yaptığı satışlarda da
      Alıcı'nın 'Hemen Al' seçeneği ile ürün bedelini ödeyeceğini kabul etmesi
      ve parayı göndermesi halinde, Ürünü almaya hak kazandığını ve kabul, beyan
      ve taahhüt eder.
    </p>
    <p>
      <strong>c)</strong> Satıcı, satışa arz ettiği Ürün'ün/Ürünler'in, Yasaklı
      Ürünler de dahil olmak üzere, Kullanıcı Sözleşmesi hükümlerince
      belirlenen, Site'nin ve Site'de sunulan Hizmet'lerin kullanımına ilişkin
      kurallara ve üyeliğe ilişkin koşullara aykırı olmadığını ve yürürlükteki
      mevzuata uygun olduğunu kabul, beyan ve taahhüt eder.
    </p>
    <p>
      <strong>d)</strong> Satıcı, Site'de sergilenen Ürünler'in tüm hukuki
      sorumluğunun kendisine ait olduğunu, Ürünler'in daha önce üreticisi veya
      yetkili satıcılar tarafından Türkiye'de ve Dünya'da piyasaya sürüldüğünü,
      Heocademy’'nin ürün arzı ve satışı konusunda, hiçbir sıfat altında,
      kendisiyle doğrudan veya dolaylı ilişki içinde olmadığını kabul eder
    </p>
    <p>
      <strong>e)</strong> Satıcı, Site'de satışa arz ettiği Ürünler'in Yasaklı
      Ürünler'den olmamasından; Ürünün satılmasının, dağıtılmasının veya
      herhangi bir şekilde Site'de satışa arzına ilişkin bir fiilde
      bulunulmasının yürürlükteki herhangi bir düzenlemeye aykırı olmasından
      dolayı doğabilecek her türlü sorumluluktan tek başına sorumlu olacağını
      kabul, beyan ve taahhüt eder.
    </p>
    <p>
      <strong>f)</strong> Satıcı, Site üzerinde gerçekleşmekte olan alım-satım
      sürecinin herhangi bir aşamasında ve her ne sebeple olursa olsun,
      Alıcı'nın Ürün'ü almaktan vazgeçmesi halinde, bu durumdan ötürü
      Heocademy’nin hiçbir sorumluluğu ve yükümlülüğü olmayacağını; Site'de,
      satmaya çalıştığı Ürün'ün, her ne suretle olursa olsun, alımdan vazgeçen
      Alıcı'nın oluşturduğu alış şartları üzerinden bir başkasına satılmasını
      veya buradan hareketle herhangi bir tazminat ödenmesini talep
      edemeyeceğini kabul, beyan ve taahhüt eder.
    </p>
    <p>
      <strong>g)</strong> Satıcı, işbu Sözleşmede yer alan herhangi bir hükme
      aykırılığı halinde Heocademy’nin herhangi bir zarara uğraması durumunda,
      Heocademy’nin doğmuş zararını derhal tazmin edeceğini ve Heocademy’nin
      herhangi bir ihbar ve ihtar şartı olmaksızın doğan zararını bu bedelden
      karşılayacağını kabul, beyan ve taahhüt eder.
    </p>
    <p>
      <strong>h)</strong> Satıcı, satışa arz ettiği Ürünler ile ilgili olarak
      gerek satım aşamasında ve gerekse satım işlemi tamamlanıp kendisine ödeme
      yapıldıktan sonra, Alıcı'ların ve üçüncü şahısların doğacak her türlü
      zararından tek başına sorumlu olduğunu ve işbu Kullanıcı Sözleşmesi'nde
      belirtilen yükümlülükleri yerine getirmemesinden dolayı alıcı ve hak
      sahipleri tarafından sürülecek tüm talep ve davalardan doğacak olan hak ve
      alacaklar ile masraf ve avukatlık ücretlerini karşılayacağını kabul, beyan
      ve taahhüt eder. Heocademy aleyhine Alıcı ve hak sahipleri tarafından
      gelecek olan tüm davalardan doğacak olan avukatlık ücreti, tazminat ve
      diğer sair taleplerini Satıcıya rücü etme hakkı her zaman mevcuttur.
    </p>
    <p>
      <strong>ı)</strong> Satıcı, Heocademy'nin, Site'de sattığı Ürünler'e
      ilişkin Tüketicinin Korunması Hakkında Kanun ve Mesafeli Satışlara Dair
      Yönetmelik gereğince kendisine ait kullanıcı ve satışa konu Ürün'e ilişkin
      verdiği bilgileri kullanabileceğini kabul, beyan ve taahhüt eder.
    </p>
    <p>
      <strong>i)</strong> Satıcı, Ürün ile ilgili bilgilerin doğruluğundan
      sorumlu olacağını, Heocademy'nin kendisi tarafından sağlanan bu bilgilerin
      doğruluğunu kontrol etmekle yükümlü olmadığını kabul, beyan ve taahhüt
      eder.
    </p>
    <p>
      <strong>j)</strong> Satıcı, Tüketicinin Korunması Hakkında Kanun ve
      Mesafeli Satışlara Dair Yönetmelik hükümlerine uygun davranacağını ve işbu
      hükümleri kısıtlayıcı veya ortadan kaldırıcı herhangi bir işlem veya
      beyanda bulunmayacağını kabul, beyan ve taahhüt eder. Satıcı, Tüketicinin
      Korunması Hakkında Kanun kapsamında Alıcı'ya karşı tüm sorumluluğun
      kendisine ait olduğunu, Alıcı ile arasında kaynaklanan her türlü
      anlaşmazlık, dava ve talepten Heocademy'nin her hangi bir sorumluluğu
      olmayacağını ve ön bilgilendirme formu ve mesafeli sözleşme hükümlerine
      uygun hareket edeceğini kabul, beyan ve taahhüt eder.
    </p>
    <h5 class="mt-4">5.2. Heocademy'nin Hak ve Yükümlülükleri</h5>
    <p>
      a) Heocademy, Site'de sunulan Hizmet'leri ve içerikleri her zaman, üçüncü
      kişilerin erişimine kapatabilme ve silme hakkını saklı tutmaktadır.
      Heocademy, bu hakkını, hiçbir bildirimde bulunmadan ve önel vermeden
      kullanabilir. Kullanıcı'lar, Heocademy’nin talep ettiği değişiklik ve/veya
      düzeltmeleri ivedi olarak yerine getirmek zorundadırlar. Heocademy
      tarafından talep edilen değişiklik ve/veya düzeltme taleplerinin
      Kullanıcı'lar tarafından zamanında yerine getirilmemesi sebebiyle doğan
      veya doğabilecek zararlar, hukuki ve cezai sorumluluklar tamamen
      Kullanıcı'lara aittir.
    </p>
    <p>
      b) Heocademy, Site üzerinden, Heocademy’n'in kendi kontrolünde olmayan
      üçüncü kişi satıcılar, sağlayıcılar ve başkaca üçüncü kişilerin sahip
      olduğu ve işlettiği başka web sitelerine ve/veya portallara, dosyalara
      veya içeriklere 'link' verebilir. Bu 'link'ler, Kullanıcı'lar tarafından
      veya sadece referans kolaylığı nedeniyle Heocademy tarafından sağlanmış
      olabilir ve web sitesini veya siteyi işleten kişiyi desteklemek amacı veya
      web sitesi veya içerdiği bilgilere yönelik herhangi bir türde bir beyan
      veya garanti niteliği taşımamaktadır. Site üzerindeki 'link'ler
      vasıtasıyla erişilen portallar, web siteleri, dosyalar ve içerikler, bu
      'link'ler vasıtasıyla erişilen portallar veya web sitelerinden sunulan
      hizmetler veya Ürün'ler veya bunların içeriği hakkında Heocademy’nin
      herhangi bir sorumluluğu yoktur.
    </p>

    <p>
      c) Heocademy, Site'de sağlanan Hizmet'ler ve yer alan Ürünler ile ilgili
      olarak Kullanıcı'lar arasında ortaya çıkan uyuşmazlıklarda, arabulucu veya
      hakem sıfatlarıyla görev almaz.
    </p>
    <p>
      d) Kullanıcı Sözleşmesi'nde belirtilen Yasaklı Ürünler'i ve ilgili mevzuat
      tarafından satışı yasaklanan Ürünleri satışa arz ettiği ve/veya Kullanıcı
      Sözleşmesi hükümlerine ve Site'de belirtilen kurallara uymadığı tespit
      edilen Satıcı'nın üyeliği, Heocademy tarafından geçici veya kalıcı olarak
      iptal edilir.
    </p>
    <p>
      e) Heocademy, Kullanıcılar arasında site üzerinden gerçekleşen ve Site'nin
      işleyişine ve/veya Kullanıcı Sözleşmesine ve/veya Site'nin genel
      kurallarına ve/veya genel ahlak kurallarına aykırı ve Heocademy tarafından
      kabul edilmesi mümkün olmayan mesajların ve/veya içeriklerin tespit
      edilmesi amacıyla gerekli içerik ve/veya mesaj taraması yapabilir ve
      tespit ettiği mesaj ve/veya içerikleri istediği zaman ve şekilde erişimden
      kaldırabilir; Heocademy, bu mesaj ve/veya içeriği oluşturan Kullanıcı'yı
      yazılı uyarabilir ve/veya Kullanıcı'nın üyeliğine, herhangi bir ihbar
      yapmadan, geçici veya kalıcı olarak, son verebilir.
    </p>
    <p>
      f) Kullanıcı'lar ve Heocademy hukuken bağımsız taraflardır. Aralarında
      ortaklık, temsilcilik veya işçi-işveren ilişkisi yoktur. Kullanıcı
      Sözleşmesi'nin onaylanması ve uygulanması sonucunda, ortaklık, temsilcilik
      veya işçi-işveren ilişkisi doğmaz.
    </p>
    <p>
      g) Kullanıcı'ların, Site'ye üye olurken sisteme yükledikleri "kullanıcı
      isimleri" veya "akademi" isimleri de işbu Kullanıcı Sözleşmesi içinde yer
      alan hükümlere tabi olup, Kullanıcı'ların "kullanıcı ismi" veya "akademi"
      ismi belirlerken üçüncü şahısların telif hakkı, marka, ticari unvan gibi
      yasal haklarını ihlal etmemesi gerekmektedir. Kullanıcı'ların işbu madde
      hükmüne aykırı davranması durumunda, Heocademy Kullanıcı Sözleşmesi'ne
      aykırı bu durumun düzeltilmesini Kullanıcı'dan talep edebileceği gibi
      dilerse Kullanıcı'ya önceden haber vermeksizin Kullanıcı'nın üyeliğini
      geçici veya kalıcı olarak iptal edebilir.
    </p>
    <p>
      h) Heocademy, Kullanıcılar'ın satışa arz ettikleri ürünler ile ilgili
      bilgileri istatistiksel bilgi toplamak amacıyla kullanabilir.
    </p>
    <h5 class="mt-4">6. Gizlilik Politikası</h5>
    <p>
      Heocademy, Kullanıcı'lara ilişkin bilgileri, Gizlilik Politikası'ndaki
      düzenlemelere uygun olarak kullanabilir. Heocademy, Kullanıcı'lara ait
      gizli bilgileri, Kullanıcı Sözleşmesi'nde ve Gizlilik Politikasının aksine
      müsade edilen durumlar haricinde, üçüncü kişi ve kurumlara kullandırılmaz.
    </p>
    <h5 class="mt-4">7. İptal ve Ödeme İşlemleri</h5>
    <p>
      a) Tüm kullanıcılar Heocademy Eğitim Platformunu ücretsiz olarak
      deneyebilirler. Bu sayede sistemin ne olduğu, sunulan hizmetin hangi
      bileşenlerden oluştuğu ve sistemin işleyişi hakkında kullanıcının bilgi
      sahibi olması sağlanmaktadır.
    </p>
    <p>
      b) Heocademy’nin sunduğu hizmeti satın almaya karar vermiş olan
      kullanıcılar; deneme kullanımı yapmış, sunulan hizmeti ve bu hizmeti
      oluşturan bileşenlerin ne olduğunu anlamış ve bu ürünü (platformu)
      beğenmiş demektir.
    </p>
    <p>
      c) Heocademy’den yapılan alışveriş tamamen kullanıcının bilinci dahilinde
      gerçekleşen bir eylemdir.
    </p>
    <p>
      d) Ayrıca Heocademy, Kullanım Sözleşmesi maddelerinden herhangi birinde
      belirtilen kuralın/kuralların ihlal edildiğini görmesi durumunda
      kullanıcının satın aldığı hizmeti durdulabilir.
    </p>
    <p>
      e) Heocademy durdurulan hizmeti vermeme hakkına sahiptir. Bu nedenle satın
      alınan ya da durdurulan paketlerin iptali veya iadesi söz konusu değildir.
    </p>
    <p>
      f) Platformdaki hatalar dışındaki tüm istekler, talepler, teknik destek ve
      danışmanlık hizmetleri saat başı on beş (15) euro ücretlendirilecektir.
    </p>
    <p>
      g) Kullanıcı, hizmetler için belirlenen ödeme süresinde üç (3) gecikme
      yaşadığında, sistem askıya alınacaktır. Kullanıcı askıya alma durumunda,
      hesabına erişim sağlanamayacağının ve hizmetleri kullanılamayacağının
      bilgisine sahiptir. Hesap askıya alındıktan sonra ancak ödemenin yeniden
      yapılması yolu ile yeniden aktif hale getirilecektir.
    </p>
    <p>
      h) Ödeme yapılmayan dolayısıyla askıya alınan hesaplarla ilişkili veriler,
      askıya alma tarihinden itibaren üç (3) ay içerisinde tamamen silinecektir.
    </p>
    <h5 class="mt-4">
      8. Abonelik İşlemleri ve Kart Bilgilerinin Kaydedilmesi
    </h5>
    <p>
      a) Hizmetlerin sunumunda bize yardımcı olmaları veya hizmetleri bizim
      adımıza sunmaları için başka şirketler, aracılar veya yükleniciler
      ("Hizmet Sağlayıcılar") kullanıyoruz. Örneğin Abonelik hizmetlerinin
      sürekliliğini sağlamak ve kullanıcılarımızın kesintisiz hizmet alabilmesi
      için, kullanıcıların ödeme bilgilerini (kredi kartı veya banka kartı
      bilgileri) güvenli, kolay ve hızlı bir ödeme sistemi olan ‘‘Lidio’’
      tarafından kaydedilmektedir.
    </p>
    <p>
      b) Bu bilgiler, kullanıcıların her abonelik döneminde tekrar ödeme bilgisi
      girmelerine gerek kalmadan abonelik ücretlerinin otomatik olarak tahsil
      edilmesi amacıyla kullanılacaktır. Yalnızca ‘‘Lidio’’ tarafından
      kaydedilen bu bilgiler, Heocademy ile paylaşılmaz veya sunucularında
      saklanmaz. ''Lidio'' tarafından kaydedilen bilgiler hizmet sağlama ile
      bağlantılı işler dışında kullanılma veya açıklama yetkisine sahip
      değildir.
    </p>
    <p>
      c) Kullanıcılar, abonelik işlemi sırasında ödeme bilgilerinin kaydedilmesi
      ve işlenmesine yönelik onay vermektedirler.
    </p>
    <p>
      d) Bu onay, kullanıcı sözleşmesinin kabulü ile birlikte yasal olarak
      geçerli ve bağlayıcı bir hale gelmektedir.
    </p>
    <p>
      e) Kullanıcılar, diledikleri zaman ödeme bilgilerini güncelleme veya silme
      hakkına sahiptirler. Bu işlemler, kullanıcı hesabı üzerinden veya müşteri
      hizmetleri aracılığıyla gerçekleştirilebilir.
    </p>
    <p>
      f) Ödeme bilgilerinin silinmesi durumunda, mevcut abonelik döneminin
      sonunda abonelik otomatik olarak sona erecektir.
    </p>
    <h5 class="mt-4">9. Garanti Koşulları</h5>
    <p>
      a) Heocademy’nin kullanıcısı olmaktan doğan, doğrudan veya dolaylı olarak
      ortaya çıkabilecek, nedensel veya tesadüfî gelir ve menfaat kayıpları, işe
      zorunlu ara verilmesi, program veya diğer verilerin kaybedilmesi gibi
      durumlardan Heocademy kesinlikle sorumlu tutulamaz.
    </p>
    <p>
      Heocademy olduğu şekli ile kullanıcıya sunulmuş olup, yukarıda bahsedilen
      durumların meydana gelmeyeceğini veya içeriklerin tam, doğru ve kullanışlı
      olduğunu taahhüt etmez.
    </p>
    <p>
      b) Yine Heocademy hiçbir şeye bağlı olmaksızın içeriğinin tamamını veya
      bir kısmını değiştirmeye, kaldırmaya veya yenisini eklemeye yetkilidir.
    </p>
    <p>
      c) Kullanıcı, herhangi bir nedenle hesabını askıya alması veya dondurması
      durumunda, kendi verilerinin yedeklenmesinden sorumludur.
    </p>
    <p>
      d) Heocademy, kullanıcılara veri yedekleme hizmeti sağlama taahhüdünde
      bulunmamaktadır. Kullanıcılar, verilerinin güvenliğinden ve
      yedeklemesinden tamamen kendi sorumluluklarında olduklarını kabul ederler
    </p>
    <h5 class="mt-4">10. Diğer Hükümler</h5>
    <h5 class="mt-4">10.1. Fikri Mülkiyet Hakları</h5>
    <p>
      a) Site'nin (tasarım, metin, imge, html kodu ve diğer kodlar da dahil ve
      fakat bunlarla sınırlı olmamak kaydıyla) tüm elemanları (Heocademy’nin
      telif haklarına tabi çalışmalar) Heocademy'e ait olarak ve/veya Heocademy
      tarafından üçüncü bir kişiden alınan lisans hakkı altında
      kullanılmaktadır. Kullanıcı'lar, Heocademy Hizmet'lerini, Heocademy
      bilgilerini ve Heocademy’nin telif haklarına tabi çalışmalarını yeniden
      satamaz, paylaşamaz, dağıtamaz, sergileyemez, çoğaltamaz, bunlardan
      türemiş çalışmalar yapamaz veya hazırlayamaz, veya başkasının
      Heocademy'nin Hizmet'lerine erişmesine veya kullanmasına izin veremez;
      aksi takdirde, lisans verenler de dahil ancak bunlarla sınırlı olmaksızın,
      üçüncü kişilerin uğradıkları zararlardan dolayı Heocademy’den talep edilen
      tazminat miktarını ve mahkeme masrafları ve avukatlık ücreti de dahil
      ancak bununla sınırlı olmamak üzere diğer her türlü yükümlülükleri
      karşılamakla sorumlu olacaklardır.
    </p>
    <p>
      b) Heocademy’'nin, Heocademy Hizmet'leri, Heocademy bilgileri, Heocademy
      telif haklarına tabi çalışmalar, Heocademy ticari markaları, Heocademy
      ticari görünümü veya Site vasıtasıyla sahip olduğu her tür maddi ve fikri
      mülkiyet hakları da dahil tüm malvarlığı, ayni ve şahsi hakları, ticari
      bilgi dahil tüm hakları saklıdır.
    </p>
    <p>
      c) Kullanıcılar, Heocademy’nin Marka ve Logosu'nun kullanımına ilişkin
      kurallara uygun davranacaklarını kabul, beyan ve taahüt ederler.
    </p>
    <h5 class="mt-4">10.2. Sözleşme Değişiklikleri</h5>
    <p>
      a) Heocademy, tamamen kendi takdirine bağlı ve tek taraflı olarak, işbu
      Kullanıcı Sözleşmesi ve eklerini, uygun göreceği herhangi bir zamanda,
      Site'de ilan etmek suretiyle değiştirebilir. İşbu Kullanıcı Sözleşmesi'nin
      değişen hükümleri, ilan edildikleri tarihte veya önceden belirtilen
      geçerlilik tarihi olması halinde ise geçerlilik tarihinde geçerlilik
      kazanacak; geri kalan hükümler, aynen yürürlükte kalarak hüküm ve
      sonuçlarını doğurmaya devam edecektir. İşbu Kullanıcı Sözleşmesi,
      Kullanıcı'nın tek taraflı beyanları ile değiştirilemez.
    </p>
    <p>
      b) İşbu Kullanıcı Sözleşmesi mevcut kayıtlı tüm kullanıcılar açısından 1
      Mayıs 2024 tarihinde ve bu tarihte ve sonrasında kayıt olan tüm
      kullanıcılar açısından kayıt oldukları kayıt tarihinden itibaren
      geçerlilik kazanacaktır.
    </p>
    <h5 class="mt-4">10.3. Mücbir Sebepler</h5>
    <p>
      Hukuken 'mücbir sebep' sayılan tüm hallerde, Heocademy, işbu Kullanıcı
      Sözleşmesi ile belirlenen yükümlülüklerinden herhangi birini geç veya
      eksik ifa etme veya ifa etmeme nedeniyle yükümlü değildir. Bu ve bunun
      gibi durumlar, Heocademy için, gecikme, eksik ifa etme veya ifa etmeme
      veya temerrüt addedilmeyecek veya bu durumlar için Heocademy’'den herhangi
      bir nam altında tazminat talep edilemeyecektir. "Mücbir sebep" terimi,
      doğal afet, isyan, savaş, grev, iletişim sorunları, altyapı ve internet
      arızaları, elektrik kesintisi ve kötü hava koşulları da dahil ve fakat
      bunlarla sınırlı olmamak kaydıyla, ilgili tarafın makul kontrolü haricinde
      ve gerekli özeni göstermesine rağmen önleyemediği, kaçınılamayacak olaylar
      olarak yorumlanacaktır.
    </p>
    <h5 class="mt-4">10.4. Uygulanacak Hukuk ve Yetki</h5>
    <p>
      İşbu Kullanıcı Sözleşmesi'nin uygulanmasında, yorumlanmasında ve hükümleri
      dahilinde doğan hukuki ilişkilerin yönetiminde Türk Hukuku uygulanacaktır.
      İşbu Kullanıcı Sözleşmesi'nden doğan veya doğabilecek her türlü ihtilafın
      hallinde, İzmir Mahkemeleri ve İcra Daireleri yetkilidir.
    </p>
    <h5 class="mt-4">10.5. Sözleşmenin Feshi</h5>
    <p>
      İşbu Kullanıcı Sözleşmesi, Kullanıcı Site'ye üye olduğu sürece yürürlükte
      kalacak ve taraflar arası hüküm ve sonuçlarını doğurmaya devam edecek;
      Kullanıcı'nın geçici veya kalıcı olarak üyeliğinin durdurulması hallerinde
      sona ermiş sayılacaktır. Heocademy, Kullanıcı'ların işbu Kullanıcı
      Sözleşmesi'ni ve/veya, Site içinde yer alan kullanıma, üyeliğe ve
      Hizmet'lere ilişkin benzeri kuralları ihlal etmeleri durumunda ve
      özellikle aşağıda sayılan hallerde, Kullanıcı Sözleşmesi'ni tek taraflı
      olarak feshedebilecek ve Kullanıcı'lar, fesih sebebiyle, Heocademy’nin
      uğradığı tüm zararları tazmin etmekle yükümlü olacaktır:
    </p>
    <p>
      a) Kullanıcı'nın, herhangi bir yöntem kullanarak, Site'nin işleyişini
      manipüle edecek davranışlarda bulunması,
    </p>
    <p>
      b) Kullanıcı'nın kendisi için oluşturulmuş Kullanıcı profilini başkasına
      devretmesi veya kullanıma açması,
    </p>
    <p>
      c) Kullanıcı'nın üçüncü kişilerin haklarına tecavüz eden ve/veya etme
      tehlikesi bulunan fillerde bulunması,
    </p>
    <p>
      d) Kullanıcı’nın herhangi bir yöntem kullanarak Heocademy şirket
      stratejisini ihlal etmesi.
    </p>
    <h5 class="mt-4">11. EK KURALLAR</h5>
    <p>
      Kullanıcılar, işbu maddede tanımlanan kuralların Kullanıcı Sözleşmesi'nin
      eki ve ayrılmaz bir parçası olduğunu ve işbu Kullanıcı Sözleşmesi ile
      birlikte geçerli olacağını kabul ederler. Kullanıcılar kuralları okuyup
      anladıklarını kabul ederler.
    </p>
    <div
      id="confirm-button"
      class="text-center my-4"
      v-if="getSettings.creditCardConfirmDate === null"
    >
      <button @click="confirmPolicy()" class="btn-pill btn btn-primary btn-sm">
        Sözleşmeyi onayla
      </button>
    </div>
    <div
      v-else
      id="confirm-button"
      class="col-12 d-flex align-items-center changed-video-image"
    >
      <div class="col-2 col-lg-1">
        <img src="/images/success.svg" width="80" alt="" />
      </div>
      <div class="col-10 col-md-10">
        <div>
          {{ formatter(new Date(getSettings.creditCardConfirmDate)) }} tarihinde
          sözleşmeyi onayladınız.
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AlertBox from "../../mixins/AlertBox";
import { formatter } from "gurhan/helpers/Date";
export default {
  mixins: [AlertBox],
  data() {
    return {
      hideToScrollButton: false,
      redirectUrl: "",
      hash: "",
    };
  },
  methods: {
    formatter,
    FORWARD_TO_BOTTOM() {
      document
        .getElementById("confirm-button")
        .scrollIntoView({ behavior: "smooth", inline: "end" });
    },
    onScroll(e) {
      const scrollTop = e.target.scrollTop;
      const scrollHeight = e.target.scrollHeight;
      if (scrollHeight < scrollTop + 1500) {
        this.hideToScrollButton = true;
      } else this.hideToScrollButton = false;
    },
    async confirmPolicy() {
      this.SET_LOADING_MODAL(true);
      const response = await this.$apiHeocademy.post(
        `SubscriptionManagement/ConfirmedCreditCard`
      );
      if (response.message === "OK") {
        this.SET_ALERT_BOX("success", this.$t("updatedIsSucceed"));
        this.$store.commit("SET_POLICY_DATE", new Date().toISOString());
        setTimeout(() => {
          if (this.redirectUrl === "") this.$router.go(0);
          else
            this.$router.push(
              `/${this.redirectUrl}${
                this.hash !== "" ? `?hash=${this.hash}` : ""
              }`
            );
        }, 2500);
      } else {
        this.SET_ALERT_BOX("danger", this.$t("updatedIsNotSucceed"));
      }
    },
    setRedirectUrl() {
      this.redirectUrl = this.$route.query.redirect || "";
      this.hash = this.$route.query.hash || "";
    },
  },
  computed: {
    getSettings() {
      return this.$store.state.User.settings;
    },
  },
  mounted() {
    setTimeout(() => {
      document
        .getElementById("content")
        .addEventListener("scroll", this.onScroll, true);
      this.setRedirectUrl();
    }, 400);
  },
  beforeDestroy() {
    document
      .getElementById("content")
      .removeEventListener("scroll", this.onScroll, true);
  },
};
</script>

<style></style>
