<template>
  <Modal
    v-model="editEventDates"
    ref="editEventDatesModal"
    :title="$t('editEvent')"
    :hide-footer="showConfirm"
    :sm="showConfirm"
    @ok="SEND_TO_UPDATE_EVENT_ITEM"
  >
    <div
      v-if="alertBox.status"
      class="alert"
      :class="`alert-${alertBox.variant}`"
    >
      {{ alertBox.message }}
    </div>
    <div v-if="showConfirm">
      {{ $t("eventRemoveDesc") }}
      <br />
      <strong> {{ $t("doyouwanttoit") }}</strong>
      <div class="mt-4 text-right">
        <button @click="showConfirm = false" class="btn btn-pill m-1">
          {{ $t("back") }}
        </button>
        <button
          @click="SEND_TO_REMOVE_EVENT_ITEM"
          :disabled="isLoadingRemoveEventItem"
          class="btn btn-pill m-1 btn-danger"
        >
          <loading v-if="isLoadingRemoveEventItem"></loading>
          <span v-else> {{ $t("eventCancel") }}</span>
        </button>
      </div>
    </div>
    <div v-else>
      <div class="bg-light card">
        {{ $t("eventRemoveDesc2") }}
        <div class="mt-2 text-right">
          <button
            :disabled="isLoadingRemoveEventItem"
            @click="showConfirm = true"
            class="btn btn-pill btn-danger"
          >
            <loading v-if="isLoadingRemoveEventItem"></loading>
            <span v-else> {{ $t("eventCancel") }}</span>
          </button>
        </div>
      </div>
      <hr />
      <div class="row justify-content-center">
        <div class="col-12 col-md-8 my-2">
          <label for="">{{ $t("eventDate") }}</label>
          <!-- :disabled-date="notBeforeToday" -->
          <date-picker
            :lang="{
              formatLocale: {
                firstDayOfWeek: 1,
              },
            }"
            class="w-100"
            format="DD.MM.YYYY"
            v-model="form.date"
            type="date"
          ></date-picker>
        </div>
        <div class="col-12 col-md-8 my-2">
          <label for="">{{ $t("eventHour") }} (GMT+3)</label>
          <date-picker
            v-model="form.timeString"
            :minute-step="30"
            class="w-100"
            format="HH:mm"
            value-type="format"
            type="time"
            placeholder="HH:mm"
          ></date-picker>
        </div>
        <div class="col-12 col-md-8 my-2">
          <label for="">{{ $t("eventDuration") }}</label>
          <date-picker
            v-model="form.durationString"
            class="w-100"
            format="HH:mm"
            :hour-options="hours"
            value-type="format"
            type="time"
            placeholder="HH:mm"
          ></date-picker>
        </div>
        <div class="col-12 col-md-8 my-2">
          <label for="">{{ $t("eventLimit") }}</label>
          <input type="number" class="form-control" v-model="form.limit" />
          <div class="chekbox-lg mt-2" @click="form.limit = null">
            <label>
              <input
                disabled
                :checked="form.limit === null || form.limit === 0"
                type="checkbox"
              />
              <b
                class="checkmark list"
                :class="{
                  checked: form.limit === null || form.limit === 0,
                }"
              >
              </b>
              <span class="ml-4"> {{ $t("unlimitedLimit") }}</span>
            </label>
          </div>
        </div>
        <div class="col-12 my-2">
          <h5 class="mb-2">{{ $t("hiddenInfoText") }}</h5>
          <vue-editor
            id="editor"
            :placeholder="$t('hiddenInfoContent')"
            :editorToolbar="[
              [{ header: [false, 1, 2, 3, 4, 5, 6] }],
              [{ size: ['small', false, 'large', 'huge'] }],
              ['bold', 'italic', 'underline', 'strike'],
              [
                { align: '' },
                { align: 'center' },
                { align: 'right' },
                { align: 'justify' },
              ],
              ['blockquote', 'code-block'],
              [{ list: 'ordered' }, { list: 'bullet' }, { list: 'check' }],
              [{ color: [] }, { background: [] }],
              ['link'],
              ['clean'],
            ]"
            v-model="form.hiddenInfo"
          >
          </vue-editor>
        </div>
      </div>
    </div>
  </Modal>
</template>

<script>
import Modal from "../Shared/Modal/index.vue";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import "vue2-datepicker/locale/tr";
import { convertH2M, convertM2H } from "gurhan/helpers/Date";
import { VueEditor } from "vue2-editor";

export default {
  components: { Modal, DatePicker, VueEditor },
  data() {
    return {
      showConfirm: false,
      hours: Array.from({ length: 10 }).map((_, i) => i),
      isLoadingRemoveEventItem: false,
      editEventDates: false,
      form: {
        id: -1,
        name: "",
        date: "",
        timeString: "",
        hiddenInfo: "",
        limit: 0,
      },
      alertBox: { status: false, message: "", variant: "success" },
    };
  },
  props: {
    eventDates: {
      default: () => [],
    },
    single: {
      default: false,
      type: Boolean,
    },
    product: {
      default: () => {},
    },
  },
  watch: {
    $route: {
      deep: true,
      handler() {
        this.SET_CATEGORY_ACTION_FROM_QUERY();
      },
    },
    editEventDates(data) {
      if (!data)
        this.$router.replace({
          query: { ...this.$route.query, modal: "closed" },
        });
    },
  },
  methods: {
    notBeforeToday(date) {
      return date < new Date(new Date().setHours(0, 0, 0, 0));
    },
    SET_CATEGORY_ACTION_FROM_QUERY() {
      if (this.$route.query.modal)
        if (this.$route.query.modal.includes("eventDates")) {
          const [, id] = this.$route.query.modal.split("-");
          const form = this.single
            ? this.eventDates
            : this.eventDates.find((s) => s.id === Number(id));
          if (form) {
            this.form = {
              ...form,
              date: new Date(form.date),
              timeString: convertM2H(form.time),
              durationString: convertM2H(form.duration),
              productId: this.product.id,
            };
            this.editEventDates = true;
          } else this.editEventDates = false;
        }
    },
    async SEND_TO_UPDATE_EVENT_ITEM(loading) {
      loading(true);
      let date = new Date(
        new Date(this.form.date).getTime() -
          new Date(this.form.date).getTimezoneOffset() * 60000
      ).toISOString();
      let [d, time] = date.split("T");
      time = time.replace(/^.{5}/g, this.form.timeString).replace("Z", "");
      date = [d, time].join("T");
      const response = await this.$store.dispatch("SEND_TO_UPDATE_EVENT_ITEM", {
        ...this.form,
        date,
        time: convertH2M(this.form.timeString),
        duration: convertH2M(this.form.durationString),
        hiddenInfo: this.form.hiddenInfo,
        expiredAt: this.form.expiredAt,
        productId: this.product.id,
      });
      loading(false);
      this.editEventDates = false;
      setTimeout(() => {
        if (response) this.$emit("refresh");
        if (response) this.$store.commit("SET_EVENT_ITEMS_ARE_LOADING", true);
        setTimeout(() => {
          this.$store.commit("SET_EVENT_ITEMS_ARE_LOADING", false);
        }, 1000);
      }, 2500);
    },
    async SEND_TO_REMOVE_EVENT_ITEM() {
      this.isLoadingRemoveEventItem = true;
      const response = await this.$store.dispatch("SEND_TO_REMOVE_EVENT_ITEM", {
        ...this.form,
        productId: this.product.id,
      });
      this.isLoadingRemoveEventItem = false;
      this.editEventDates = false;
      setTimeout(() => {
        if (response) this.$store.commit("SET_EVENT_ITEMS_ARE_LOADING", true);
        setTimeout(() => {
          this.$store.commit("SET_EVENT_ITEMS_ARE_LOADING", false);
        }, 1000);
      }, 2500);
    },
  },
  created() {
    this.SET_CATEGORY_ACTION_FROM_QUERY();
  },
};
</script>
