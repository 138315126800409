<template>
  <div>
    <SubscriptionSavedCard />
  </div>
</template>

<script>
import SubscriptionSavedCard from "../components/SubscriptionSavedCard/index.vue";
export default {
  components: {
    SubscriptionSavedCard,
  },
};
</script>

<style></style>
