<template>
  <Modal
    v-model="newEventDate"
    ref="newEventDateModal"
    class="text-left"
    title="Yeni Etkinlik Yaratın"
    @ok="SEND_TO_NEW_EVENT_ITEM"
  >
    <div
      v-if="alertBoxRez.status"
      class="alert"
      :class="`alert-${alertBoxRez.variant}`"
    >
      {{ alertBoxRez.message }}
    </div>
    <div
      v-if="alertBox.status"
      class="alert"
      :class="`alert-${alertBox.variant}`"
    >
      {{ alertBox.message }}
    </div>
    <div class="row justify-content-center">
      <div class="col-12 col-md-8 my-2">
        <label for="">{{ $t("eventDate") }} *</label>
        <!-- :disabled-date="notBeforeToday" -->
        <date-picker
          :lang="{
            formatLocale: {
              firstDayOfWeek: 1,
            },
          }"
          class="w-100"
          ref="formDate"
          format="DD.MM.YYYY"
          v-model="form.date"
          type="date"
        ></date-picker>
      </div>
      <div class="col-12 col-md-8 my-2">
        <label for="">{{ $t("eventHour") }} (GMT+3)*</label>
        <date-picker
          v-model="form.timeString"
          :minute-step="15"
          class="w-100"
          format="HH:mm"
          value-type="format"
          type="time"
          placeholder="HH:mm"
        ></date-picker>
      </div>
      <div class="col-12 col-md-8 my-2">
        <label for="">{{ $t("eventDuration") }} *</label>
        <date-picker
          v-model="form.durationString"
          class="w-100"
          :minute-step="15"
          format="HH:mm"
          :hour-options="hours"
          value-type="format"
          type="time"
          placeholder="Saat:Dakika"
        ></date-picker>
        <p class="text-danger">*: {{ $t("fieldIsRequired") }}.</p>
      </div>
      <div class="col-12 col-md-8 my-2">
        <label for="">{{ $t("eventLimit") }}</label>
        <input type="number" class="form-control" v-model="form.limit" />
        <div class="chekbox-lg mt-2" @click="form.limit = null">
          <label>
            <input
              disabled
              :checked="form.limit === null || form.limit === 0"
              type="checkbox"
            />
            <b
              class="checkmark list"
              :class="{
                checked: form.limit === null || form.limit === 0,
              }"
            >
            </b>
            <span class="ml-4"> {{ $t("unlimitedLimit") }}</span>
          </label>
        </div>
      </div>
      <!-- <div class="col-12" v-if="getRezervationRoom.Id !== -1">
        <div class="my-2 col-12 d-flex align-items-center bg-light card">
          <img width="70" src="/images/rez-logo.png" alt="" />
          <div class="p-3">
            <div>
              <small>{{ $t("chooseYesNoButton") }}.</small>
            </div>
            <h6>
              Rezzervasyon sisteminde seçilen etkinlik saati kapatılsın mı?
            </h6>
            <div class="text-right mt-2">
              <button
                @click="fillRezzToRezzarvation = false"
                :class="{ donate: !fillRezzToRezzarvation }"
                class="btn btn-pill mx-1"
              >
                {{ $t("no") }}
              </button>
              <button
                @click="fillRezzToRezzarvation = true"
                :class="{ donate: fillRezzToRezzarvation }"
                class="btn btn-pill mx-1"
              >
                {{ $t("yes") }}
              </button>
            </div>
          </div>
        </div>
        <div class="col-12 alert alert-warning" v-if="!fillRezzToRezzarvation">
          <small>
            * Eğer "Rezzervasyon sisteminde seçilen etkinlik saati kapatılsın
            mı?" sorusu "Hayır" işaretlenirse rezzervasyon sisteminde dolu saat
            olarak GÖRÜNMEZ.
          </small>
        </div>
        <div class="col-12 alert alert-warning" v-else>
          <small
            >* Eğer "Rezzervasyon sisteminde seçilen etkinlik saati kapatılsın
            mı?" sorusu "Evet" işaretlenirse rezzervasyon sisteminde dolu saat
            olarak GÖRÜNÜR. Etkinlik oluşturulmadan seçili tarihin doluluk
            kontrolü yapılır. Eğer uygun bir saat ise Etkinlik
            Oluşturulur.</small
          >
        </div>
      </div> -->
      <div
        class="col-12 bg-light card"
        v-if="getSettings.isSeances === 1 && !fillRezzToRezzarvation"
      >
        Rezzervasyon sistemi bağlantınız bulunmuyor. Bu yüzden oluşturulacak
        etkinlik rezzervasyon sistemine etki etmeyecektir.
      </div>
      <div class="my-2 col-12">
        <GetUserThatOldEvents
          table="event"
          :lastEvent="eventDateList.length ? eventDateList[0] : {}"
          @response="GET_SELECTED_USER_LIST"
          :product="product"
          ref="multipleAddUser"
        />
      </div>
      <div class="col-12 my-2">
        <h5 class="mb-2">
          {{ $t("hiddenInfoText") }}
        </h5>
        <div class="bg-light card">
          {{ $t("shouldEmptyEventHiddenText") }}
        </div>
        <vue-editor
          id="editor"
          :placeholder="$t('hiddenInfoContent')"
          :editorToolbar="[
            [{ header: [false, 1, 2, 3, 4, 5, 6] }],
            [{ size: ['small', false, 'large', 'huge'] }],
            ['bold', 'italic', 'underline', 'strike'],
            [
              { align: '' },
              { align: 'center' },
              { align: 'right' },
              { align: 'justify' },
            ],
            ['blockquote', 'code-block'],
            [{ list: 'ordered' }, { list: 'bullet' }, { list: 'check' }],
            [{ color: [] }, { background: [] }],
            ['link'],
            ['clean'],
          ]"
          v-model="form.hiddenInfo"
        >
        </vue-editor>
      </div>
      <div
        v-if="alertBox.status"
        class="alert"
        :class="`alert-${alertBox.variant}`"
      >
        {{ alertBox.message }}
      </div>
    </div>
  </Modal>
</template>

<script>
import Modal from "../Shared/Modal/index.vue";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import "vue2-datepicker/locale/tr";
import { convertH2M } from "gurhan/helpers/Date";
import { VueEditor } from "vue2-editor";
import GetUserThatOldEvents from "./GetUserThatOldEvents.vue";

export default {
  components: { Modal, DatePicker, VueEditor, GetUserThatOldEvents },
  data() {
    return {
      hours: Array.from({ length: 10 }).map((_, i) => i),
      isLoadingRemoveSection: false,
      fillRezzToRezzarvation: true,
      newEventDate: false,
      form: {
        id: -1,
        date: "",
        timeString: "",
        durationString: "",
        hiddenInfo: "",
        limit: null,
      },
      alertBox: { status: false, message: "", variant: "success" },
      alertBoxRez: { status: false, message: "", variant: "success" },
      eventDateList: [],
      insertUserList: [],
    };
  },
  computed: {
    // rez active room
    getRezervationRoom() {
      return this.$store.state.Rezervation.activeRoom;
    },
    // rez active room
    getSettings() {
      return this.$store.state.User.settings;
    },
  },
  props: {
    eventDates: {
      default: () => [],
    },
    product: {
      default: () => {},
    },
    date: {
      default: null,
    },
  },
  watch: {
    $route: {
      deep: true,
      handler() {
        this.SET_CATEGORY_ACTION_FROM_QUERY();
      },
    },
    newEventDate(data) {
      if (!data)
        this.$router.replace({
          query: { ...this.$route.query, modal: "closed" },
        });
    },
  },
  methods: {
    notBeforeToday(date) {
      return date < new Date(new Date().setHours(0, 0, 0, 0));
    },
    GET_SELECTED_USER_LIST(list) {
      this.insertUserList = list;
    },
    SET_CATEGORY_ACTION_FROM_QUERY() {
      if (this.$route.query.modal)
        if (this.$route.query.modal === "add-eventDate") {
          this.newEventDate = true;
          setTimeout(() => {
            if (this.date) this.form.date = new Date(this.date + " 00:00");
          }, 250);
        }
    },
    async SEND_EMPTY_REZZERVATION() {
      if (!this.fillRezzToRezzarvation) return true;
      const response = await this.$apiForRez.post("Rezervations/Create", {
        statusRezervation: false,
        RoomId: 0,
        CustomerId: null,
        Note: "",
        Price: 0,
        Duration: Number(convertH2M(this.form.durationString)),
        IsCanceled: 0,
        CancelText: null,
        Processes: "",
        RezervationDate: new Date(this.form.date).toLocaleDateString("en-CA"),
        RezervationTime: Number(convertH2M(this.form.timeString)),
      });
      if (response.message === "OK" && response.result === "OK") {
        return true;
      } else if (response.result === "FAIL") {
        this.$store.commit("LOGOUT_REZERVATION");
        return false;
      } else return false;
    },
    async CONTROL_FILL_REZZERVATION() {
      return [];
    },
    async SEND_TO_NEW_EVENT_ITEM(loading) {
      this.alertBoxRez.status = false;
      if (
        this.form.date === "" ||
        this.form.durationString === "" ||
        this.form.timeString === ""
      )
        return;
      loading(true);
      const rezControl = await this.CONTROL_FILL_REZZERVATION();
      if (rezControl.length !== 0) {
        loading(false);
        return (this.alertBox = {
          status: true,
          message: `${this.form.timeString}'dan itibaren ${convertH2M(
            this.form.durationString
          )}dk.lık etkinliğiniz süresince ${
            rezControl.length
          } adet randevunuz çakışmaktadır. Bu yüzden etkinliğinizi oluşturamıyoruz.          `,
          variant: "danger",
        });
      }
      const emptyRezResponse = true; //await this.SEND_EMPTY_REZZERVATION();
      if (!emptyRezResponse)
        this.alertBoxRez = {
          status: true,
          message: "Rezzervasyon sistemine rezzervasyonunuz oluşturulamadı.",
          variant: "danger",
        };
      else {
        let date = new Date(
          new Date(this.form.date).getTime() -
            new Date(this.form.date).getTimezoneOffset() * 60000
        ).toISOString();
        date = date.replace("00:00", this.form.timeString).replace("Z", "");
        const response = await this.$store.dispatch("SEND_TO_NEW_EVENT_ITEM", {
          ...this.form,
          date,
          time: convertH2M(this.form.timeString),
          duration: convertH2M(this.form.durationString),
          limit: this.form.limit || null,
          productId: this.product.id,
          hiddenInfo: this.form.hiddenInfo,
          emptyRezResponse,
          fillRezzToRezzarvation: this.fillRezzToRezzarvation,
        });
        if (response) {
          await this.$refs.multipleAddUser.ADD_USERS_TO_PRODUCT(response.id);
        }
        loading(false);
        this.newEventDate = false;
        setTimeout(async () => {
          if (response) {
            this.$emit("refresh");
            this.$store.commit("SET_EVENT_ITEMS_ARE_LOADING", true);
            this.$router.push(
              `/event-detail/${this.product.id}/event-date/${response.id}`
            );
          }
          setTimeout(() => {
            this.$store.commit("SET_EVENT_ITEMS_ARE_LOADING", false);
          }, 1000);
        }, 2500);
      }
    },
    async GET_LAST_EVENT() {
      const response = await this.$api.get(
        `EventItems?eventId=${this.product.id}&page=1&limit=1`
      );
      if (response.message === "OK") {
        this.eventDateList = response.data;
      } else this.eventDateList = [];
    },
  },
  created() {
    this.SET_CATEGORY_ACTION_FROM_QUERY();
    this.GET_LAST_EVENT();
  },
};
</script>
