<template>
  <div>
    <MyAcademyContentView></MyAcademyContentView>
  </div>
</template>

<script>
import MyAcademyContentView from "../../components/MyAcademy/Content/_type.vue";
export default {
  components: {
    MyAcademyContentView,
  },
};
</script>

<style></style>
