<template>
  <div>
    <MyAcademyContent></MyAcademyContent>
  </div>
</template>

<script>
import MyAcademyContent from "../../components/MyAcademy/Content/index.vue";
export default {
  components: {
    MyAcademyContent,
  },
};
</script>

<style></style>
