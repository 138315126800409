<template>
  <div>
    <MyAcademy />
  </div>
</template>

<script>
import MyAcademy from "../components/MyAcademy/index.vue";
// import Setup from "../components/Setup/index.vue";
export default {
  components: {
    MyAcademy,
    //Setup,
  },
  name: "Home",
};
</script>
