import Vue from "vue";

const vm = Vue.prototype;

export default {
  state: {
    rezzAppActiveUser: {
      loading: true,
      status: false,
      token: "",
    },
  },
  mutations: {
    SET_REZZAPP_ACTIVE_USER(state, payload) {
      state.rezzAppActiveUser = payload;
      vm.$apiForRez.token = payload.token;
    },
  },
  actions: {
    async GET_REZZAPP_INFO({ state, commit }) {
      state.rezzAppActiveUser.loading = true;
      const token = localStorage.getItem("rez_info");
      if (token) {
        const response = await vm.$apiForRez.post("Auth/RefreshToken", {
          token: token,
        });
        if (response.message === "OK") {
          commit("SET_REZZAPP_ACTIVE_USER", {
            status: true,
            ...response.data,
          });
        }
      }
      state.rezzAppActiveUser.loading = false;
    },
  },
};
