<template>
  <Modal
    :title="activeUserUpdate.fullname"
    :hide-footer="!confirmMessage.status"
    :sm="confirmMessage.status"
    :lg="!confirmMessage.status"
    @ok="(e) => confirmMessage.func(e)"
    :okText="$t('submit')"
    :value="value"
    @input="(e) => $emit('input', e)"
  >
    <div
      class="alert"
      :class="`alert-${alertBox.variant}`"
      v-if="alertBox.status"
    >
      {{ alertBox.message }}
    </div>
    <div v-if="confirmMessage.status">
      <div class="text-center mb-5">
        <img :src="confirmMessage.logo" width="110" alt="" />
      </div>
      <p>{{ confirmMessage.message }}</p>
      <h5 v-if="confirmMessage.data === -1" class="my-4">
        {{ $t("chooseCurrency") }}
      </h5>
      <select
        v-if="confirmMessage.data === -1"
        name=""
        v-model="activeUserUpdate.currency"
        id=""
        disabled
        class="w-100 p-2 rounded bg-light card"
      >
        <option v-for="(price, i) in pricesFilter" :key="i" :value="price.type">
          {{ price.label }}
        </option>
      </select>
      <p class="mt-4">
        <strong> {{ $t("doyouwanttoit") }} </strong>
      </p>
    </div>
    <div v-else>
      <p>
        <strong>{{ $t("event") }}:</strong> {{ product.title }}
      </p>
      <p>
        <strong>{{ $t("eventDesc") }}</strong
        >: {{ product.description }}
      </p>
      <slot name="event-date"></slot>
      <hr />
      <h5 class="my-2">
        No:
        <span class="text-primary"> {{ activeUserUpdate.orderNo }}</span>
      </h5>
      <h5 class="my-2">{{ $t("user") }}: {{ activeUserUpdate.fullname }}</h5>
      <div
        v-if="!(getActiveUser.roleId === 3 && getSettings.departmentId === 2)"
      >
        <h5 class="my-2">
          {{ $t("email") }}:
          <a class="email-link" :href="`mailto:${activeUserUpdate.email}`">{{
            activeUserUpdate.email
          }}</a>
        </h5>
        <h5 class="my-2">{{ $t("phone") }}: {{ activeUserUpdate.phone }}</h5>
      </div>
      <!-- <h5 class="my-2">
        Satın Alınan Para Birimi:
        {{ activeUserUpdate.currency }}
      </h5> -->
      <div
        class="alert alert-success"
        v-if="activeUserUpdate.promotion !== null"
      >
        <h6>
          {{ $t("usedPromotionCode") }}. (%{{
            activeUserUpdate.promotion.discount
          }}
          - {{ activeUserUpdate.promotion.code }})
        </h6>
      </div>
      <slot name="purchase-date"></slot>
      <div
        v-if="activeUserUpdate.status === 1"
        class="row justify-content-center payments mb-4"
      >
        <div class="col-12 mb-4">
          <h6>
            {{ $t("youcansendPaymentLink") }}
          </h6>
        </div>
        <div
          class="col-2"
          v-if="getPaymentIyzico.key !== ''"
          @click="
            confirmMessage = {
              status: true,
              message: $t('iyzicoConfirmMessage', {
                email: activeUserUpdate.email,
              }),
              func: SEND_PRODUCT_TO_IYZICO,
              logo: '/images/payments/iyzico.png',
              data: -1,
              type: 'iyzico',
            }
          "
        >
          <img src="/images/payments/iyzico.png" alt="iyzico" />
        </div>
        <div
          class="col-2"
          v-if="
            getPaymentPaypal.key !== '' && activeUserUpdate.currency !== 'TRY'
          "
          @click="
            confirmMessage = {
              status: true,
              message: $tc('paypalConfirmMessage', {
                email: activeUserUpdate.email,
              }),
              func: SEND_PRODUCT_TO_PAYPAL,
              logo: '/images/payments/paypal.png',
              data: -1,
              type: 'paypal',
            };
            currency = 'USD';
          "
        >
          <img src="/images/payments/paypal.png" alt="paypal" />
        </div>
        <div
          v-if="getPaymentMethods.length > 0"
          class="col-2"
          @click="
            confirmMessage = {
              status: true,
              message: $tc('eftConfirmMessage', {
                email: activeUserUpdate.email,
              }),
              func: SEND_PRODUCT_TO_EFT,
              logo: '/images/payments/havale-eft.png',
              data: -2,
              type: 'eft',
            }
          "
        >
          <img src="/images/payments/havale-eft.png" alt="eft-havale" />
        </div>
        <div
          class="col-2"
          v-if="getPaymentWise.length > 0"
          @click="
            confirmMessage = {
              status: true,
              message: $t('wiseConfirmMessage', {
                email: activeUserUpdate.email,
              }),
              func: SEND_PRODUCT_TO_WISE,
              logo: '/images/payments/wise.webp',
              data: -2,
              type: 'wise',
            }
          "
        >
          <img src="/images/payments/wise.webp" alt="wise" />
        </div>
      </div>
      <div class="">
        <hr />
        <h6 class="mb-4" v-if="activeUserUpdate.status !== 2">
          {{ $t("youCanchangeCourseStatus") }}
        </h6>
        <span
          style="font-size: 20px"
          class="badge badge-success"
          :class="{
            'badge-success': activeUserUpdate.status === 2,
            'badge-danger': activeUserUpdate.status === 0,
            'badge-warning': activeUserUpdate.status === 1,
            'badge-warning': activeUserUpdate.status === 3,
            'badge-info': activeUserUpdate.status === 4,
          }"
        >
          {{
            activeUserUpdate.status === 1
              ? getSettings.departmentId === 2
                ? $t("studentWaiting")
                : $t("waitPayment")
              : activeUserUpdate.status === 2
              ? getSettings.departmentId === 2
                ? $t("addedAccountOfStudent")
                : $t("completePayment")
              : activeUserUpdate.status === 0
              ? $t("cancelPayment")
              : activeUserUpdate.status === 3
              ? $t("offerWaiting")
              : activeUserUpdate.status === 4
              ? $t("offered")
              : ""
          }}
        </span>
        <div
          v-if="activeUserUpdate.status === 3 || activeUserUpdate.status === 4"
          :class="`my-4 alert alert-${
            activeUserUpdate.status === 3 ? 'warning' : 'info'
          } vid-title-sec`"
        >
          <h5 v-if="activeUserUpdate.status === 4">
            {{ $t("youOfferedToStudent") }}
          </h5>
          <h5 v-else>{{ $t("theStudentRequestedToGetOffer") }}</h5>
          <p class="mt-3" v-if="activeUserUpdate.status === 4">
            {{ $t("youOfferedProductToStudent", { product: product.title }) }}
          </p>
          <p class="mt-3" v-else>
            {{
              $t("theStudentRequestedToGetOfferDescription", {
                product: product.title,
              })
            }}
          </p>
          <form>
            <div class="row align-items-center mt-4 justify-content-center">
              <div class="col-12 col-md-4 col-lg-4 card border-white p-4">
                <h3 class="mb-2">{{ $t("giveOffer") }}</h3>
                <div class="mb-4">
                  <strong>{{ $t("currency") }}: </strong>
                  {{ activeUserUpdate.currency }}
                </div>
                <div class="vid-title">
                  <h2 class="title-hd">
                    {{ $t("enterProductPrice") }} ({{
                      activeUserUpdate.currency
                    }}) *
                  </h2>
                  <div class="form_field">
                    <input
                      required
                      v-model="activeUserUpdate.price"
                      type="number"
                      :min="0"
                    />
                  </div>
                </div>
                <div class="vid-title mt-4">
                  <h2 class="title-hd">
                    {{ $t("enterProductDiscount") }} ({{
                      activeUserUpdate.currency
                    }}) *
                  </h2>
                  <div class="form_field">
                    <input
                      required
                      v-model="activeUserUpdate.discount"
                      type="number"
                      :min="0"
                      :max="100"
                      :placeholder="$t('discountPer')"
                    />
                  </div>
                </div>
                <div>
                  <button
                    :disabled="loadingUpdate"
                    @click="
                      confirmMessage = {
                        status: true,
                        message: $t('youGonnaGetOffer', {
                          email: activeUserUpdate.email,
                        }),
                        func: SEND_UPDATE_USER_PRODUCT,
                        logo: getSettings.logo,
                        data: 4,
                      }
                    "
                    :class="{ 'bg-info': activeUserUpdate.status === 4 }"
                    class="btn donate btn-pill mt-4 w-100"
                  >
                    <loading v-if="loadingUpdate"></loading>
                    <span v-else>
                      {{
                        activeUserUpdate.status === 4
                          ? $t("updateOffer")
                          : $t("giveOffer")
                      }}</span
                    >
                  </button>
                </div>
              </div>
            </div>
            <p
              class="text-center my-3"
              v-html="$t('WarnOffer', { currency: activeUserUpdate.currency })"
            ></p>
          </form>
        </div>
        <div class="my-4">
          <h6 class="text-left my-3">
            {{ $t("youCanchangeCourseStatus") }}
          </h6>
          <button
            :disabled="loadingUpdate"
            @click="
              confirmMessage = {
                status: true,
                message: $t('paymentWillCompletedMessage', {
                  email: activeUserUpdate.email,
                }),
                func: SEND_UPDATE_USER_PRODUCT,
                logo: getSettings.logo,
                data: 2,
              }
            "
            class="btn mx-2 btn-pill donate bg-success"
          >
            <loading v-if="loadingUpdate"></loading>
            <span v-else-if="getSettings.departmentId === 2">
              {{ $t("addAccountOfStudent") }}
            </span>
            <span v-else> {{ $t("completePayment") }}</span>
          </button>
          <button
            :disabled="loadingUpdate"
            @click="
              confirmMessage = {
                status: true,
                message: $t('paymentWillWaitingMessage', {
                  email: activeUserUpdate.email,
                }),
                func: SEND_UPDATE_USER_PRODUCT,
                logo: getSettings.logo,
                data: 1,
              }
            "
            class="btn mx-2 btn-pill btn-warning"
          >
            <loading v-if="loadingUpdate"></loading>
            <span v-else-if="getSettings.departmentId === 2">
              {{ $t("studentWait") }}
            </span>
            <span v-else> {{ $t("waitPayment") }} </span>
          </button>
          <button
            :disabled="loadingUpdate"
            @click="
              confirmMessage = {
                status: true,
                message: $t('paymentWillCanceledMessage', {
                  email: activeUserUpdate.email,
                }),
                func: SEND_UPDATE_USER_PRODUCT,
                logo: getSettings.logo,
                data: 0,
              }
            "
            class="btn mx-2 btn-pill btn-danger"
          >
            <loading v-if="loadingUpdate"></loading>
            <span v-else> {{ $t("cancelPayment") }} </span>
          </button>
          <button
            v-if="product.isOffer === 1"
            :disabled="loadingUpdate"
            @click="
              confirmMessage = {
                status: true,
                message: $t('offerWillbeActiveToStudent'),
                func: SEND_UPDATE_USER_PRODUCT,
                logo: getSettings.logo,
                data: 3,
              }
            "
            class="btn mx-2 btn-pill btn-info"
          >
            <loading v-if="loadingUpdate"></loading>
            <span v-else> {{ $t("activatedToOffer") }} </span>
          </button>
        </div>
        <div
          class="my-4 alert alert-light"
          v-if="getSettings.departmentId !== 2"
        >
          <p>
            <strong class="text-success"> {{ $t("completePayment") }} :</strong>
            {{ $t("completePaymentDescription") }}
          </p>
          <p>
            <strong class="text-warning"> {{ $t("waitPayment") }} :</strong>
            {{ $t("waitPaymentDescription") }}
          </p>
          <p>
            <strong class="text-danger"> {{ $t("cancelPayment") }} :</strong>
            {{ $t("cancelPaymentDescription") }}
          </p>
        </div>
      </div>
    </div>
  </Modal>
</template>

<script>
import Modal from "../Shared/Modal";
import { Currency } from "gurhan/helpers/Currency";
export default {
  components: { Modal },
  props: {
    value: {
      default: false,
    },
    product: {
      default: () => {},
    },
    activeUserUpdate: {
      default: () => ({
        promotion: {},
      }),
    },
  },
  watch: {
    value() {
      this.confirmMessage.status = false;
      this.alertBox.status = false;
    },
  },
  data() {
    return {
      currency: "TRY",
      loadingUpdate: false,
      confirmMessage: { status: false, message: "", func: () => {}, data: -1 },
      alertBox: {
        status: false,
        message: "",
        variant: "",
      },
      prices: [
        { type: "TRY", label: "Türk Lirası (₺)", isPaypal: 0 },
        { type: "USD", label: "Dolar ($)", isPaypal: 1 },
        { type: "USD", label: "Euro (€)", isPaypal: 1 },
      ],
    };
  },
  computed: {
    getSettings() {
      return this.$store.state.User.settings;
    },
    getActiveUser() {
      return this.$store.state.User.activeUser;
    },
    pricesFilter() {
      if (this.confirmMessage.type === "paypal")
        return this.prices.filter((p) => p.isPaypal === 1);
      else return this.prices;
    },
    getPaymentMethods() {
      return this.$store.state.PaymentMethods.paymentMethods.filter(
        (f) => f.type === 3 && f.iban.length > 8
      );
    },
    getPaymentWise() {
      return this.$store.state.PaymentMethods.paymentMethods.filter(
        (f) => f.type === 4
      );
    },
    getPaymentIyzico() {
      return (
        this.$store.state.PaymentMethods.paymentMethods.find(
          (f) => f.type === 1
        ) || { id: -1, key: "" }
      );
    },
    getPaymentPaypal() {
      return (
        this.$store.state.PaymentMethods.paymentMethods.find(
          (f) => f.type === 2
        ) || { id: -1, key: "" }
      );
    },
  },
  methods: {
    SEND_PRODUCT_TO_IYZICO(l) {
      this.SEND_TO_BUY_COURSE(1, this.product.type.typeTable, l);
    },
    SET_PAYMENTS() {
      let data = "";
      for (let i = 0; i < this.getPaymentMethods.length; i++) {
        const element = this.getPaymentMethods[i];
        data =
          data +
          `<tr>
                <td  style="border: 1px solid #ddd;">${element.bankName}</td>
                <td  style="border: 1px solid #ddd;">${element.receiverName}</td>
                <td  style="border: 1px solid #ddd;">${element.iban}</td>
              </tr>`;
      }
      return data;
    },
    SEND_PRODUCT_TO_EFT() {
      this.$store.commit("SET_MAIL_DRAWER", {
        status: true,
        form: {
          to: this.activeUserUpdate.email,
          bcc: "",
          subject: this.$t("aboutBoughtProduct", {
            title: this.product.title,
            typeTitle: this.product.type.title,
          }),
          content: this.$t("eftPurchase", {
            fullname: this.activeUserUpdate.fullname,
            academyName: this.getSettings.title,
            productType: this.product.type.title,
            productTitle: this.product.title,
            orderNo: this.activeUserUpdate.orderNo,
            productPrice: this.product[`price${this.currency}`],
            discount: this.product[`discount${this.currency}`],
            paymentDetails: this.SET_PAYMENTS(), // IBAN bilgileri için tablo verisi
          }),
          files: [],
        },
      });
      this.$emit("input", false);
      // this.SEND_TO_BUY_COURSE(3, this.product.type.typeTable, l);
    },
    SEND_PRODUCT_TO_PAYPAL(l) {
      this.SEND_TO_BUY_COURSE(2, this.product.type.typeTable, l);
    },
    SEND_PRODUCT_TO_WISE(l) {
      this.SEND_TO_BUY_COURSE(4, this.product.type.typeTable, l);
    },
    async SEND_UPDATE_USER_PRODUCT(loading) {
      this.alertBox.status = false;
      loading(true);
      const response = await this.$api.put(
        `Products/UpdateStatus?table=${this.product.type.name}`,
        {
          userId: this.activeUserUpdate.id,
          productId: this.product.id,
          status: this.confirmMessage.data,
          price: this.activeUserUpdate.price,
          discount: this.activeUserUpdate.discount,
        }
      );
      if (response.message === "OK") {
        this.$emit("input", false);
        this.$emit("refresh");
      } else {
        this.alertBox = {
          status: true,
          message: this.$t("somethingWentWrong"),
          variant: "danger",
        };
      }
      loading(false);
    },
    SET_PRODUCT_PRICE() {
      if (this.activeUserUpdate.promotion !== null) {
        const fp =
          ((100 - this.product[`discount${this.activeUserUpdate.currency}`]) *
            this.product[`price${this.activeUserUpdate.currency}`]) /
          100;
        return ((100 - this.activeUserUpdate.promotion.discount) * fp) / 100;
      } else
        return (
          ((100 - this.product[`discount${this.activeUserUpdate.currency}`]) *
            this.product[`price${this.activeUserUpdate.currency}`]) /
          100
        );
    },
    async SEND_TO_BUY_COURSE(paymentType, productType, loading) {
      this.alertBox.status = false;
      loading(true);
      const response = await this.$api.post(
        `Products/Pay?userId=${this.activeUserUpdate.id}`,
        {
          productId: this.product.id,
          price: this.SET_PRODUCT_PRICE(),
          discount: this.product[`discount${this.activeUserUpdate.currency}`],
          paymentType,
          productType,
          currency: this.activeUserUpdate.currency,
        }
      );
      if (response.message === "OK") {
        this.$store.commit("SET_MAIL_DRAWER", {
          status: true,
          form: {
            to: this.activeUserUpdate.email,
            bcc: "",
            subject: this.$t("aboutBoughtProduct", {
              title: this.product.title,
              typeTitle: this.product.type.title,
            }),
            content: this.$t("paymentLinkEmail", {
              fullname: this.activeUserUpdate.fullname,
              academyName: this.getSettings.title,
              productType: this.product.type.title,
              productTitle: this.product.title,
              currencyPrice: Currency(
                {
                  price: this.product[`price${this.activeUserUpdate.currency}`],
                },
                this.product.currency
              ),
              discount: this.product[`discount${this.currency}`],
              totalPrice: Currency(
                { price: this.SET_PRODUCT_PRICE() },
                this.product.currency
              ),
              paymentLink: response.data, // Ödeme linki
            }),
            files: [],
          },
        });
        this.$emit("input", false);

        // location.href = response.data;
      } else if (response.message === "CODE ALREADY USED") {
        this.alertBox = {
          status: true,
          variant: "danger",
          message: this.$t("codeAlreadyUsed"),
        };
      } else {
        this.alertBox = {
          status: true,
          variant: "danger",
          message: this.$t("somethingWentWrong"),
        };
      }
      loading(false);
    },
  },
  created() {
    this.confirmMessage.status = false;
    this.alertBox.status = false;
  },
};
</script>

<style></style>
