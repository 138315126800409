<template>
  <div>
    <div v-if="getPageIsClosed">
      <PageIsClosed />
    </div>
    <main v-else>
      <TobBar />
      <aside
        @mouseenter="expanded = true"
        @mouseleave="expanded = false"
        :class="{ opened: expanded }"
      >
        <new-side-menu
          :expanded="expanded"
          @toggle:menu="expanded = $event"
        ></new-side-menu>
      </aside>
      <div :class="{ opened: expanded }" class="content" id="content">
        <header>
          <div class="d-lg-none px-2">
            <img
              class="cursor-pointer mx-2"
              @click="$router.go(-1)"
              src="/images/back-button.svg"
              width="25"
              alt="back"
            />

            <img
              @click="expanded = true"
              class="forward-icon d-lg-none px-3"
              src="/images/menu.png"
              width="60"
              alt=""
            />
          </div>
          <h4 class="d-none d-lg-block" v-if="$route.name === 'Home'">
            <span @click="$tours['myTour'].start()"
              ><i class="icon-features"></i
            ></span>
            {{ $t("hi") }}, {{ getActiveUser.fullname }}!
          </h4>
          <div v-else>
            <h4 class="d-none d-lg-block">
              <img
                class="cursor-pointer mr-2"
                @click="$router.go(-1)"
                src="/images/back-button.svg"
                width="25"
                alt="back"
              />
              <img src="/android-chrome-192x192.png" width="32" alt="" />
              <strong> {{ getSettings.title }}</strong>
            </h4>
          </div>
          <div class="right-side">
            <a
              class="d-none d-lg-inline"
              :href="`https://${getSettings.website}`"
              target="_blank"
            >
              {{ $t("goToAcademy") }}
            </a>
            <router-link
              to="/packages"
              class="text-primary mx-2 d-none d-lg-inline"
            >
              {{ $t("upgradeAccount.button") }}
            </router-link>
            <router-link
              to="/new-product"
              id="v-step-l-h-4"
              title=""
              class="btn-pill btn btn-primary btn-sm"
              >+ {{ $t("uploadProduct") }}</router-link
            >
            <div class="dropdown">
              <img
                :src="$api.image(getActiveUser.imagePath)"
                @error="(e) => (e.target.src = getSettings.defaultImage)"
                alt=""
                class="avatar"
              />
              <div class="dropdown-content">
                <div>
                  <router-link to="/profile">
                    <div>
                      <i class="icon-user"></i> {{ getActiveUser.fullname }}
                    </div>
                    <div>{{ getActiveUser.email }}</div>
                  </router-link>
                </div>
                <div class="mt-4">
                  <hr />
                  <a :href="`https://${getSettings.website}`" target="_blank">
                    <li class="my-2">
                      <span class="px-1">
                        <i class="icon-location"></i>
                      </span>
                      {{ $t("goToAcademy") }}
                    </li>
                  </a>
                  <router-link to="/my-subscription">
                    <li class="my-2">
                      <span class="px-1">
                        <i class="icon-subscriptions"></i>
                      </span>
                      {{ $t("mySubscription") }}
                    </li>
                  </router-link>
                  <router-link to="/profile?push=language">
                    <li class="my-2">
                      <span class="px-1">
                        <i class="icon-language"></i>
                      </span>
                      {{ $t("languageTitle") }}
                    </li>
                  </router-link>
                  <router-link to="/packages">
                    <li class="my-2">
                      <span class="px-1">
                        <i class="icon-features"></i>
                      </span>
                      {{ $t("upgradeAccount.button") }}
                    </li>
                  </router-link>
                  <li
                    @click="
                      $store.commit('SET_LOGOUT', {
                        variant: 'success',
                        message: 'sessionDestroyed',
                      })
                    "
                  >
                    <span>
                      <i class="icon-logout"></i>
                    </span>
                    <a href="javascript:void(0)" title="">{{ $t("logout") }}</a>
                  </li>
                </div>
              </div>
            </div>
          </div>
        </header>
        <div class="container">
          <router-view v-if="!getActivePlanLoading" class="card"></router-view>
        </div>
      </div>
      <ModalLoading></ModalLoading>
      <VideoIsUploading v-if="getVideoIsUploading"></VideoIsUploading>
      <MailDrawer></MailDrawer>
      <BottomBar />
    </main>
  </div>
</template>

<script>
import Auth from "../mixins/Auth";
import CheckConfirmationDateOfSubscription from "../mixins/CheckConfirmationDateOfSubscription";
import ModalLoading from "../components/Shared/ModalLoading.vue";
import VideoIsUploading from "../components/Product/VideoIsUploading.vue";
import MailDrawer from "../components/Shared/MailDrawer.vue";
import ListenPostMessage from "../mixins/ListenPostMessage";
import BottomBar from "../components/Shared/BottomBar.vue";
import NewSideMenu from "../components/Shared/SideMenu/NewSideMenu.vue";
import PageIsClosed from "../components/PageIsClosed/index.vue";
import TobBar from "../components/Shared/TopBar.vue";
export default {
  components: {
    TobBar,
    PageIsClosed,
    ModalLoading,
    BottomBar,
    VideoIsUploading,
    MailDrawer,
    NewSideMenu,
  },
  mixins: [Auth, ListenPostMessage, CheckConfirmationDateOfSubscription],
  data() {
    return {
      expanded: false,
    };
  },
  computed: {
    getVideoIsUploading() {
      return this.$store.state.Products.videoIsUploading;
    },
    getActiveUser() {
      return this.$store.state.User.activeUser;
    },
    getSettings() {
      return this.$store.state.User.settings;
    },
    getCategories() {
      return this.$store.state.Category.categories;
    },
    categoriesAreLoading() {
      return this.$store.state.Category.categoriesAreLoading;
    },
    getPageIsClosed() {
      return this.$store.state.User.pageIsClosed;
    },
    getActivePlanLoading() {
      return this.$store.state.User.activePlan.loading;
    },
  },
  methods: {
    SET_TOGGLE_MENU(toggle = true) {
      const detec = this.expanded;
      this.expanded = toggle ? !this.expanded : false;
      if (this.expanded) this.LISTEN_BODY_CLICK();
      else if (detec) document.removeEventListener("click", () => {});
    },
    LISTEN_BODY_CLICK() {
      const vm = this;
      document.body.addEventListener("click", (e) => {
        let isSideMenu = false;
        var element = e.target;
        while (element.parentNode) {
          element.parentNode.classList !== undefined
            ? element.parentNode.classList.contains("side_menu") ||
              element.parentNode.classList.contains("menu")
              ? (isSideMenu = true)
              : ""
            : "";
          element = element.parentNode;
        }
        if (!isSideMenu) vm.SET_TOGGLE_MENU(false);
      });
    },
  },
  mounted() {
    this.$store.dispatch("GET_PAYMENT_METHODS");
  },
};
</script>

<style>
.dropdown {
  position: relative;
}
.dropdown-content {
  position: absolute;
  display: none;
  z-index: 1000;
  top: 40px;
  right: 0;
  width: 250px;
  background: white;
  padding: 10px;
  border-radius: 13px;
}
.dropdown:hover .dropdown-content {
  display: block;
  border: 1px solid #dedede;
}
</style>
