<template>
  <div class="card">
    <div class="card-body">
      <div class="d-flex justify-content-between align-items-center">
        <div class="d-flex justify-content-start align-items-center">
          <span class="d-none d-md-flex avatar-text" v-if="assignment.title">{{
            assignment.title.charAt(0)
          }}</span>
          <span v-else class="avatar-text">
            <i class="icon-user"></i>
          </span>
          <div class="ml-4">
            <h4>{{ assignment.title }}</h4>
            <p>
              {{ formatter(new Date(assignment.createdAt)) }} -
              {{ formatter(new Date(assignment.expiredAt)) }}
            </p>
          </div>
        </div>
        <div>
          <a target="_blank" :href="$api.image(assignment.filePath)">
            <button class="btn btn-pill donate m-1">
              {{ $t("viewFile") }}
            </button></a
          >
          <button
            :title="$t('update')"
            class="btn m-1 btn-pill btn-xs btn-warning mx-1"
            @click="SHOW_ASSIGNMENT_DETAIL(assignment)"
          >
            <img src="/images/edit.svg" width="20" alt="" />
          </button>
          <button
            :title="$t('remove')"
            @click="removeFile = { status: true, index: 0, id: assignment.id }"
            class="btn m-1 btn-pill btn-xs btn-danger mx-1 btn-icon"
          >
            <img src="/images/trush.svg" width="20" alt="" />
          </button>
        </div>
      </div>
      <hr />
      <div class="mt-4 user-detail-header">
        <p>{{ assignment.description }}</p>
      </div>
    </div>
    <Modal hide-footer lg v-model="showEditFileModal">
      <AssignmentFileModal
        :product="assignment.type === 1 ? assignment : {}"
        edit
        :key="editModalKey"
        :event="assignment.type === 2 ? assignment : {}"
        :video="assignment.type === 3 ? assignment : {}"
        :type="assignment.type"
        :item="assignment"
        @close="showEditFileModal = false"
        @refresh="REFRESH_PAGE"
      ></AssignmentFileModal>
    </Modal>
    <Modal
      @ok="SET_REMOVE_FILE(assignment.returnPath)"
      :okText="$t('remove')"
      persistent
      sm
      v-model="removeFile.status"
    >
      {{ $t("confirmDeleteModalText") }}
    </Modal>
  </div>
</template>

<script>
import Assignment from "../Assignments/index";
export default {
  mixins: [Assignment],
  data() {
    return {
      removeFile: { status: false },
      showEditFileModal: false,
      editModalKey: 1,
    };
  },
  props: {
    assignment: {
      default: () => {
        return {
          fullname: "",
        };
      },
    },
  },
  methods: {
    RETURN_PATH() {
      this.$router.push(this.assignment.returnPath);
    },
    REFRESH_PAGE(variant, content, form) {
      this.SET_ALERT_BOX(variant, content);
      this.showNewFileModal = false;
      this.showEditFileModal = false;
      this.$emit("refresh", form);
    },
  },
};
</script>

<style></style>
