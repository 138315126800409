<template>
  <div class="row">
    <div class="col-12 mb-4">
      <button
        @click="setLang(lang, true)"
        class="btn btn-xs"
        v-for="(lang, i) in langs"
        :class="{ donate: lang === form.language }"
        :key="i"
      >
        {{ lang }}
      </button>
    </div>
    <div
      class="col-12 col-lg-6 d-flex align-items-center justify-content-center"
    >
      <div class="text-center mt-4">
        <img
          :src="
            changedImage.image ||
            $apiHeocademy.image(form.imagePath) ||
            getSettings.logo
          "
          @error="(e) => (e.target.src = getSettings.logo)"
          alt=""
          width="100%"
        />
        <div class="mt-4">
          <input
            class="d-none"
            type="file"
            ref="file"
            accept="image/*"
            @change="(e) => uploadImage(e, 900)"
            id=""
          />
          <button
            @click="$refs.file.click()"
            class="ml-4 btn btn-pill subscribe"
          >
            <span>{{ $t("upload") }}</span>
          </button>
        </div>
      </div>
    </div>
    <div class="col-12 col-lg-6">
      <div class="bg-light p-3 my-3 row align-items-center" v-if="form.id">
        <div class="col-12 col-lg-8">{{ $t("removeContent") }}</div>
        <div class="col-12 col-lg-4">
          <button
            @click="showRemoveModal = true"
            class="bg-danger text-white btn btn-pill"
          >
            {{ $t("remove") }}
          </button>
        </div>
      </div>
      <form @submit="SUBMIT_CONTENT">
        <div class="vid-title mb-4" v-if="fields[0]">
          <h2 class="title-hd">{{ $t(fields[0]) }}</h2>
          <div class="form_field">
            <input type="text" required v-model="form.title" />
          </div>
        </div>
        <div class="vid-title mb-4" v-if="fields[1]">
          <h2 class="title-hd">{{ $t(fields[1]) }}</h2>
          <div class="form_field">
            <textarea
              type="text"
              rows="4"
              required
              v-model="form.description"
            />
          </div>
        </div>
        <div class="vid-title mb-4" v-if="fields[2]">
          <h2 class="title-hd">{{ $t(fields[2]) }}</h2>
          <div class="form_field">
            <vue-editor
              v-if="fields[2].includes('HTML')"
              id="editor"
              :editorToolbar="[
                [{ header: [false, 1, 2, 3, 4, 5, 6] }],
                [{ size: ['small', false, 'large', 'huge'] }],
                ['bold', 'italic', 'underline', 'strike'],
                [
                  { align: '' },
                  { align: 'center' },
                  { align: 'right' },
                  { align: 'justify' },
                ],
                ['blockquote', 'code-block'],
                [{ list: 'ordered' }, { list: 'bullet' }, { list: 'check' }],
                [{ color: [] }, { background: [] }],
                ['link'],
                ['clean'],
              ]"
              v-model="form.overview"
            >
            </vue-editor>
            <textarea
              v-else
              type="text"
              rows="4"
              required
              v-model="form.overview"
            />
          </div>
        </div>
        <div class="vid-title mb-4" v-if="fields[3]">
          <h2 class="title-hd">{{ $t(fields[3]) }}</h2>
          <div
            class="my-4 text-center text-muted"
            v-if="degrees.length === 0 && ['certificates'].includes(fields[3])"
          >
            <small> {{ $t(`no${fields[3]}`) }}</small>
          </div>
          <ul v-if="['certificates'].includes(fields[3])">
            <li
              v-for="(degree, i) in degrees"
              :key="i"
              class="d-flex justify-content-between"
            >
              <input type="text" class="w-100" required v-model="degree.name" />
              <button @click.prevent="degrees.splice(i, 1)" class="btn btn-xs">
                <i class="icon-cancel"></i>
              </button>
            </li>
          </ul>
          <div class="vid-title mb-4" v-else>
            <div class="form_field">
              <input type="number" required v-model="form.degrees" />
            </div>
          </div>
          <div class="mt-4" v-if="['certificates'].includes(fields[3])">
            <button
              @click.prevent="degrees.push({ name: '' })"
              class="btn w-100 btn-pill donate"
            >
              + {{ $t("add") }}
            </button>
          </div>
        </div>
        <button @submit="SUBMIT_CONTENT" class="w-100 btn btn-pill subscribe">
          <span>{{ form.id ? $t("update") : $t("add") }}</span>
        </button>
      </form>
    </div>
    <Modal
      @ok="contentDelete(form.id)"
      :okText="$t('remove')"
      persistent
      sm
      v-model="showRemoveModal"
    >
      {{ $t("removeContentModal") }}
    </Modal>
  </div>
</template>

<script>
import actionMixin from "./action.mixin";
import Modal from "../../Shared/Modal/index.vue";
import { VueEditor } from "vue2-editor";

export default {
  mixins: [actionMixin],
  components: {
    VueEditor,
    Modal,
  },
  props: {
    items: {
      default: () => [],
    },
    fields: {
      default: () => [],
    },
    forceProps: {
      default: () => ({
        language: "",
        title: "",
        description: "",
        overview: "",
        degrees: "",
      }),
    },
  },
  computed: {
    getSettings() {
      return this.$store.state.User.settings;
    },
  },
  data() {
    return {
      showRemoveModal: false,
      langs: ["TR", "EN", "DE", "KG", "KZ"],
      degrees: [],
      form: {
        title: "",
        description: "",
        degrees: "",
        imagePath: null,
        language: null,
      },
    };
  },
  methods: {
    setLang(lang, force = false) {
      this.form =
        this.items.length === 1 && !force
          ? this.items[0]
          : this.items.find((item) => item.language === lang) || {
              title: "",
              description: "",
              imagePath: null,
              language: lang,
            };
      this.$router.replace({ query: { ...this.$route.query, lang } });
      if (this.form.degrees && ["certificates"].includes(this.fields[3])) {
        // <ul class='lab-ul'><li>Bursa Anadolu Kız Meslek Lisesi - Tekstil/2015</li><li>Stil Danışmanlığı/Renk Analizi</li></ul>
        const removeHtml = this.form.degrees
          .replace("<ul class='lab-ul'>", "")
          .replace("</ul>", "")
          .replace(/<\/li>/g, "");
        if (removeHtml !== "-")
          this.degrees = removeHtml
            .split("<li>")
            .map((r) => ({ name: r }))
            .filter((r) => r.name);
      }
    },
  },
  mounted() {
    const query = this.$route.query;
    this.setLang(query.lang || this.langs[0]);
  },
};
</script>

<style></style>
