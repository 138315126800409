import Modal from "../../Shared/Modal/index.vue";
import QuestionModal from "./QuestionModal.vue";
import Alertbox from "../../../mixins/AlertBox";
import { formatter } from "gurhan/helpers/Date";
export default {
  mixins: [Alertbox],
  components: { Modal, QuestionModal },
  methods: {
    formatter,
    async SET_REMOVE_FILE(returnPath = "") {
      this.SET_LOADING_MODAL(true);
      const response = await this.$api.delete(
        `QuizQuestions/${this.removeQuestion.id}`
      );
      if (response.message === "OK" && response.result === "OK") {
        this.SET_ALERT_BOX("success", this.$t("updatedIsSucceed"));
        if (returnPath === "") this.items.splice(this.removeQuestion.index, 1);
        else this.$router.push(returnPath);
        this.removeQuestion.status = false;
      } else {
        this.SET_ALERT_BOX("danger", this.$t("updatedIsNotSucceed"));
      }
    },
    GET_CORRECT_ANSWER(item) {
      const result = item.questionAnswers.find(
        (data) => data.id === item.correctAnswerId
      );
      if (result) return this.$t("answer") + ": " + result.answerText;
      else return this.$t("noAnswer");
    },
    SET_PAGINATION(page) {
      this.page = page;
      this.$router.replace({ query: { ...this.$route.query, page } });
      this.GET_QUESTION_ITEMS(this.quiz.id);
    },
    REFRESH_COMPONENT() {
      // this.SET_ALERT_BOX(variant, content);
      this.SET_FILE_TYPE_BEFORE_GET_QUESTION();
    },
    SHOW_QUESTION_DETAIL(item) {
      this.selectedQuestion = item;
      this.editModalKey += 1;
      this.showEditQuestionModal = true;
    },
    async GET_QUESTION_ITEMS(quizId) {
      this.loading = true;
      const response = await this.$api.get(
        `QuizQuestions?quizId=${quizId}&page=${this.page}&limit=10`
      );
      if (response.message === "OK") {
        this.items = response.data.items;
        this.count = response.data.count;
      } else this.items = [];
      this.loading = false;
    },
    SET_FILE_TYPE_BEFORE_GET_QUESTION() {
      this.GET_QUESTION_ITEMS(this.quiz.id);
    },
    GET_PAGE_FROM_QUERY() {
      if (!isNaN(Number(this.$route.query?.page)))
        this.page = Number(this.$route.query?.page);
    },
  },
};
