<template>
  <div class="container mt-4">
    <h2>
      {{ $t("users") }}
      <span class="text-muted">{{ count }}</span>
    </h2>
    <h5 class="text-muted mt-2 mb-4">{{ $t("viewCourseStudents") }}</h5>
    <div class="text-right" v-if="product.type.type !== 3">
      <button
        @click="
          modalKey += 1;
          showActiveUserInsertModal = true;
        "
        class="btn btn-pill donate"
      >
        + {{ $t("addProductToUser") }}
      </button>
    </div>
    <div
      class="row justify-content-end mt-4"
      v-if="selectedAllItemWithoutPage || checkedList.length !== 0"
    >
      <div class="col-12 col-md-6 mt-2 col-lg-3">
        <export-excel
          :fields="fields"
          :name="`Heocademy_${checkedList.length} ${$t('users')}: ${
            product.title
          }.${getExcelName}`"
          ref="excel"
          :data="checkedList"
          :type="`${getExcelName}`"
        >
          <div></div>
        </export-excel>
        <button
          @click="GET_ALL_USER_EMAIL(false, true)"
          v-if="selectedAllItemWithoutPage"
          class="bg-success text-white btn w-100 d-block p-2 btn-pill d-flex align-items-center justify-content-center"
        >
          ({{ count }}) {{ $t("exportExcell") }}
        </button>
        <button
          @click="SET_CHECK_LIST_FOR_EXCEL(checkedList)"
          v-else-if="checkedList.length !== 0"
          class="bg-success text-white btn w-100 d-block p-2 btn-pill d-flex align-items-center justify-content-center"
        >
          ({{ checkedList.length }}) {{ $t("exportExcell") }}
        </button>
      </div>
      <div class="col-12 col-md-6 mt-2 col-lg-3">
        <button
          @click="GET_ALL_USER_EMAIL(false)"
          v-if="selectedAllItemWithoutPage"
          class="subscribe w-100 d-block p-2 btn-pill d-flex align-items-center justify-content-center"
        >
          ({{ count }}) {{ $t("sendMail") }}
        </button>
        <button
          @click="
            $store.commit('SET_MAIL_DRAWER', {
              status: true,
              form: {
                to: '',
                bcc: SET_CHECK_LIST(checkedList),
                subject: $t('aboutBoughtProduct', {
                  typeTitle: product.type.title,
                  productTitle: product.title,
                }),
                content: '',
                files: [],
              },
            })
          "
          v-else-if="checkedList.length !== 0"
          class="subscribe w-100 d-block p-2 btn-pill d-flex align-items-center justify-content-center"
        >
          ({{ checkedList.length }}) {{ $t("sendMail") }}
        </button>
      </div>
      <div class="col-12 col-md-6 mt-2 col-lg-3">
        <button
          @click="GET_ALL_USER_EMAIL(true)"
          v-if="selectedAllItemWithoutPage"
          class="btn text-white bg-info w-100 d-block p-2 btn-pill d-flex align-items-center justify-content-center"
        >
          ({{ count }})
          {{ $t("updateUser") }}
        </button>
        <button
          @click="UPDATE_USER_STATUS(checkedList)"
          v-if="checkedList.length !== 0 && !selectedAllItemWithoutPage"
          class="btn text-white bg-info w-100 d-block p-2 btn-pill d-flex align-items-center justify-content-center"
        >
          ({{ checkedList.length }}) {{ $t("updateUser") }}
        </button>
      </div>
    </div>
    <Tables
      @change:search="SET_SEARCH"
      :loading="usersAreLoading"
      :count="count"
      @change:selectAllWithoutPage="SET_SELECT_ALL_WITHOUT_PAGE"
      @change:checkedList="(e) => (checkedList = e)"
      checkable
      :headers="headers"
      checkedKey="purchasedAt"
      :items="userList"
    >
      <template #fullname="{ item }">
        <div>
          <span>
            <a class="email-link" :href="`mailto:${item.email}`">{{
              item.email
            }}</a></span
          >
        </div>
        <div>
          <span>
            <a class="tel-link" :href="`tel:${item.phone}`">{{
              item.phone
            }}</a></span
          >
        </div>
      </template>
      <template #orderNo="{ item }">
        {{ item.orderNo }}
        <div>
          <strong>
            <router-link class="tel-link" :to="`/user/${item.id}`">
              <i class="icon-user"></i> {{ item.fullname }}</router-link
            >
          </strong>
        </div>
      </template>
      <template #purchasedAt="{ item }">
        {{ formatter(new Date(item.purchasedAt + "+00:00")) }}
      </template>
      <template #status="{ item }">
        <span
          v-if="product.type.type !== 3"
          class="badge"
          :class="`${
            item.status === 1
              ? 'badge-warning '
              : item.status === 2
              ? 'badge-success'
              : item.status === 0
              ? 'badge-danger '
              : item.status === 3
              ? 'badge-primary '
              : item.status === 4
              ? 'badge-info'
              : ''
          }`"
        >
          {{
            item.status === 1
              ? $t("waiting")
              : item.status === 2
              ? $t("done")
              : item.status === 0
              ? $t("canceled")
              : item.status === 3
              ? $t("offerWaiting")
              : item.status === 4
              ? $t("offered")
              : ""
          }}
        </span>
        <div class="mt-2" v-if="getSettings.departmentId !== 2">
          <strong v-if="item.status === 3 || item.status === 4">
            {{
              Currency(
                { price: item.price, discount: item.discount },
                item.currency || "TRY"
              )
            }}
          </strong>
          <div v-else>
            <small v-if="item.discount > 0 && item.price > 0" class="old-price">
              {{ Currency({ price: item.price }, item.currency || "TRY") }}
            </small>
            <div>
              <strong>
                {{
                  Currency(
                    { price: item.price, discount: item.discount },
                    item.currency || "TRY"
                  )
                }}
              </strong>
              <small v-if="item.discount > 0 && item.promotion === null"
                >(%{{ item.discount }})</small
              >
            </div>
          </div>
          <div v-if="item.promotion !== null" class="text-success">
            <small
              >{{ item.promotion.code }} (-{{
                item.promotion.discount
              }}%)</small
            >
          </div>
        </div>
      </template>
      <template #action="{ item }">
        <div class="text-right">
          <button
            v-if="product.type.type !== 3"
            class="btn btn-pill btn-xs mr-2"
            @click="
              activeUserUpdate = item;
              showActiveUserUpdateModal = true;
            "
          >
            {{ $t("view") }}
          </button>
          <button
            @click="
              $store.commit('SET_MAIL_DRAWER', {
                status: true,
                form: {
                  to: item.email,
                  bcc: '',
                  subject: $t('aboutBoughtProduct', {
                    typeTitle: this.product.type.title,
                    productTitle: this.product.title,
                  }),
                  content: '',
                  files: [],
                },
              })
            "
            class="btn btn-pill donate btn-xs"
          >
            {{ $t("sendMail") }}
          </button>
        </div>
      </template>
    </Tables>
    <UserListOfEventUpdate
      v-model="showActiveUserUpdateModal"
      :activeUserUpdate="activeUserUpdate"
      :product="product"
      @refresh="GET_LIST"
    ></UserListOfEventUpdate>
    <UserListOfEventInsert
      :key="modalKey"
      :product="product"
      @refresh="GET_LIST"
      v-model="showActiveUserInsertModal"
    ></UserListOfEventInsert>
    <div class="d-flex justify-content-center">
      <BoostrapPagination
        @change="SET_PAGINATION"
        :page="page"
        :pages="pages"
      ></BoostrapPagination>
    </div>
    <Modal v-model="updateUserList.show" @ok="REQUEST_UPDATE_USER_STATUS">
      <div class="mt-4">
        <h5 class="mb-2">{{ $t("currencyItemWhoAddedToStudent") }}</h5>
        <p class="mb-4 text-muted">
          <span>
            {{ $t("currencyItemWhoAddedToStudentDescription2") }}
          </span>
        </p>
        <div class="alert" :class="`alert-info`" v-if="alertBox.length > 0">
          <ul>
            <li v-for="(alert, i) in alertBox" :key="i">
              {{
                $t("alertUpdateStudent", {
                  fullname: alert.fullname,
                  email: alert.email,
                  status: alert.status ? "güncellendi" : "güncellenemedi.",
                })
              }}
              <hr />
            </li>
          </ul>
        </div>
        <select
          v-model="updateUserList.status"
          name="pay"
          class="form-control"
          id=""
        >
          <option selected hidden :value="null">
            {{ $t("choosePaymentStatus") }}
          </option>
          <option :value="1">{{ $t("waitPayment") }}</option>
          <option :value="2">{{ $t("completePayment") }}</option>
          <option :value="0">{{ $t("cancelPayment") }}</option>
        </select>
        <div
          v-if="updateUserList.status === 2"
          class="alert alert-warning mt-2"
        >
          {{ $t("choosedPaymentCompletedForStudent") }}
        </div>
      </div></Modal
    >
  </div>
</template>

<script>
import { formatter, convertM2H } from "gurhan/helpers/Date";
import BoostrapPagination from "gurhan/vue/BootstrapPagination.vue";
import Tables from "../Tables/index.vue";
import Modal from "../Shared/Modal/index.vue";
import UserListOfEventUpdate from "./UserListOfEventUpdate.vue";
import UserListOfEventInsert from "./UserListOfEventInsert.vue";
import { Currency } from "gurhan/helpers/Currency";
import { mapGetters } from "vuex";
export default {
  components: {
    Tables,
    Modal,
    BoostrapPagination,
    UserListOfEventUpdate,
    UserListOfEventInsert,
  },
  data() {
    return {
      modalKey: 1,
      usersAreLoading: false,
      selectedAllItemWithoutPage: false,
      showActiveUserUpdateModal: false,
      showActiveUserInsertModal: false,
      activeUserUpdate: { id: -1, promotion: {} },
      checkedList: [],
      userList: [],
      search: "",
      page: 1,
      limit: 10,
      count: 0,
      headers: [
        {
          title: "No",
          value: "orderNo",
          col: 12,
          md: 2,
          lg: 2,
          xl: 2,
        },
        {
          title: this.$t("user"),
          value: "fullname",
          col: 12,
          md: 4,
          lg: 3,
          xl: 3,
        },
        {
          title: this.$t("createdAt"),
          value: "purchasedAt",
          col: 12,
          md: 6,
          lg: 2,
          xl: 2,
        },
        {
          title: this.$t("situation"),
          value: "status",
          col: 12,
          md: 6,
          lg: 2,
          xl: 2,
        },
        {
          title: "",
          value: "action",
          col: 12,
          md: 6,
          lg: 3,
          xl: 3,
        },
      ],
      updateUserList: {
        show: false,
        status: 1,
        users: [],
      },
      alertBox: [],
      fields: {},
    };
  },
  props: {
    product: {
      default: () => {},
    },
  },
  computed: {
    ...mapGetters(["getExcelName"]),
    pages() {
      return Math.ceil(this.count / this.limit);
    },
    getSettings() {
      return this.$store.state.User.settings;
    },
    getActiveUser() {
      return this.$store.state.User.activeUser;
    },
  },
  methods: {
    Currency,
    formatter,
    convertM2H,
    CREATE_EXCEL_FIELD() {
      this.fields = {
        [`${this.$t("name")}`]: {
          field: "fullname",
          callback: (value) => {
            return value;
          },
        },
        [`${this.$t("phone")}`]: {
          field: "phone",
          callback: (value) => {
            return value;
          },
        },
        [`${this.$t("email")}`]: {
          field: "email",
          callback: (value) => {
            return value;
          },
        },
        [`${this.$t("price")}`]: {
          field: "priceXls",
          callback: (value) => {
            return value;
          },
        },
        [`${this.$t("promotion")}`]: {
          field: "promotionCode",
          callback: (value) => {
            return value;
          },
        },
        [`${this.$t("purchasedAt")}`]: {
          field: "purchasedAt",
          callback: (value) => {
            return formatter(new Date(value));
          },
        },
        [`${this.$t("situation")}`]: {
          field: "status",
          callback: (value) => {
            return value === 1
              ? this.$t("waiting")
              : value === 2
              ? this.$t("done")
              : value === 0
              ? this.$t("canceled")
              : value === 3
              ? this.$t("offerWaiting")
              : value === 4
              ? this.$t("offered")
              : "";
          },
        },
        [`${this.$t("expiredAt")}`]: {
          field: "expiredAt",
          callback: (value) => {
            return value.includes("9999")
              ? this.$t("lifeTimeAccess")
              : formatter(new Date(value));
          },
        },
      };
    },
    async SEND_UPDATE_USER_PRODUCT(user) {
      this.alertBox.status = false;
      const response = await this.$api.put(
        `Products/UpdateStatus?table=${this.product.type.name}`,
        {
          userId: user.id,
          productId: this.product.id,
          status: this.updateUserList.status,
          price: user.price,
          discount: user.discount,
        }
      );
      if (response.message === "OK") {
        return { ...user, status: true };
        // this.$emit("input", false);
        // this.$emit("refresh");
      } else {
        return { ...user, status: false };
      }
    },
    SET_CHECK_LIST_FOR_EXCEL(checkedList) {
      this.checkedList = checkedList.map((item) => ({
        ...item,
        promotionCode: `${item.promotion?.code || ""} (${
          item.promotion?.discount || 0
        }%)`,
        priceXls: `${Currency(
          { price: item.price, discount: item.discount },
          item.currency || "TRY"
        )} ${item.discount ? "(%" + item.discount + ")" : ""}`,
      }));
      setTimeout(() => {
        this.$refs.excel.generate();
      }, 250);
    },
    SET_CHECK_LIST(checkedList) {
      return checkedList
        .map((c) => c.email)
        .reduce(function (a, b) {
          if (a.indexOf(b) < 0) a.push(b);
          return a;
        }, [])
        .join(",");
    },
    async REQUEST_UPDATE_USER_STATUS(loading) {
      this.alertBox = [];

      loading(true);
      const response = await Promise.all(
        this.updateUserList.users.map((user) =>
          this.SEND_UPDATE_USER_PRODUCT(user)
        )
      );
      this.alertBox = response;
      this.GET_LIST();
      loading(false);
    },
    UPDATE_USER_STATUS(userList) {
      this.alertBox = [];
      this.updateUserList = { show: true, users: userList };
    },
    async GET_ALL_USER_EMAIL(updateUser = false, fromFetch = false) {
      this.$store.commit("SET_LOADING_MODAL", {
        loading: true,
        alertBox: {
          status: false,
          variant: "success",
          message: "",
        },
      });
      const response = await this.$api.get(
        `Products/ByProduct?table=${this.product.type.name}&productId=${this.product.id}&page=1&limit=${this.count}`
      );
      this.$store.commit("SET_LOADING_MODAL", {
        loading: false,
        alertBox: {
          status: false,
          variant: "success",
          message: "",
        },
      });
      if (response.message === "OK") {
        if (updateUser) return this.UPDATE_USER_STATUS(response.data.items);
        if (fromFetch)
          return this.SET_CHECK_LIST_FOR_EXCEL(response.data.items);
        this.$store.commit("SET_MAIL_DRAWER", {
          status: true,
          form: {
            to: "",
            bcc: response.data.items
              .map((c) => c.email)
              .reduce(function (a, b) {
                if (a.indexOf(b) < 0) a.push(b);
                return a;
              }, [])
              .join(","),
            subject: this.$t("aboutBoughtProduct", {
              typeTitle: this.product.type.title,
              productTitle: this.product.title,
            }),
            content: "",
            files: [],
          },
        });
      }
    },
    SET_SELECT_ALL_WITHOUT_PAGE(all) {
      this.selectedAllItemWithoutPage = all;
    },
    SET_SEARCH(search) {
      this.search = search;
      this.GET_LIST();
    },
    SET_PAGINATION(page) {
      this.page = page;
      this.$router.replace({ query: { ...this.$route.query, page } });
      this.GET_LIST();
    },
    async GET_LIST() {
      this.usersAreLoading = true;
      const response = await this.$api.get(
        `Products/ByProduct?table=${this.product.type.name}&productId=${this.product.id}&page=${this.page}&limit=${this.limit}&searchKey=${this.search}`
      );
      if (
        response.message === "OK" &&
        response.result === "OK" &&
        response.data !== null
      ) {
        this.count = response.data.count;
        this.userList = response.data.items;
      } else this.userList = [];

      this.usersAreLoading = false;
    },
    REMOVE_IF_SCHOOL_INSTRUCTOR() {
      if (
        this.getActiveUser.roleId === 3 &&
        this.getSettings.departmentId === 2
      )
        this.headers.splice(1, 1);
    },
  },
  created() {
    this.REMOVE_IF_SCHOOL_INSTRUCTOR();
  },
  mounted() {
    this.CREATE_EXCEL_FIELD();
    if (!isNaN(Number(this.$route.query.page)))
      this.page = Number(this.$route.query.page);
    this.GET_LIST();
  },
};
</script>
