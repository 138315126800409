<template>
  <div>
    <Table
      :hideHeader="inside"
      :count="count"
      :headers="headers"
      no-search
      :loading="isLoading"
      :items="items"
      no-card
    >
      <template #productName="{ item }">
        {{ item.courseDetail.title }}
      </template>
      <template #courseFinishedRate="{ item }">
        <h6 :class="courseRateClass(item.courseFinishedRate)">
          % {{ item.courseFinishedRate }} {{ $t("completed") }}
        </h6>
      </template>
    </Table>
    <div v-if="!inside" class="d-flex justify-content-center">
      <BoostrapPagination
        @change="SET_PAGINATION"
        :page="page"
        :pages="pages"
      ></BoostrapPagination>
    </div>
  </div>
</template>

<script>
import Table from "../Tables/index.vue";
import { formatter } from "gurhan/helpers/Date";
import { Currency } from "gurhan/helpers/Currency";
import BoostrapPagination from "gurhan/vue/BootstrapPagination.vue";

export default {
  components: {
    BoostrapPagination,
    Table,
  },
  props: {
    inside: {
      default: false,
      type: Boolean,
    },
    limit: {
      default: 10,
    },
    user: {
      default: () => ({ id: -1 }),
    },
  },
  data() {
    return {
      items: [],
      isLoading: true,
      count: 0,
      page: 1,
      headers: [
        {
          title: this.$t("productName"),
          value: "productName",
          col: 12,
          md: 6,
          lg: 6,
          xl: 6,
        },
        {
          title: this.$t("courseFinishedRate"),
          value: "courseFinishedRate",
          col: 12,
          md: 6,
          lg: 6,
          xl: 6,
        },
      ],
    };
  },
  computed: {
    pages() {
      return Math.ceil(this.count / 10);
    },
    getSettings() {
      return this.$store.state.User.settings;
    },
  },
  methods: {
    formatter,
    Currency,
    courseRateClass(rate) {
      if (rate > 85) return "text-success";
      else if (rate > 70) return "text-info";
      else if (rate > 50) return "text-warning";
      else return "text-danger";
    },
    SET_PAGINATION(page) {
      this.page = page;
      this.$router.replace({ query: { page } });
      this.GET_ITEMS();
    },
    async GET_ITEMS() {
      this.isLoading = true;
      const response = await this.$api.get(
        `Statistic/GetUserCourseFinishedDetail?userId=${this.user.id}&page=${this.page}&limit=${this.limit}`
      );
      if (response.message === "OK") {
        if (response.data.items.length > 0) {
          this.items = response.data.items[0].courseInfos;
        }
      }
      this.isLoading = false;
    },
  },
  mounted() {
    const q = this.$route.query;
    if (!isNaN(Number(q.page))) this.page = Number(q.page);
    this.GET_ITEMS();
  },
};
</script>

<style></style>
