<template>
  <div>
    <ModuleLocked v-if="!digitalProductModule" />
    <section v-else class="vid-title-sec">
      <delete-product
        :product="form"
        v-if="form.buyerCount === 0"
      ></delete-product>
      <div class="container">
        <form @submit.prevent="SET_MIDDLEWARE">
          <div class="vid-title">
            <h2 class="title-hd">{{ $t("enterBookTitle") }}</h2>
            <div class="form_field">
              <input
                type="text"
                maxlength="100"
                required
                v-model="form.title"
                :placeholder="$t('addHereMax100Char')"
              />
            </div>
          </div>
          <!--vid-title-->
          <div class="abt-vidz-pr">
            <h2 class="title-hd">{{ $t("shortDesc") }}</h2>
            <div class="form_field">
              <textarea
                :placeholder="$t('shortDescMax250')"
                required
                v-model="form.description"
                maxlength="250"
              ></textarea>
            </div>
          </div>
          <!--abt-vidz-->
          <div class="abt-vidz-pr">
            <h2 class="title-hd">{{ $t("bookOverview") }} *</h2>
            <div class="form_field">
              <vue-editor
                id="editor"
                :placeholder="$t('explainBookDetail')"
                :editorToolbar="[
                  [{ header: [false, 1, 2, 3, 4, 5, 6] }],
                  [{ size: ['small', false, 'large', 'huge'] }],
                  ['bold', 'italic', 'underline', 'strike'],
                  [
                    { align: '' },
                    { align: 'center' },
                    { align: 'right' },
                    { align: 'justify' },
                  ],
                  ['blockquote', 'code-block'],
                  [{ list: 'ordered' }, { list: 'bullet' }, { list: 'check' }],
                  [{ color: [] }, { background: [] }],
                  ['link'],
                  ['clean'],
                ]"
                v-model="form.overview"
              >
              </vue-editor>
            </div>
          </div>
          <!--abt-vidz-->
          <div class="cls-vidz">
            <div class="row">
              <div class="col-lg-3 col-md-3 col-sm-6 col-12">
                <div class="option">
                  <h2 class="title-hd">{{ $t("selectBookLang") }} *</h2>
                  <div class="form_field">
                    <select required v-model="form.langCode">
                      <option value="tr">{{ $t("tr") }}</option>
                      <option value="en">{{ $t("en") }}</option>
                      <option value="de">{{ $t("de") }}</option>
                    </select>
                  </div>
                </div>
                <!--option end-->
              </div>
              <div class="col-lg-3 col-md-3 col-sm-6 col-12">
                <div class="option">
                  <h2 class="title-hd">{{ $t("bookLevel") }} *</h2>
                  <div class="form_field">
                    <select required v-model="form.level">
                      <option :value="1">{{ $custom$t("beginning") }}</option>
                      <option :value="2">
                        {{ $custom$t("intermediate") }}
                      </option>
                      <option :value="3">{{ $custom$t("advanced") }}</option>
                      <option :value="4">{{ $t("all") }}</option>
                    </select>
                  </div>
                </div>
                <!--option end-->
              </div>
              <div class="col-sm-6 col-12">
                <div class="option">
                  <h2 class="title-hd">{{ $t("category") }} *</h2>
                  <div class="form_field">
                    <select
                      :disabled="getCategoriesAreLoading"
                      required
                      v-model="form.categoryId"
                    >
                      <option
                        :value="item.id"
                        v-for="(item, i) in getCategories"
                        :key="i"
                      >
                        {{ item.name }}
                      </option>
                    </select>
                  </div>
                </div>
                <!--option end-->
              </div>
            </div>
            <div
              class="row justify-content-center mt-4"
              v-if="getSettings.departmentId !== 2"
            >
              <div class="col-lg-3 col-md-6 col-12">
                <div class="vid-title">
                  <h2 class="title-hd">{{ $t("bookType") }}</h2>
                  <div class="form_field">
                    <input
                      required
                      v-model="form.bookGenre"
                      type="text"
                      maxlength="100"
                      :placeholder="$t('bookType')"
                    />
                  </div>
                </div>
              </div>
              <div class="col-lg-3 col-md-6 col-12">
                <div class="vid-title">
                  <h2 class="title-hd">ISBN</h2>
                  <div class="form_field">
                    <input
                      required
                      v-model="form.bookISBN"
                      type="text"
                      maxlength="100"
                      placeholder="ISBN"
                    />
                  </div>
                </div>
              </div>
              <div class="col-lg-3 col-md-6 col-12">
                <div class="vid-title">
                  <h2 class="title-hd">{{ $t("printingDate") }}</h2>
                  <div class="form_field">
                    <input
                      required
                      v-model="form.bookPrintInfo"
                      type="text"
                      maxlength="100"
                      :placeholder="$t('printingDate')"
                    />
                  </div>
                </div>
              </div>
              <div class="col-lg-3 col-md-6 col-12">
                <div class="vid-title">
                  <h2 class="title-hd">{{ $t("totalPages") }}</h2>
                  <div class="form_field">
                    <input
                      required
                      v-model="form.pageCount"
                      type="text"
                      maxlength="100"
                      :placeholder="$t('totalPages')"
                    />
                  </div>
                </div>
              </div>
            </div>
            <!-- <HiddenInfo v-model="form.hiddenInfo"></HiddenInfo> -->
            <ProductIsOffer
              v-if="getSettings.departmentId !== 2"
              v-model="form.isOffer"
            ></ProductIsOffer>
            <ProductPrice
              v-if="getSettings.departmentId !== 2"
              v-model="form"
            ></ProductPrice>
          </div>
          <Cropper :image="form.imagePath" @change:image="SET_IMAGE"></Cropper>
          <PdfOfBook :file="form.filePath" @change:file="SET_FILE"></PdfOfBook>
          <div>
            <publish-on-home-page
              v-if="getSettings.departmentId !== 2"
              v-model="form.isShowcase"
            ></publish-on-home-page>
          </div>
          <div>
            <SharableProduct
              v-if="getSettings.departmentId !== 2"
              v-model="form.isSharable"
            ></SharableProduct>
          </div>
          <ProductHasLimit v-model="form.limit"></ProductHasLimit>
          <div>
            <SelectProductOwner
              :edit="edit"
              v-model="form.userInfo"
              ref="userList"
            ></SelectProductOwner>
          </div>
          <!--cls-vidz end-->
          <div class="clearfix"></div>
          <div
            class="btn-sbmit bg-white p-2 shadow"
            style="position: sticky; bottom: -10px"
          >
            <button type="submit" :disabled="getNewBookIsLoading">
              <loading v-if="getNewBookIsLoading"></loading>
              <span v-else>
                {{ $t("updateProduct") }} <i class="icon-tick"></i
              ></span>
            </button>
          </div>
        </form>
        <IsActiveProduct
          :isActive="1"
          :form="product"
          v-if="edit"
        ></IsActiveProduct>
      </div>
    </section>
  </div>
  <!--vid-title-sec-->
</template>

<script>
import Cropper from "./ImageCropper.vue";
import PdfOfBook from "./PdfOfBook.vue";
// import HiddenInfo from "./HiddenInfo.vue";
import { VueEditor } from "vue2-editor";
import IsActiveProduct from "./IsActiveProduct.vue";
import PublishOnHomePage from "./PublishOnHomePage.vue";
import SharableProduct from "./SharableProduct.vue";
import SelectProductOwner from "./SelectProductOwner.vue";
import ProductPrice from "./ProductPrice.vue";
import ProductIsOffer from "./ProductIsOffer.vue";
import DeleteProduct from "./DeleteProduct.vue";
import ModuleLocked from "../Packages/ModuleLocked.vue";
import Modules from "../../mixins/Modules.js";
import AlertBox from "../../mixins/AlertBox.js";
import ProductHasLimit from "./ProductHasLimit.vue";

export default {
  mixins: [Modules, AlertBox],
  components: {
    Cropper,
    SelectProductOwner,
    PdfOfBook,
    // HiddenInfo,
    VueEditor,
    IsActiveProduct,
    PublishOnHomePage,
    SharableProduct,
    ProductPrice,
    ProductIsOffer,
    DeleteProduct,
    ModuleLocked,
    ProductHasLimit,
  },
  props: {
    edit: {
      default: false,
      type: Boolean,
    },
    product: {
      default: () => {},
    },
  },
  data() {
    return {
      form: {
        user: null,
        title: "",
        description: "",
        overview: "",
        imagePath: "",
        videoPath: "",
        filePath: "",
        image: null,
        file: null,
        priceTRY: 0,
        priceUSD: 0,
        priceEUR: 0,
        discountTRY: 0,
        discountUSD: 0,
        discountEUR: 0,
        langCode: "tr",
        bookISBN: "-",
        bookPrintInfo: "-",
        pageCount: "-",
        bookGenre: "-",
        level: 1,
        isShowcase: 0,
        isSharable: 1,
        isOffer: 0,
        hiddenInfo: "",
        categoryId: null,
      },
      files: {
        image: "",
        pdf: "",
      },
    };
  },
  computed: {
    // kategorilerin listesi alınır.
    getCategories() {
      return this.$store.state.Category.categories;
    },
    // kateori listesi loading
    getCategoriesAreLoading() {
      return this.$store.state.Category.categoriesAreLoading;
    },
    // ürün ekleme loading
    getNewBookIsLoading() {
      return this.$store.state.Books.newBooksIsLoading;
    },
    getSettings() {
      return this.$store.state.User.settings;
    },
    getActiveUser() {
      return this.$store.state.User.activeUser;
    },
  },
  methods: {
    // eğer yeni kayıt query'de category var ise.
    SET_CATEGORY() {
      const categoryId = Number(this.$route.query.category);
      if (categoryId) this.form.categoryId = categoryId;
    },
    SET_IF_EDIT() {
      this.form = {
        ...this.product,
        categoryId: this.product.category.id,
        imagePath: this.$api.image(this.product.imagePath),
        videoPath: this.$api.image(this.product.videoPath),
        filePath: this.$api.image(this.product.filePath),
        file: null,
        image: null,
      };
    },
    SET_IMAGE(e) {
      this.form.image = e.file; // image file'ı
      this.files.image = e.uri; // image linki
    },
    SET_FILE(e) {
      this.form.file = e.file; // video file'ı
      this.files.pdf = e.uri; /// video linki
    },
    // middleware
    SET_MIDDLEWARE(e) {
      e.preventDefault();
      if (this.edit) this.SEND_UPDATE_BOOK();
      else this.SEND_BOOK();
    },
    // ürünü güncelle
    async SEND_UPDATE_BOOK() {
      const response = await this.$store.dispatch("SEND_UPDATE_BOOK", {
        fd: this.SET_BOOK_BY_FORMDATA(this.form),
        form: {
          ...this.form,
          imagePath:
            this.files.image === null
              ? this.product.imagePath
              : this.files.image,
          filePath:
            this.files.pdf === null ? this.product.filePath : this.files.pdf,
        },
      });
      if (response)
        setTimeout(() => {
          this.$router.go(0);
        }, 1000);
    },
    // ürünü gönder
    async SEND_BOOK() {
      if (this.form.image === null)
        return this.SET_ALERT_BOX("danger", this.$t("imageIsRequired"));
      if (this.form.file === null)
        return this.SET_ALERT_BOX("danger", this.$t("videoIsRequired"));
      const response = await this.$store.dispatch("SEND_NEW_BOOK", {
        fd: this.SET_BOOK_BY_FORMDATA(this.form),
        form: {
          ...this.form,
          imagePath: this.files.image,
          filePath: this.files.pdf,
        },
      });
      if (response)
        setTimeout(() => {
          this.$router.push(`/product/books-${response}`);
        }, 2500);
    },
    // ürünü formdataya taşı.
    SET_BOOK_BY_FORMDATA(form) {
      const fd = new FormData();
      fd.append("UserId", form.userInfo.id);
      fd.append("Title", form.title);
      fd.append("CategoryId", form.categoryId);
      fd.append("Description", form.description);
      fd.append("DiscountEUR", form.discountEUR);
      fd.append("DiscountTRY", form.discountTRY);
      fd.append("DiscountUSD", form.discountUSD);
      fd.append("HiddenInfo", form.hiddenInfo);
      fd.append("BookISBN", form.bookISBN);
      fd.append("BookGenre", form.bookGenre);
      fd.append("BookPrintInfo", form.bookPrintInfo);
      fd.append("PageCount", form.pageCount);
      fd.append("LangCode", form.langCode);
      fd.append("Level", form.level);
      fd.append("IsShowcase", form.isShowcase);
      fd.append("Limit", form.limit || null);
      fd.append("IsOffer", form.isOffer);
      fd.append("IsSharable", form.isSharable);
      fd.append("IsLocked", 0);
      fd.append("Overview", form.overview);
      fd.append("PriceEUR", form.priceEUR);
      fd.append("PriceTRY", form.priceTRY);
      fd.append("PriceUSD", form.priceUSD);
      fd.append("ImagePath", form.imagePath);
      fd.append("FilePath", form.filePath);
      if (this.form.file !== null) fd.append("File", form.file);
      if (this.form.image !== null) fd.append("Image", form.image);
      return fd;
    },
  },
  created() {
    if (this.edit) this.SET_IF_EDIT();
    this.SET_CATEGORY();
  },
};
</script>
