import Vue from "vue";
import i18n from "../../plugins/i18n";
const vm = Vue.prototype;

export default {
  state: {
    newSeancesIsLoading: false, // yeni ürün loadingi
    seanceListRequested: false, // daha önce ürün verisi çekilmiş mi?
    seancesAreLoading: false, // ürünler listesi loadingi
    seances: [], // ürünler listesi
    count: 0, // kaç adet ürün bulunuyor bilgisi.
  },
  mutations: {
    // ürünlerin loadingi
    SET_SEANCES_ARE_LOADING(state, payload) {
      state.seancesAreLoading = payload;
    },
    // ürünleri projeye ekle
    SET_SEANCES(state, payload) {
      if (!state.seanceListRequested) state.seances = [];
      if (payload.search !== "" || payload.categoryId !== -1) {
        state.seances = [];
        state.seanceListRequested = false;
      } else state.seanceListRequested = true;

      state.seances = [...state.seances, ...payload.data];
      state.count = payload.count;
    },
    // ürünü yeni eklerkenki loading
    SET_NEW_SEANCE_IS_LOADING(state, payload) {
      state.newSeancesIsLoading = payload;
    },
    // eklenen ürünü ekle
    SET_NEW_SEANCE_TO_LIST(state, payload) {
      state.seances.unshift(payload);
    },
    // güncellenen ürünü ekle
    SET_UPDATE_SEANCE_TO_LIST(state, payload) {
      const seanceIndex = state.seances.findIndex((c) => c.id === payload.id);
      if (seanceIndex !== -1)
        state.seances[seanceIndex] = {
          ...state.seances[seanceIndex],
          ...payload,
        };
    },
    // ürünü sil
    SET_REMOVE_SEANCE(state, { id }) {
      const seanceIndex = state.seances.findIndex((c) => c.id === id);
      if (seanceIndex !== -1) state.seances.splice(seanceIndex, 1);
    },
  },
  actions: {
    // ürünleri getir
    async GET_SEANCES(
      { state, commit },
      { page = 1, search = "", limit = 12, type, categoryId = -1 }
    ) {
      if (
        state.seances.length !== 0 &&
        (search === "") & state.seanceListRequested &&
        state.seances.map((seance) => seance.page).includes(page) &&
        categoryId === -1
      )
        return; // daha önce liste çekilmişse return at.
      commit("SET_SEANCES_ARE_LOADING", true);
      const response = await vm.$api.get(
        `Seances?page=${page}&searchKey=${search}&limit=${limit}${
          categoryId === -1 ? "" : `&categoryId=${categoryId}`
        }`
      );
      if (response.message === "OK" && response.result === "OK")
        commit("SET_SEANCES", {
          count: response.data.count || 0,
          search,
          categoryId,
          data: response.data.items.map((d) => {
            // .items gelecek.
            return {
              ...d,
              page,
              type,
            };
          }),
        });
      else commit("SET_SEANCES", []);
      commit("SET_SEANCES_ARE_LOADING", false);
    },
    // yeni ürünü kayıt etme
    async SEND_NEW_SEANCE({ commit }, { fd, form }) {
      commit("SET_NEW_SEANCE_IS_LOADING", true);
      commit("SET_SEANCES_ARE_LOADING", true);
      const response = await vm.$api.post(`Seances`, fd);
      if (response.message === "OK" && response.result === "OK") {
        commit("SET_NEW_SEANCE_TO_LIST", { ...form, id: response.data });
        commit("SET_LOADING_MODAL", {
          loading: true,
          alertBox: {
            status: true,
            variant: "success",
            message: i18n.t("insertIsSucceed"),
          },
        });
        commit("SET_NEW_SEANCE_IS_LOADING", false);
        commit("SET_SEANCES_ARE_LOADING", false);
        commit("CLEAR_CATEGORIES");
        return response.data;
      } else {
        commit("SET_LOADING_MODAL", {
          loading: true,
          alertBox: {
            status: true,
            variant: "danger",
            message: i18n.t("insertIsNotSucceed"),
          },
        });
        commit("SET_NEW_SEANCE_IS_LOADING", false);
        commit("SET_SEANCES_ARE_LOADING", false);
        return false;
      }
    },
    // ürünü güncelle
    async SEND_UPDATE_SEANCE({ commit }, { fd, form }) {
      commit("SET_NEW_SEANCE_IS_LOADING", true); // store seances.js
      commit("SET_SEANCES_ARE_LOADING", true); // store seances.js
      const response = await vm.$api.put(`Seances/${form.id}`, fd);
      if (response.message === "OK" && response.result === "OK") {
        commit("SET_UPDATE_SEANCE_TO_LIST", { ...form }); // store seances.js
        commit("SET_UPDATE_PRODUCT", { ...form }); // store products.js
        commit("SET_LOADING_MODAL", {
          loading: true,
          alertBox: {
            status: true,
            variant: "success",
            message: i18n.t("updatedIsSucceed"),
          },
        });
        commit("SET_NEW_SEANCE_IS_LOADING", false);
        commit("SET_SEANCES_ARE_LOADING", false);

        return form.id;
      } else {
        commit("SET_LOADING_MODAL", {
          loading: true,
          alertBox: {
            status: true,
            variant: "danger",
            message: i18n.t("updatedIsNotSucceed"),
          },
        });
        commit("SET_NEW_SEANCE_IS_LOADING", false);
        commit("SET_SEANCES_ARE_LOADING", false);
        return false;
      }
    },
    async SEND_USER_TO_SEANCE(_, payload) {
      const response = await vm.$api.post("Products?table=seance", payload);
      if (response.message === "OK") {
        return {
          status: true,
          variant: "success",
          message: i18n.t("productHasBeenInsertedToUser"),
        };
      } else if (response.message === "PRODUCT ALREADY EXISTS") {
        return {
          status: true,
          variant: "danger",
          message: i18n.t("productAlreadyExist"),
        };
      } else {
        return {
          status: true,
          variant: "danger",
          message: i18n.t("productHasNotBeenInsertedToUser"),
        };
      }
    },
  },
};
